import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, RouteProps, useRouteMatch } from 'react-router';

interface Props {
  nested?: boolean;
}

const LocalizedSwitch: React.FC<Props> = ({ children, nested = false }) => {
  const { t } = useTranslation();
  const routeMatch = useRouteMatch();

  const localizeRoutePath = (path?: string | readonly string[], props?) => {
    switch (typeof path) {
      case 'undefined': {
        return undefined;
      }
      case 'object': {
        return path.map((key) => {
          return `/${t(key)}`;
        });
      }
      default: {
        const isFallbackRoute = path === '*';

        if (nested) {
          return routeMatch ? `${routeMatch.path}/${t(path)}` : `/${t(path)}`;
        }

        return isFallbackRoute ? path : `/${t(path)}`;
      }
    }
  };

  return (
    <Switch>
      {React.Children.map(children, (child) => {
        return React.isValidElement<RouteProps>(child)
          ? React.cloneElement(child, {
              ...child.props,
              path: localizeRoutePath(child.props.path, child.props),
            })
          : child;
      })}
    </Switch>
  );
};

export default LocalizedSwitch;
