import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Project } from '../../../model/Project';
import { getWorkflowWithoutSubSteps } from '../../../utils/workflowUtils';
import {
  AirflowTestingPage,
  AshpUnitConfigurationPage,
  BackupControlStrategyPage,
  BackupHeatSourcePage,
  CrossSectionalAreaMeasurementPage,
  DehumidificationStrategyPage,
  DuctlessIndoorOutdoorUnitsConfigurationPage,
  ExistingConstraintsPage,
  GHGCostEnergyUseComparisonPage,
  HeatPumpSelectionPage,
  HeatingCoolingLoadEstimatesPage,
  UtilityCostsPage,
} from '../Steps';
import { Steps } from '../../../model/Step';

interface Props {
  reference: React.MutableRefObject<null>;
  project: Project;
}

const ChecklistPrintFormat: React.FC<Props> = ({ reference, project }) => {
  const { t } = useTranslation();
  const projectSteps = project.steps;
  const situations = project.situations;
  const selectedSituationsJSX = Object.keys(situations).map((objKey, i) => {
    if (situations[objKey]) {
      return <li key={i}>{t(`situation.${objKey}`)}</li>;
    }

    return null;
  });
  // const determineHeatLostGainExists =
  //   projectSteps && projectSteps.determineHeatLostGain;
  const generateHeader = () => {
    return (
      <>
        <a href="https://www.canada.ca/en.html" property="url">
          <img
            className="print-gov-logo"
            src="https://www.canada.ca/etc/designs/canada/cdts/gcweb/v4_0_32/assets/sig-blk-en.svg"
            alt=""
            property="logo"
          />
        </a>
        <p>
          <a className="website-link" href="https://www.canada.ca/en.html">
            Canada.ca
          </a>
        </p>
        <h2 className="app-name-small">{t('appTitle')}</h2>
        <h1>{t('pageTitle.checklist', { projectName: project.name })}</h1>
        <h2>{t('checklist.title')}</h2>
        <p>{t('checklist.description')}</p>
      </>
    );
  };
  const generateChecklistFieldInfo = () => {
    return (
      <>
        {project.checklist && project.checklist.homeBuilder && (
          <>
            <label>{t('checklist.form.homeBuilder.label')}</label>
            <p className="responseText">{project.checklist.homeBuilder}</p>
          </>
        )}
        {project.checklist && project.checklist.hvacDesigner && (
          <>
            <label>{t('checklist.form.hvacDesigner.label')}</label>
            <p className="responseText">{project.checklist.hvacDesigner}</p>
          </>
        )}
        {project.checklist && project.checklist.houseId && (
          <>
            <label>{t('checklist.form.houseId.label')}</label>
            <p className="responseText">{project.checklist.houseId}</p>
          </>
        )}
        {project.checklist && project.checklist.appliedTo && (
          <>
            <label>{t('checklist.form.appliedTo.label')}</label>
            <p className="responseText">
              {t(`checklist.form.${project.checklist.appliedTo}.label`)}
            </p>
          </>
        )}
        {project.checklist &&
          (project.checklist.homeBuilder ||
            project.checklist.hvacDesigner ||
            project.checklist.houseId ||
            project.checklist.appliedTo) && (
            <p>
              <em>{t('checklist.footNote')}</em>
            </p>
          )}
      </>
    );
  };
  const generateAdditionalInfo = (label, text) => {
    return (
      <>
        <label>{label}</label>
        <p className="additional-info-text">{text}</p>
      </>
    );
  };
  const generateStep1Info = () => {
    const stepIsCompleted = project.completedSteps.some(
      (s) => s === 'projectDetails'
    );
    return (
      <div className="print-step">
        <hr />
        <h4>
          {stepIsCompleted && (
            <img
              alt=""
              className="completed-step"
              src={'/assets/images/CompletedStep.png'}
            />
          )}
          {`${t('checklist.stepNumber', { stepNumber: 1 })} - ${t(
            'project.details.title'
          )}`}
        </h4>
        <label>{t('project.identification.houseType.label')}</label>
        <p>{t(`houseType.${project.houseType}`)}</p>
        <label>{t('project.identification.numberOfLevels.label')}</label>
        <p>{project.numberOfLevels}</p>
        <label>{t('project.details.situations.label')}</label>
        <ul>{selectedSituationsJSX}</ul>
      </div>
    );
  };

  const generateStandardStep = (step) => {
    const stepNumber = step.number;
    const stepId = step.id;
    const selectedValue =
      projectSteps &&
      projectSteps[stepId] &&
      projectSteps[stepId][`${stepId}-value`];
    const stepIsCompleted = project.completedSteps.some((s) => s === stepId);
    return (
      <div className="print-step">
        <hr />
        <h4>
          {stepIsCompleted && (
            <img
              alt=""
              className="completed-step"
              src={'/assets/images/CompletedStep.png'}
            />
          )}
          {`${t('checklist.stepNumber', {
            stepNumber: stepNumber,
          })} - ${t(`step-${stepId}:title`)}`}
        </h4>
        {projectSteps && projectSteps[stepId] && selectedValue && (
          <>
            <label>{t('checklist.selectedOption')}</label>
            <p>{`${stepNumber}${selectedValue} -
              ${t(
                `step-${stepId}:form.${selectedValue.toLowerCase()}.option.text`
              )}
              `}</p>
          </>
        )}

        {projectSteps &&
          projectSteps['ashpUnitConfiguration'] &&
          stepId === 'ashpUnitConfiguration' && (
            <>
              <AshpUnitConfigurationPage
                project={project}
                displayCompact={true}
                userIsProjectOwner={false}
                stepData={projectSteps[stepId]}
                hideOptionsSelection
              >
                {projectSteps[stepId].numberOfIndoorUnits ||
                projectSteps[stepId].numberOfOutdoorUnits ? (
                  <DuctlessIndoorOutdoorUnitsConfigurationPage
                    step2Data={projectSteps[stepId]}
                    stepData={projectSteps[Steps.STEP_2_1]}
                    displayCompact={true}
                    userIsProjectOwner={false}
                  />
                ) : (
                  <></>
                )}
              </AshpUnitConfigurationPage>
            </>
          )}
        {projectSteps &&
          projectSteps['existingConstraints'] &&
          stepId === 'existingConstraints' && (
            <>
              <ExistingConstraintsPage
                project={project}
                displayCompact={true}
                userIsProjectOwner={false}
                stepData={projectSteps[stepId]}
                step2Data={projectSteps['ashpUnitConfiguration']}
                hideOptionsSelection
              >
                <>
                  {projectSteps[Steps.STEP_2]['ashpUnitConfiguration-value'] ===
                    'A' &&
                  projectSteps[Steps.STEP_5][
                    'airflowCapacityMeasurementMethod-value'
                  ] === 'A' ? (
                    <AirflowTestingPage
                      stepData={projectSteps[Steps.STEP_5_1]}
                      displayCompact={true}
                      userIsProjectOwner={false}
                    />
                  ) : undefined}
                  {projectSteps[Steps.STEP_5][
                    'airflowCapacityMeasurementMethod-value'
                  ] === 'B' ? (
                    <CrossSectionalAreaMeasurementPage
                      stepData={projectSteps[Steps.STEP_5_1]}
                      displayCompact={true}
                      userIsProjectOwner={false}
                    />
                  ) : undefined}
                </>
              </ExistingConstraintsPage>
            </>
          )}
        {projectSteps &&
          projectSteps['heatingCoolingLoadEstimates'] &&
          stepId === 'heatingCoolingLoadEstimates' && (
            <HeatingCoolingLoadEstimatesPage
              project={project}
              displayCompact={true}
              userIsProjectOwner={false}
              stepData={projectSteps[stepId]}
              hideOptionsSelection
            />
          )}
        {projectSteps &&
          projectSteps['heatPumpSelection'] &&
          stepId === 'heatPumpSelection' && (
            <HeatPumpSelectionPage
              project={project}
              stepData={projectSteps[Steps.STEP_12]}
              displayCompact={true}
              userIsProjectOwner={false}
            >
              {projectSteps[Steps.STEP_12].heatPumpCompareList.length ? (
                <BackupHeatSourcePage
                  stepData={projectSteps[Steps.STEP_12]}
                  displayCompact={true}
                  userIsProjectOwner={false}
                />
              ) : undefined}
            </HeatPumpSelectionPage>
          )}
        {projectSteps &&
          projectSteps['utilityCosts'] &&
          stepId === 'utilityCosts' && (
            <UtilityCostsPage
              step12Data={projectSteps[Steps.STEP_12]}
              stepData={projectSteps[Steps.STEP_13]}
              displayCompact={true}
              userIsProjectOwner={false}
            >
              {projectSteps[Steps.STEP_12].heatPumpCompareList.length ? (
                <BackupControlStrategyPage
                  project={project}
                  step12Data={projectSteps[Steps.STEP_12]}
                  stepData={projectSteps[Steps.STEP_13_1]}
                  displayCompact={true}
                  userIsProjectOwner={false}
                />
              ) : undefined}
            </UtilityCostsPage>
          )}
        {projectSteps &&
          projectSteps['dehumidificationStrategy'] &&
          stepId === 'dehumidificationStrategy' && (
            <DehumidificationStrategyPage
              project={project}
              stepData={projectSteps[Steps.STEP_14]}
              displayCompact={true}
              userIsProjectOwner={false}
            />
          )}
        {projectSteps &&
          projectSteps['ghgCostEnergyUseComparison'] &&
          stepId === 'ghgCostEnergyUseComparison' && (
            <GHGCostEnergyUseComparisonPage
              project={project}
              stepData={projectSteps[Steps.STEP_15]}
              displayCompact={true}
              userIsProjectOwner={false}
            />
          )}

        {project.additionalInformation &&
          project.additionalInformation[stepId] &&
          generateAdditionalInfo(
            t('stepPage.additionalInformation.label'),
            project.additionalInformation[stepId]
          )}
      </div>
    );
  };

  const stepList = useMemo(
    () => getWorkflowWithoutSubSteps(project),
    [project]
  );

  return (
    <div ref={reference} className="checklist-print-format">
      {generateHeader()}
      <section>{generateChecklistFieldInfo()}</section>
      <section>
        <h3>{t('checklist.accordianTitle1')}</h3>
        {generateStep1Info()}
        {stepList.map((step, index) =>
          index > 0 ? generateStandardStep(step) : null
        )}
      </section>
    </div>
  );
};

export default ChecklistPrintFormat;
