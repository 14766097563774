import { Trunk } from './Trunk';

export class CrossSectionalAreaMeasurement {
  trunks: Trunk[] = [];
  estimatedMaximumFlowCapacity?: number;

  constructor(entity?: CrossSectionalAreaMeasurement) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.trunks = [];
      this.estimatedMaximumFlowCapacity = 0;
    }
  }
}
