import { ReactNode, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { StepOption } from '../../../model/Step';
import { Disclaimer } from '../../common';
import VideoModal from '../Videos/VideoModal';
import { OptionDetailsModal } from './';

interface Props {
  item: {
    extraField?: ReactNode;
    label: string;
    value: string | number;
    labelData?: StepOption;
  };
  extraId?: number;
}

const StepOptionRadioItem: React.FC<Props> = ({ item, extraId }) => {
  const { t } = useTranslation();
  const describedbyId = item.labelData?.img
    ? `${item.labelData?.img.fileName}-description${
        extraId != null ? `-${extraId}` : ''
      }`
    : '';
  const [show, setShow] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<boolean>(false);

  const showModal = (event) => {
    event.stopPropagation();

    setShow(true);
  };

  const showVideoModal = (event) => {
    event.stopPropagation();

    setShowVideo(true);
  };

  if (!item.labelData) return null;

  return (
    <Row>
      <Col xs={12} md={8}>
        {item.labelData &&
          item.labelData.option &&
          item.labelData.option.title && (
            <div className="option-value">{item.labelData.option.title}</div>
          )}
        <div className="option-title">
          {item.labelData &&
            item.labelData.option &&
            item.labelData.option.text}
        </div>
        {item.extraField && item.extraField}
        {item.labelData.modal && (
          <Button
            aria-label={t('stepPage.moreInformation.aria', {
              stepLabel:
                item.labelData &&
                item.labelData.option &&
                item.labelData.option.text,
            })}
            className="option-modal-trigger"
            onClick={(e) => showModal(e)}
          >
            {t('common.moreInformation')}
          </Button>
        )}
        {item.labelData.video && (
          <Button
            aria-label={`${t('common.watchVideo')}: ${
              item.labelData.video.title
            }`}
            className="btn btn-primary video-button"
            onClick={(e) => showVideoModal(e)}
          >
            <FontAwesomeIcon icon={faPlayCircle} />
            <span className="video-button-text">{t('common.watchVideo')}</span>
          </Button>
        )}

        {item.labelData.option?.disclaimer && (
          <Disclaimer>
            {t('step-automaticZoningEquipment:disclaimer')}
          </Disclaimer>
        )}
      </Col>
      {item.labelData.img && (
        <Col xs={12} md={4} className="text-right-md text-right-lg">
          <img
            src={`/assets/images/${item.labelData.img.fileName}`}
            alt={item.labelData.img.alt}
            aria-describedby={describedbyId}
          />
          <p id={describedbyId} className="sr-only">
            {item.labelData.img.longDescription}
          </p>
        </Col>
      )}

      {item.labelData.modal && (
        <OptionDetailsModal
          option={item.labelData}
          show={show}
          setShow={setShow}
        />
      )}
      {item.labelData.video && (
        <VideoModal
          video={item.labelData.video}
          show={showVideo}
          setShow={setShowVideo}
        />
      )}
    </Row>
  );
};

export default StepOptionRadioItem;
