import { FurnaceType } from '../model/HeatPumpWithBackup/FurnaceType';
import { getWorkflowWithoutSubSteps } from './workflowUtils';

export const getProjectStatus = (project, t) => {
  const stepLength = getWorkflowWithoutSubSteps(project).length;
  if (project.isCompleted) {
    return t('project.status.completed');
  }

  if (project.isStarted) {
    if (project.completedSteps?.length === stepLength) {
      return t('project.status.completed');
    }
    return t('project.status.inProgress', {
      checkedSteps: project.completedSteps?.length || 0,
      total: stepLength,
    });
  }

  return t('project.status.created');
};

export const getFurnaceAfueDefaultValue = (furnaceType: FurnaceType) => {
  switch (furnaceType) {
    case FurnaceType.None:
      return 100;
    case FurnaceType.Oil:
      return 85;
    default:
      return 96;
  }
};
