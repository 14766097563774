export const updateFieldArrayDependingOnNumber = (
  number: number,
  fieldArray: any[],
  valueToAdd: any
) => {
  let updatedFieldArray = [...fieldArray];

  if (number && number - fieldArray.length > -1) {
    for (
      let index = 0;
      index < (number ? number - fieldArray.length : 0);
      index++
    ) {
      updatedFieldArray.push(valueToAdd);
    }
  } else if (number != null) {
    updatedFieldArray = updatedFieldArray.slice(0, number - fieldArray.length);
  }

  return updatedFieldArray;
};
