export enum LanguageCode {
  fr = 'fr',
  en = 'en',
}

export enum TranslationLanguage {
  Fr = 'Fr',
  En = 'En',
}
export type TranslatedString<
  Key extends string,
  Lang extends TranslationLanguage
> = `${Key}_${Lang}`;

export type TranslatedStrings<T extends string> = {
  [Key in TranslatedString<T, TranslationLanguage>]: string;
};
