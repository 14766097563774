export class AirflowTesting {
  airflow?: number;
  totalExternalStaticPressure?: number;
  predictedMaximumAirflow?: number;

  constructor(entity?: AirflowTesting) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.airflow = 0;
      this.totalExternalStaticPressure = 0;
      this.predictedMaximumAirflow = 0;
    }
  }
}
