import {
  Project,
  ProjectPropositions,
  ProjectSituation,
  ProjectType,
  Propositions,
} from '../model/Project';
import { Steps } from '../model/Step';

export const defaultSituations = {
  [ProjectSituation.ENERGY_EFFICIENCY_IMPORTANT]: false,
  [ProjectSituation.LOWER_UPFRONT_COSTS]: false,
  [ProjectSituation.ENVIRONMENTALLY_FRIENDLINESS]: false,
  [ProjectSituation.QUIET_OUTDOOR_OPERATION]: false,
  [ProjectSituation.QUIET_INDOOR_OPERATION]: false,
  [ProjectSituation.INDOOR_SYSTEM_TOO_NOISY]: false,
  [ProjectSituation.INADEQUATE_HEATING_UPPERLEVEL]: false,
  [ProjectSituation.INADEQUATE_HEATING_BASEMENT]: false,
  [ProjectSituation.ASHP_WORK_WITH_FURNACE]: false,
  [ProjectSituation.BASEMENT_LIKELY_FINISHED]: false,
  [ProjectSituation.PLANNING_IMPROVEMENT_ENERGY_EFFICENCY]: false,
  [ProjectSituation.POOR_INDOOR_AIR_QUALITY]: false,
  [ProjectSituation.INDOOR_AIR_TOO_DRY_WINTER]: false,
  [ProjectSituation.INDOOR_AIR_TOO_HUMID_SUMMER]: false,
  [ProjectSituation.UNWANTED_TEMPERATURE_FLUCTUATIONS]: false,
  [ProjectSituation.ROOM_OVER_GARAGE_TOO_WARM_AND_TOO_COLD]: false,
  [ProjectSituation.HUMIDITY_COMPLAINT]: false,
  [ProjectSituation.HOT_OR_COLD_SPOTTING_COMPLAINT]: false,
  [ProjectSituation.SECOND_FLOOR_TOO_WARM_BASEMENT_TOO_COLD_IN_SUMMER_COMPLAINT]:
    false,
  [ProjectSituation.INDOOR_NOISE_COMPLAINT]: false,
  [ProjectSituation.OUTDOOR_NOISE_COMPLAINT]: false,
  [ProjectSituation.AIMING_PARTICULAR_ENERGY_STANDARD]: false,
  [ProjectSituation.CHALLENGES_WITH_HVAC_TECH]: false,
  [ProjectSituation.CONSIDER_ZONING_HOME]: false,
  [ProjectSituation.UTILITY_SERVICE_OPTION_RESTRICTIONS]: false,
  [ProjectSituation.CHALLENGES_WITH_AESTHETICS_HVAC_INDOOR]: false,
  [ProjectSituation.CHALLENGES_WITH_AESTHETICS_HVAC_OUTDOOR]: false,
  [ProjectSituation.CHALLENGES_WITH_AMEMITY_SPACE_CONFLICT_HVAC_OUTDOOR]: false,
  [ProjectSituation.BYLAWS_PLACEMENT_OUTDOOR]: false,
  [ProjectSituation.SUPPLY_CHAIN_ISSUES]: false,
  [ProjectSituation.BASEMENT_FINISHED]: false,
};

const getPropositionsConditions = (project: Project) => {
  const isNewHome =
    project.projectType === ProjectType.FULL_SYSTEM_REPLACEMENT ||
    project.projectType === ProjectType.NEW_SYSTEM_INSTALLATION;
  const isSystemExtension =
    project.projectType === ProjectType.SYSTEM_EXTENSION;
  const isEquipmentRemplacement =
    project.projectType === ProjectType.EQUIPMENT_REPLACEMENT_ONLY;
  const isMediumOrHighVelocity =
    project.steps[Steps.HPNewHome_Step5Data]['ductSizing-value'] === 'B' ||
    project.steps[Steps.HPNewHome_Step5Data]['ductSizing-value'] === 'C';
  const isOtherOrOneZonePerFloor =
    project.steps[Steps.STEP_6]['zoningSupplyDucts-value'] === 'B' ||
    project.steps[Steps.STEP_6]['zoningSupplyDucts-value'] === 'C';
  const isDucted =
    project.steps[Steps.STEP_2]['ashpUnitConfiguration-value'] === 'D' ||
    project.steps[Steps.STEP_2]['ashpUnitConfiguration-value'] === 'A';
  const isDuctless =
    project.steps[Steps.STEP_2]['ashpUnitConfiguration-value'] === 'B' ||
    project.steps[Steps.STEP_2]['ashpUnitConfiguration-value'] === 'C';

  const propositionsConditions = {
    options: {
      [Steps.STEP_2]: [
        {
          proposition: Propositions.LOWER_UPFRONT_COSTS_STEP_2,
          conditions: [ProjectSituation.LOWER_UPFRONT_COSTS],
          skip: !(
            project.projectType === ProjectType.NEW_SYSTEM_INSTALLATION ||
            project.projectType === ProjectType.FULL_SYSTEM_REPLACEMENT
          ),
        },
        {
          proposition: Propositions.CHALLENGES_WITH_AESTHETICS_HVAC_INDOOR,
          conditions: [ProjectSituation.CHALLENGES_WITH_AESTHETICS_HVAC_INDOOR],
          skip: !(
            project.projectType === ProjectType.NEW_SYSTEM_INSTALLATION ||
            project.projectType === ProjectType.FULL_SYSTEM_REPLACEMENT
          ),
        },
      ],
      [Steps.STEP_3]: [
        {
          proposition: Propositions.ENERGY_EFFICIENCY_IMPORTANT,
          conditions: [ProjectSituation.ENERGY_EFFICIENCY_IMPORTANT],
          skip: !isEquipmentRemplacement,
        },
        {
          proposition: Propositions.LOWER_UPFRONT_COSTS,
          conditions: [ProjectSituation.LOWER_UPFRONT_COSTS],
          skip: !isEquipmentRemplacement,
        },
        {
          proposition: Propositions.ENVIRONMENTALLY_FRIENDLINESS,
          conditions: [ProjectSituation.ENVIRONMENTALLY_FRIENDLINESS],
          skip: !isEquipmentRemplacement,
        },
        {
          proposition: Propositions.PLANNING_IMPROVEMENTS_ENERGY_EFFICIENCY,
          conditions: [ProjectSituation.PLANNING_IMPROVEMENT_ENERGY_EFFICENCY],
          skip: !isEquipmentRemplacement,
        },

        //newhome
        {
          proposition: Propositions.ENERGY_EFFICIENCY_IMPORTANT_NEW_HOME_STEP_3,
          conditions: [ProjectSituation.ENERGY_EFFICIENCY_IMPORTANT],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.LOWER_UPFRONT_COSTS_STEP_3,
          conditions: [ProjectSituation.LOWER_UPFRONT_COSTS],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.ENVIRONMENTALLY_FRIENDLINESS,
          conditions: [ProjectSituation.ENVIRONMENTALLY_FRIENDLINESS],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.AIMING_PARTICULAR_ENERGY_STANDARD,
          conditions: [ProjectSituation.AIMING_PARTICULAR_ENERGY_STANDARD],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.CHALLENGES_WITH_HVAC_TECH,
          conditions: [ProjectSituation.CHALLENGES_WITH_HVAC_TECH],
          skip: !(
            project.projectType === ProjectType.NEW_SYSTEM_INSTALLATION ||
            project.projectType === ProjectType.FULL_SYSTEM_REPLACEMENT
          ),
        },
      ],
      [Steps.STEP_4]: [
        {
          proposition: Propositions.HOT_OR_COLD_SPOTTING_COMPLAINT,
          conditions: [ProjectSituation.HOT_OR_COLD_SPOTTING_COMPLAINT],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.LOWER_UPFRONT_COSTS_STEP_4,
          conditions: [ProjectSituation.LOWER_UPFRONT_COSTS],
          skip: !isNewHome,
        },
        {
          proposition:
            Propositions.CHALLENGES_WITH_AESTHETICS_HVAC_INDOOR_STEP_4,
          conditions: [ProjectSituation.CHALLENGES_WITH_AESTHETICS_HVAC_INDOOR],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.CHALLENGES_WITH_HVAC_TECH_STEP_4,
          conditions: [ProjectSituation.CHALLENGES_WITH_HVAC_TECH],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.INADEQUATE_HEATING_UPPERLEVEL,
          conditions: [ProjectSituation.INADEQUATE_HEATING_UPPERLEVEL],
          skip: !isSystemExtension,
        },
        {
          proposition: Propositions.HYBRID_OR_CENTRAL_SUPPLY,
          skip: !isSystemExtension,
        },
      ],
      [Steps.HPNewHome_Step5Data]: [
        {
          proposition:
            Propositions.CHALLENGES_WITH_AESTHETICS_HVAC_INDOOR_NEW_HOME_STEP_5,
          conditions: [ProjectSituation.CHALLENGES_WITH_AESTHETICS_HVAC_INDOOR],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.HEAT_PUMPS_REQUIRE_GREATER_AIRFLOWS,
          conditions: [],
          skip: false, // we do that to make this one appear in the You should consider section instead of the You should always
        },
      ],
      [Steps.STEP_5]: [
        {
          proposition: Propositions.DETERMINE_MAXIMUM_AIR_FLOW_CAPACITY,
          conditions: [ProjectSituation.LOWER_UPFRONT_COSTS],
          skip: !isEquipmentRemplacement,
        },
        {
          proposition: Propositions.AIR_FLOW_TESTING_MORE_ACCURATE,
          conditions: [
            ProjectSituation.QUIET_INDOOR_OPERATION,
            ProjectSituation.INDOOR_SYSTEM_TOO_NOISY,
          ],
          skip: !isEquipmentRemplacement,
        },
        {
          proposition:
            Propositions.DETERMINE_MAXIMUM_AIR_FLOW_CAPACITY_SYSTEM_EXTENSION,
          conditions: [ProjectSituation.LOWER_UPFRONT_COSTS],
          skip: !isSystemExtension,
        },
        {
          proposition:
            Propositions.AIR_FLOW_TESTING_MORE_ACCURATE_SYSTEM_EXTENSION,
          conditions: [
            ProjectSituation.QUIET_INDOOR_OPERATION,
            ProjectSituation.INDOOR_SYSTEM_TOO_NOISY,
          ],
          skip: !isSystemExtension,
        },
      ],
      [Steps.STEP_6]: [
        {
          proposition: Propositions.HOT_OR_COLD_SPOTTING_COMPLAINT_STEP_6,
          conditions: [
            ProjectSituation.HOT_OR_COLD_SPOTTING_COMPLAINT,
            ProjectSituation.SECOND_FLOOR_TOO_WARM_BASEMENT_TOO_COLD_IN_SUMMER_COMPLAINT,
          ],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.CONSIDER_ZONING_HOME,
          conditions: [ProjectSituation.CONSIDER_ZONING_HOME],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.CHALLENGES_WITH_HVAC_TECH_STEP_6,
          conditions: [ProjectSituation.CHALLENGES_WITH_HVAC_TECH],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.HOT_OR_COLD_SPOTTING_COMPLAINT_STEP_6,
          conditions: [
            ProjectSituation.INADEQUATE_HEATING_UPPERLEVEL,
            ProjectSituation.ROOM_OVER_GARAGE_TOO_WARM_AND_TOO_COLD,
          ],
          skip: !isSystemExtension,
        },
        {
          proposition: Propositions.ZONED_SYSTEMS_CAREFULLY_DESIGNED,
          skip: !isSystemExtension,
        },
      ],
      [Steps.STEP_7]: [
        {
          proposition: Propositions.CONSIDER_ZONING_HOME_STEP_7,
          conditions: [ProjectSituation.CONSIDER_ZONING_HOME],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.CHALLENGES_WITH_HVAC_TECH_STEP_7,
          conditions: [ProjectSituation.CHALLENGES_WITH_HVAC_TECH],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.ZONED_SYSTEMS_CAREFULLY_DESIGNED,
          skip: !isSystemExtension,
        },
      ],
      [Steps.STEP_8]: [
        {
          proposition: Propositions.LOWER_UPFRONT_COSTS_STEP_8,
          conditions: [ProjectSituation.LOWER_UPFRONT_COSTS],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.HOT_OR_COLD_SPOTTING_COMPLAINT_STEP_8,
          conditions: [ProjectSituation.HOT_OR_COLD_SPOTTING_COMPLAINT],
          skip: !isNewHome,
        },
        {
          proposition:
            Propositions.CHALLENGES_WITH_AESTHETICS_HVAC_INDOOR_STEP_8,
          conditions: [ProjectSituation.CHALLENGES_WITH_AESTHETICS_HVAC_INDOOR],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.LOWER_UPFRONT_COSTS_SYSTEM_EXTENSION_STEP_8,
          conditions: [ProjectSituation.LOWER_UPFRONT_COSTS],
          skip: !isSystemExtension,
        },
        {
          proposition: Propositions.INADEQUATE_HEATING_UPPERLEVEL_STEP_8,
          conditions: [
            ProjectSituation.INADEQUATE_HEATING_UPPERLEVEL,
            ProjectSituation.ROOM_OVER_GARAGE_TOO_WARM_AND_TOO_COLD,
          ],
          skip: !isSystemExtension,
        },
        {
          proposition: Propositions.REDUCE_THE_NEED_FOR_BULKHEADS,
          skip: !isSystemExtension,
        },
      ],
      [Steps.STEP_9]: [
        {
          proposition: Propositions.DUCT_SEALING_HELPS_REDUCE_HOT_COLD_SPOTTING,
          conditions: [
            ProjectSituation.HOT_OR_COLD_SPOTTING_COMPLAINT,
            ProjectSituation.SECOND_FLOOR_TOO_WARM_BASEMENT_TOO_COLD_IN_SUMMER_COMPLAINT,
          ],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.DUCT_SEALING_HELPS_REDUCE_HOT_COLD_SPOTTING,
          conditions: [
            ProjectSituation.INADEQUATE_HEATING_UPPERLEVEL,
            ProjectSituation.ROOM_OVER_GARAGE_TOO_WARM_AND_TOO_COLD,
          ],
          skip: !isSystemExtension,
        },
      ],
      [Steps.STEP_10]: [
        {
          proposition: Propositions.BASEMENT_LIKELY_FINISHED,
          conditions: [ProjectSituation.BASEMENT_LIKELY_FINISHED],
        },
        {
          proposition:
            Propositions.PLANNING_IMPROVEMENTS_ENERGY_EFFICIENCY_STEP_10,
          conditions: [ProjectSituation.PLANNING_IMPROVEMENT_ENERGY_EFFICENCY],
        },
      ],
      [Steps.STEP_11]: [],
      [Steps.STEP_12]: [
        ///SystemEtension
        {
          proposition: Propositions.DHL,
          skip:
            !isSystemExtension ||
            project.steps['heatingCoolingLoadEstimates'].designHeatLoss == null,
        },
        {
          proposition: Propositions.TARGET_HEATING_CAPACITY,
          skip:
            !isSystemExtension ||
            !project.steps.sizingCapacityRequirements[
              'sizingCapacityRequirements-value'
            ],
        },
        {
          proposition: Propositions.SAFETY_FACTOR,
          skip:
            !isSystemExtension ||
            project.steps['heatingCoolingLoadEstimates'].designHeatLoss == null,
        },
        {
          proposition: Propositions.AIRFLOW_CFM,
          skip: !isSystemExtension,
        },
        {
          proposition: Propositions.PANEL_CAPACITY,
          skip: !isSystemExtension,
        },
        {
          proposition: Propositions.ELECTRIC_SERVICE,
          skip: !isSystemExtension,
        },
        {
          proposition: Propositions.ENVIRONMENTALLY_FRIENDLINESS_STEP_12,
          conditions: [ProjectSituation.ENVIRONMENTALLY_FRIENDLINESS],
          secondary: true,
          skip: !isSystemExtension,
        },
        {
          proposition: Propositions.QUIET_OUTDOOR_OPERATION,
          conditions: [ProjectSituation.QUIET_OUTDOOR_OPERATION],
          secondary: true,
          skip: !isSystemExtension,
        },
        {
          proposition: Propositions.QUIET_INDOOR_OPERATION_DUCTED_STEP_12,
          conditions: [
            ProjectSituation.QUIET_INDOOR_OPERATION,
            ProjectSituation.INDOOR_SYSTEM_TOO_NOISY,
          ],
          skip: !(isDucted && isSystemExtension),
          secondary: true,
        },
        {
          proposition: Propositions.QUIET_INDOOR_OPERATION_DUCTLESS_STEP_12,
          conditions: [
            ProjectSituation.QUIET_INDOOR_OPERATION,
            ProjectSituation.INDOOR_SYSTEM_TOO_NOISY,
          ],
          skip: !(isDuctless && isSystemExtension),
          secondary: true,
        },
        {
          proposition: Propositions.ENERGY_EFFICIENCY_IMPORTANT_STEP_12,
          conditions: [ProjectSituation.ENERGY_EFFICIENCY_IMPORTANT],
          secondary: true,
          skip: !isSystemExtension,
        },
        {
          proposition: Propositions.UNWANTED_TEMPERATURE_FLUCTUATIONS,
          conditions: [ProjectSituation.UNWANTED_TEMPERATURE_FLUCTUATIONS],
          secondary: true,
          skip: !isSystemExtension,
        },
        {
          proposition: Propositions.ASHP_WORK_WITH_FURNACE,
          conditions: [ProjectSituation.ASHP_WORK_WITH_FURNACE],
          skip: !(
            project.steps[Steps.STEP_2]['ashpUnitConfiguration-value'] ===
              'D' && isSystemExtension
          ),
          secondary: true,
        },
        {
          proposition: Propositions.INDOOR_AIR_TOO_HUMID_SUMMER,
          conditions: [ProjectSituation.INDOOR_AIR_TOO_HUMID_SUMMER],
          secondary: true,
          skip: !isSystemExtension,
        },
        {
          proposition: Propositions.OTHER_OR_ONE_ZONE_PER_FLOOR,
          skip: !(isSystemExtension && isOtherOrOneZonePerFloor),
        },
        {
          proposition: Propositions.MEDIUM_OR_HIGH_VELOCITY,
          skip: !(isSystemExtension && isMediumOrHighVelocity),
        },
        {
          proposition: Propositions.INDOOR_NOISE_COMPLAINT_MEDIUM_HIGH_VELOCITY,
          skip: !(isSystemExtension && isMediumOrHighVelocity),
        },
        {
          proposition: Propositions.RIGHT_SIZING_HEAT_PUMP,
          conditions: [ProjectSituation.INADEQUATE_HEATING_UPPERLEVEL],
          skip: !isSystemExtension,
        },
        {
          proposition: Propositions.CHALLENGES_WITH_AESTHETICS_HVAC_OUTDOOR,
          skip: !isSystemExtension,
        },
        /// Equipment Remplacement
        {
          proposition: Propositions.TARGET_HEATING_CAPACITY,
          skip:
            !isEquipmentRemplacement ||
            !project.steps.sizingCapacityRequirements[
              'sizingCapacityRequirements-value'
            ],
        },
        {
          proposition: Propositions.AIRFLOW_CFM,
          skip: !isEquipmentRemplacement,
        },
        {
          proposition: Propositions.ELECTRIC_SERVICE,
          skip: !isEquipmentRemplacement,
        },
        {
          proposition: Propositions.ENVIRONMENTALLY_FRIENDLINESS_STEP_12,
          conditions: [ProjectSituation.ENVIRONMENTALLY_FRIENDLINESS],
          secondary: true,
          skip: !isEquipmentRemplacement,
        },
        {
          proposition: Propositions.QUIET_OUTDOOR_OPERATION,
          conditions: [ProjectSituation.QUIET_OUTDOOR_OPERATION],
          skip: !isEquipmentRemplacement,
          secondary: true,
        },
        {
          proposition: Propositions.QUIET_INDOOR_OPERATION_DUCTED_STEP_12,
          conditions: [
            ProjectSituation.QUIET_INDOOR_OPERATION,
            ProjectSituation.INDOOR_SYSTEM_TOO_NOISY,
          ],
          skip: !(isEquipmentRemplacement && isDucted),
          secondary: true,
        },
        {
          proposition: Propositions.QUIET_INDOOR_OPERATION_DUCTLESS_STEP_12,
          conditions: [
            ProjectSituation.QUIET_INDOOR_OPERATION,
            ProjectSituation.INDOOR_SYSTEM_TOO_NOISY,
          ],
          skip: !(isDuctless && isEquipmentRemplacement),
          secondary: true,
        },
        {
          proposition: Propositions.ENERGY_EFFICIENCY_IMPORTANT_STEP_12,
          conditions: [ProjectSituation.ENERGY_EFFICIENCY_IMPORTANT],
          secondary: true,
          skip: !isEquipmentRemplacement,
        },
        {
          proposition: Propositions.UNWANTED_TEMPERATURE_FLUCTUATIONS,
          conditions: [ProjectSituation.UNWANTED_TEMPERATURE_FLUCTUATIONS],
          secondary: true,
          skip: !isEquipmentRemplacement,
        },
        {
          proposition: Propositions.ASHP_WORK_WITH_FURNACE,
          conditions: [ProjectSituation.ASHP_WORK_WITH_FURNACE],
          skip: !(
            project.steps[Steps.STEP_2]['ashpUnitConfiguration-value'] ===
              'D' && isEquipmentRemplacement
          ),
          secondary: true,
        },
        {
          proposition: Propositions.INDOOR_AIR_TOO_HUMID_SUMMER,
          conditions: [ProjectSituation.INDOOR_AIR_TOO_HUMID_SUMMER],
          secondary: true,
          skip: !isEquipmentRemplacement,
        },
        //New Home
        {
          proposition: Propositions.OTHER_OR_ONE_ZONE_PER_FLOOR,
          skip: !(isNewHome && isOtherOrOneZonePerFloor),
        },
        {
          proposition: Propositions.MEDIUM_OR_HIGH_VELOCITY,
          skip: !(isNewHome && isMediumOrHighVelocity),
        },
        {
          proposition: Propositions.DHL,
          skip:
            !isNewHome ||
            project.steps['heatingCoolingLoadEstimates'].designHeatLoss == null,
        },
        {
          proposition: Propositions.TARGET_HEATING_CAPACITY,
          skip:
            !isNewHome ||
            !project.steps.sizingCapacityRequirements[
              'sizingCapacityRequirements-value'
            ],
        },
        {
          proposition: Propositions.SAFETY_FACTOR,
          skip:
            !isNewHome ||
            project.steps['heatingCoolingLoadEstimates'].designHeatLoss == null,
        },
        {
          proposition:
            Propositions.ENERGY_EFFICIENCY_IMPORTANT_NEW_HOME_STEP_12,
          conditions: [
            ProjectSituation.ENERGY_EFFICIENCY_IMPORTANT,
            ProjectSituation.LOWER_UPFRONT_COSTS,
            ProjectSituation.ENVIRONMENTALLY_FRIENDLINESS,
          ],
          secondary: true,
          skip: !isNewHome,
        },
        {
          proposition:
            Propositions.ENVIRONMENTALLY_FRIENDLINESS_NEW_HOME_STEP_12,
          conditions: [ProjectSituation.ENVIRONMENTALLY_FRIENDLINESS],
          secondary: true,
          skip: !isNewHome,
        },
        {
          proposition: Propositions.QUIET_OUTDOOR_OPERATION,
          conditions: [
            ProjectSituation.QUIET_OUTDOOR_OPERATION,
            ProjectSituation.OUTDOOR_NOISE_COMPLAINT,
          ],
          secondary: true,
          skip: !isNewHome,
        },
        {
          proposition: Propositions.HUMIDITY_COMPLAINT,
          conditions: [ProjectSituation.HUMIDITY_COMPLAINT],
          secondary: true,
          skip: !isNewHome,
        },
        {
          proposition: Propositions.INDOOR_NOISE_COMPLAINT,
          conditions: [ProjectSituation.INDOOR_NOISE_COMPLAINT],
          secondary: true,
          skip: !(isDucted && isNewHome),
        },
        {
          proposition: Propositions.INDOOR_NOISE_COMPLAINT_MEDIUM_HIGH_VELOCITY,
          conditions: [ProjectSituation.INDOOR_NOISE_COMPLAINT],
          secondary: true,
          skip: !(isMediumOrHighVelocity && isNewHome),
        },
        {
          proposition: Propositions.UTILITY_SERVICE_OPTION_RESTRICTIONS,
          conditions: [ProjectSituation.UTILITY_SERVICE_OPTION_RESTRICTIONS],
          secondary: true,
          skip: !isNewHome,
        },
        {
          proposition: Propositions.AIMING_PARTICULAR_ENERGY_STANDARD_STEP_12,
          conditions: [ProjectSituation.AIMING_PARTICULAR_ENERGY_STANDARD],
          secondary: true,
          skip: !isNewHome,
        },
        {
          proposition: Propositions.RIGHT_SIZING_HEAT_PUMP,
          conditions: [
            ProjectSituation.SECOND_FLOOR_TOO_WARM_BASEMENT_TOO_COLD_IN_SUMMER_COMPLAINT,
            ProjectSituation.HOT_OR_COLD_SPOTTING_COMPLAINT,
          ],
          skip: !isNewHome,
          secondary: true,
        },
        {
          proposition: Propositions.CHALLENGES_WITH_AESTHETICS_HVAC_OUTDOOR,
          conditions: [
            ProjectSituation.CHALLENGES_WITH_AESTHETICS_HVAC_OUTDOOR,
          ],
          secondary: true,
          skip: !isNewHome,
        },
        {
          proposition:
            Propositions.CHALLENGES_WITH_AMEMITY_SPACE_CONFLICT_HVAC_OUTDOOR,
          conditions: [
            ProjectSituation.CHALLENGES_WITH_AMEMITY_SPACE_CONFLICT_HVAC_OUTDOOR,
          ],
          secondary: true,
          skip: !isNewHome,
        },
      ],
      [Steps.STEP_13]: [],
      [Steps.STEP_13_1]: [
        // Equipment Remplacement
        {
          proposition: Propositions.LOWER_UPFRONT_COSTS_STEP_13_1,
          conditions: [ProjectSituation.LOWER_UPFRONT_COSTS],
          skip: !isEquipmentRemplacement,
        },
        {
          proposition: Propositions.ENERGY_EFFICIENCY_IMPORTANT_STEP_13_1,
          conditions: [ProjectSituation.ENERGY_EFFICIENCY_IMPORTANT],
          skip: !isEquipmentRemplacement,
        },
        {
          proposition: Propositions.ENVIRONMENTALLY_FRIENDLINESS_STEP_13_1,
          conditions: [ProjectSituation.ENVIRONMENTALLY_FRIENDLINESS],
          skip: !isEquipmentRemplacement,
        },
        {
          proposition: Propositions.CONTINUOUS_OPERATION_OF_THE_FAN,
          conditions: [
            ProjectSituation.INADEQUATE_HEATING_UPPERLEVEL,
            ProjectSituation.ROOM_OVER_GARAGE_TOO_WARM_AND_TOO_COLD,
          ],
          skip: !isEquipmentRemplacement,
        },
        //New Home
        {
          proposition:
            Propositions.ENERGY_EFFICIENCY_IMPORTANT_NEW_HOME_STEP_13_1,
          conditions: [ProjectSituation.ENERGY_EFFICIENCY_IMPORTANT],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.LOWER_UPFRONT_COSTS_STEP_13_1,
          conditions: [ProjectSituation.LOWER_UPFRONT_COSTS],
          skip: !isNewHome,
        },
        {
          proposition:
            Propositions.ENVIRONMENTALLY_FRIENDLINESS_NEW_HOME_STEP_13_1,
          conditions: [ProjectSituation.ENVIRONMENTALLY_FRIENDLINESS],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.CONTINUOUS_OPERATION_OF_THE_FAN_NEW_HOME,
          conditions: [
            ProjectSituation.HOT_OR_COLD_SPOTTING_COMPLAINT,
            ProjectSituation.SECOND_FLOOR_TOO_WARM_BASEMENT_TOO_COLD_IN_SUMMER_COMPLAINT,
          ],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.CHALLENGES_WITH_HVAC_TECH_STEP_13_1,
          conditions: [ProjectSituation.CHALLENGES_WITH_HVAC_TECH],
          skip: !isNewHome,
        },
      ],
      [Steps.STEP_14]: [
        {
          proposition: Propositions.HUMIDITY_COMPLAINT,
          conditions: [ProjectSituation.HUMIDITY_COMPLAINT],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.CHALLENGES_WITH_HVAC_TECH_STEP_14,
          conditions: [ProjectSituation.CHALLENGES_WITH_HVAC_TECH],
          skip: !isNewHome,
        },
        {
          proposition: Propositions.INDOOR_AIR_TOO_HUMID_SUMMER_STEP_14,
          conditions: [ProjectSituation.INDOOR_AIR_TOO_HUMID_SUMMER],
          skip: !isSystemExtension,
        },
      ],
      [Steps.STEP_15]: [
        {
          proposition: Propositions.MOST_EFFICENT_HP,
          conditions: [ProjectSituation.ENERGY_EFFICIENCY_IMPORTANT],
        },
        {
          proposition: Propositions.HP_LOWEST_OPERATING_COST,
          conditions: [ProjectSituation.LOWER_UPFRONT_COSTS],
        },
        {
          proposition: Propositions.HP_LOWEST_GREENHOUSE_GAZ,
          conditions: [ProjectSituation.ENVIRONMENTALLY_FRIENDLINESS],
        },
      ],
    },
  };
  return propositionsConditions;
};

export const getProjectPropositions = (
  project: Project
): ProjectPropositions => {
  const { situations } = project;
  const propositionsConditions = getPropositionsConditions(project);
  const propositions = {
    always: {},
    options: {},
    secondaries: {},
  };

  for (const listType in propositionsConditions) {
    const list = propositionsConditions[listType];

    for (const listItemKey in list) {
      const step = list[listItemKey];
      const stepPropositions: string[] = [];
      const stepAlwaysPropositions: string[] = [];
      const secondaryStepPropositions: string[] = [];

      step.forEach((stepProposition) => {
        if (!stepProposition.conditions && !stepProposition.skip) {
          stepAlwaysPropositions.push(stepProposition.proposition);
        } else if (
          !stepProposition.skip &&
          (stepProposition.conditions.some(
            (condition) => situations && situations[condition]
          ) ||
            !stepProposition.conditions.length)
        ) {
          if (stepProposition.secondary) {
            secondaryStepPropositions.push(stepProposition.proposition);
          } else {
            stepPropositions.push(stepProposition.proposition);
          }
        }
      });

      propositions[listType][listItemKey] = stepPropositions;
      propositions.secondaries[listItemKey] = secondaryStepPropositions;
      if (stepAlwaysPropositions && !propositions.always[listType]) {
        propositions.always[listType] = {};
      }
      propositions.always[listType][listItemKey] = stepAlwaysPropositions;
    }
  }

  return propositions;
};
