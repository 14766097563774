import clsx from 'clsx';
import React from 'react';
import { FormControl, HelpBlock } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldError,
  UseControllerProps,
} from 'react-hook-form';
import { FormattedInput } from '../../../model/Formatter';
import FieldGroup from './FieldGroup';

interface Props {
  className?: string;
  control: Control<any>;
  defaultValue?: string | number;
  error?: FieldError;
  help?: string;
  id: string;
  inputProps?: any;
  label?: string;
  labelHidden?: boolean;
  onBlur?: (value: { [key: string]: string | number }) => void;
  value?: any;
  helperText?: string;
  suffix?: string;
  placeholder?: string;
  type?: 'text' | 'password' | 'number' | 'hidden';
  validationRules?: UseControllerProps['rules'];
  formatter?: (value: string) => FormattedInput;
}

const Input: React.FC<Props> = ({
  className,
  control,
  defaultValue,
  error,
  help,
  id,
  label,
  labelHidden = false,
  inputProps,
  onBlur,
  value,
  helperText,
  suffix,
  placeholder,
  type = 'text',
  validationRules,
  formatter,
}) => {
  const onChangeWithFormatter = (
    event: React.FormEvent<FormControl>,
    onChange: (...event: any[]) => void
  ) => {
    if (formatter) {
      const parsedValue = formatter(
        (event.target as any).value
      ).parsed.toString();
      if ((event.target as any)?.value) {
        (event.target as any).value = parsedValue;
      }
    }
    onChange(event);
  };

  return (
    <FieldGroup
      className={clsx(className, {
        ...inputProps?.className,
        'input-with-helper-text': helperText,
      })}
      error={error}
      help={help}
      id={id}
      label={label}
      labelHidden={labelHidden}
    >
      <div className={clsx({ 'controller-container-with-suffix': suffix })}>
        <Controller
          name={id}
          control={control}
          defaultValue={defaultValue}
          rules={validationRules}
          render={({ field, fieldState }) => {
            const val = value ?? field.value;
            return (
              <>
                <FormControl
                  type={type}
                  aria-invalid={!!error ? 'true' : 'false'}
                  {...field}
                  placeholder={placeholder}
                  value={
                    formatter
                      ? formatter((val ?? '') as string).formatted
                      : val ?? ''
                  }
                  onBlur={() => {
                    if (onBlur && !fieldState.invalid) {
                      onBlur({ [field.name]: field.value });
                    }
                    field.onBlur();
                  }}
                  onChange={(e) => {
                    onChangeWithFormatter(e, field.onChange);
                  }}
                  {...inputProps}
                  className={clsx({
                    ...inputProps?.className,
                  })}
                />
              </>
            );
          }}
        />
        {suffix && <p>{suffix}</p>}
      </div>
      {helperText && (
        <HelpBlock className="input-helper-text">{helperText}</HelpBlock>
      )}
    </FieldGroup>
  );
};

export default Input;
