/* eslint-disable react-hooks/exhaustive-deps */
import { faFire, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FieldArrayWithId, useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { getBrands, getHeatPumps } from '../../../../api/heatPump';
import { BTUH_MAX_DECIMALS } from '../../../../const/Formatter';
import { getProjectPropositions } from '../../../../const/Projects';
import { env } from '../../../../env';
import { useNumberFormatter } from '../../../../hooks/useNumberFormatter';
import { HeatPump } from '../../../../model/HeatPump';
import { HeatPumpsFilter } from '../../../../model/HeatPumpsFilter';
import { PaginationEntity } from '../../../../model/PaginationEntity';
import { Project, ProjectPropositions } from '../../../../model/Project';
import { Step12Data, Steps } from '../../../../model/Step';
import { Spinner } from '../../../common';
import { DatatablePagination } from '../../../common/Datatable';
import { Input, Select, ServerFeedback } from '../../../common/Form';
import ProjectStepProposition from '../../ProjectStepProposition';
import HeatPumpSelectionCompareList from './HeatPumpSelectionCompareList';
import HeatPumpSelectionIntro from './HeatPumpSelectionIntro';

interface Props {
  project: Project;
  stepData?: Step12Data;
  updateProject?: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  additionalInfo?: JSX.Element;
  children?: JSX.Element;
}

const HeatPumpSelectionPage: React.FC<Props> = ({
  project,
  stepData,
  updateProject,
  displayCompact,
  userIsProjectOwner,
  additionalInfo,
  children,
}) => {
  const { t } = useTranslation();
  const numberFormatter = useNumberFormatter(BTUH_MAX_DECIMALS);
  const stepId = Steps.STEP_12;
  const i18nForm = `step-${stepId}:form`;
  //need a state for the data to prevent the pagination to receive undefined for currentPage and totalCount
  const [heatPumps, setHeatPumps] = useState<PaginationEntity<HeatPump>>();
  const [isInitialized, setIsInitialized] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(new HeatPumpsFilter());

  const propositions: ProjectPropositions = useMemo(() => {
    return getProjectPropositions(project);
  }, [project]);

  const { control: compareListControl } = useForm<Step12Data>({
    defaultValues: {
      heatPumpCompareList: stepData?.heatPumpCompareList ?? [],
    },
    mode: 'onChange',
  });

  const {
    fields: compareList,
    append,
    remove,
  } = useFieldArray({
    control: compareListControl,
    name: 'heatPumpCompareList',
  });

  const {
    control: filterControl,
    getValues: filterGetValues,
    reset,
    formState: { errors: filterErrors },
  } = useForm<HeatPumpsFilter>({
    defaultValues: {
      ...new HeatPumpsFilter(),
    },
    mode: 'onChange',
  });

  const { data: brands, isError: isBrandsError } = useQuery(
    ['brands'],
    async () => {
      return (await getBrands()).data;
    }
  );

  const { data, isFetching, isError } = useQuery(
    ['heatPumps', page, filter],
    async () => {
      return (await getHeatPumps(filter, page)).data;
    },
    {
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );

  const submitCompareList = (
    value: FieldArrayWithId<Step12Data, 'heatPumpCompareList', 'id'>[]
  ) => {
    updateProject &&
      updateProject({ [`steps.${[stepId]}/heatPumpCompareList`]: value });
  };

  const brandOptions = useMemo(() => {
    const options = [{ label: t(`${i18nForm}.filter.allBrands`), value: '' }];
    brands?.forEach((brand) => options.push({ value: brand, label: brand }));

    return options;
  }, [brands]);

  const validationRules = {
    min: {
      value: 1,
      message: t('errors.project.minNumber', {
        min: 1,
      }).toString(),
    },
    validate: (value) => {
      const num = Number(value);
      if (!Number.isInteger(num)) {
        return t('errors.common.notInteger').toString();
      }
      return true;
    },
  };

  useEffect(() => {
    if (data) {
      setHeatPumps(data);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      submitCompareList(compareList);
    }
  }, [compareList]);

  useEffect(() => setIsInitialized(!!heatPumps?.data), [heatPumps]);

  const noResult = !heatPumps?.data?.length;

  return (
    <>
      {displayCompact ? (
        <Row>
          <Col xs={12} md={12}>
            {userIsProjectOwner ? (
              <>
                <HeatPumpSelectionCompareList
                  compareList={compareList}
                  remove={remove}
                  userIsProjectOwner={userIsProjectOwner}
                  isLoading={isFetching}
                />
              </>
            ) : (
              <HeatPumpSelectionCompareList
                compareList={compareList}
                remove={remove}
                userIsProjectOwner={userIsProjectOwner}
                isLoading={isFetching}
              />
            )}
            {children}
            {additionalInfo}
          </Col>
        </Row>
      ) : (
        <div className="heat-pump-selection-container">
          <HeatPumpSelectionIntro project={project} />
          <HeatPumpSelectionCompareList
            compareList={compareList}
            remove={remove}
            userIsProjectOwner={userIsProjectOwner}
            isLoading={isFetching}
          />
          <form noValidate>
            <Row>
              <Col xs={12} lg={3} className="filter">
                <h2>{t(`${i18nForm}.filter.title`)}</h2>
                <hr />
                <Select
                  label={t(`${i18nForm}.filter.brand`)}
                  id={'brand'}
                  control={filterControl}
                  options={brandOptions}
                />
                {isBrandsError && (
                  <ServerFeedback type="error">
                    {t('errors.common.unknown')}
                  </ServerFeedback>
                )}
                <Input
                  label={t(`${i18nForm}.filter.ahriModelUnit`)}
                  id={'ahriModelUnit'}
                  control={filterControl}
                  placeholder={t(`${i18nForm}.filter.ahriModelUnit`)}
                  className="ahriModelUnit"
                />
                <Input
                  id="capacity47Min"
                  control={filterControl}
                  label={t(`${i18nForm}.filter.capacity47Min`)}
                  inputProps={{ disabled: !userIsProjectOwner }}
                  validationRules={validationRules}
                  error={filterErrors.capacity47Min}
                  suffix={t(`${i18nForm}.filter.btu`)}
                  formatter={numberFormatter}
                />
                <Input
                  id="capacity5Min"
                  control={filterControl}
                  label={t(`${i18nForm}.filter.capacity5Min`)}
                  inputProps={{ disabled: !userIsProjectOwner }}
                  validationRules={validationRules}
                  error={filterErrors.capacity5Min}
                  suffix={t(`${i18nForm}.filter.btu`)}
                  formatter={numberFormatter}
                />
                <Row>
                  <Col xs={12}>
                    <Button
                      onClick={() => {
                        setFilter(filterGetValues());
                      }}
                      className="btn btn-contrast btn-sm"
                    >
                      {t(`${i18nForm}.filter.applyFilter`)}
                    </Button>
                  </Col>
                  <Col xs={12}>
                    <Button
                      onClick={() => {
                        reset(new HeatPumpsFilter());
                        setFilter(new HeatPumpsFilter());
                      }}
                      className="btn btn-primary btn-contrast btn-sm"
                    >
                      {t(`${i18nForm}.filter.resetFilter`)}
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={9}>
                <h2>{t(`${i18nForm}.heatPump.title`)}</h2>
                <hr />
                <div className="heat-pumps-container">
                  {isFetching ? (
                    <Spinner />
                  ) : isError ? (
                    <ServerFeedback type="error">
                      {t('errors.common.unknown')}
                    </ServerFeedback>
                  ) : !noResult ? (
                    <div className="heat-pump-container">
                      {heatPumps?.data.map((heatPump) => (
                        <div className="heat-pump" key={heatPump.ahri_Number}>
                          <div>
                            <h3 className="heat-pump-name">{heatPump.name}</h3>
                            <div className="model-number">
                              <p>
                                {t(`${i18nForm}.heatPump.ahriModelUnit`)}
                                <strong>{heatPump.ahri_Number}</strong>
                              </p>
                              <p>
                                {t(`${i18nForm}.heatPump.outdoorUnitModel`)}
                                <strong>{heatPump.outdoorUnitNumber}</strong>
                              </p>
                              <p>
                                {t(`${i18nForm}.heatPump.indoorUnitModel`)}
                                <strong>{heatPump.indoorUnitNumber}</strong>
                              </p>
                            </div>
                            <div className="rated-btuh">
                              <p>
                                <FontAwesomeIcon
                                  icon={faFire}
                                  className="fire-icon"
                                />
                                <Trans>
                                  {t(
                                    `${i18nForm}.heatPump.maxHeatingCapacityAt47F`,
                                    {
                                      number: heatPump.maxHeatingCapacityAt47F,
                                    }
                                  )}
                                </Trans>
                              </p>
                              <p>
                                <FontAwesomeIcon
                                  icon={faFire}
                                  className="fire-icon"
                                />
                                <Trans>
                                  {t(
                                    `${i18nForm}.heatPump.maxHeatingCapacityAt17F`,
                                    {
                                      number: heatPump.maxHeatingCapacityAt17F,
                                    }
                                  )}
                                </Trans>
                              </p>
                              {heatPump.maxHeatingCapacityAt5F && (
                                <p>
                                  <FontAwesomeIcon
                                    icon={faFire}
                                    className="fire-icon"
                                  />
                                  <Trans>
                                    {t(
                                      `${i18nForm}.heatPump.maxHeatingCapacityAt5F`,
                                      {
                                        number: heatPump.maxHeatingCapacityAt5F,
                                      }
                                    )}
                                  </Trans>
                                </p>
                              )}
                            </div>
                            {heatPump.copAt5F && (
                              <p>
                                {t(`${i18nForm}.heatPump.cop5`)}{' '}
                                <strong>{heatPump.copAt5F}</strong>
                              </p>
                            )}
                            {heatPump.hspf && (
                              <p>
                                {t(`${i18nForm}.heatPump.hspf`)}
                                <strong>{heatPump.hspf}</strong>
                              </p>
                            )}
                            <div className="heat-pump-button">
                              <Button
                                disabled={
                                  !!stepData?.heatPumpCompareList.find(
                                    (hp) =>
                                      hp.ahri_Number === heatPump.ahri_Number
                                  ) ||
                                  (!!env.REACT_APP_API_MAX_HP_COMPARE_COUNT &&
                                    Number(
                                      env.REACT_APP_API_MAX_HP_COMPARE_COUNT
                                    ) <=
                                      (stepData?.heatPumpCompareList.length ??
                                        0))
                                }
                                onClick={() => {
                                  append(heatPump);
                                }}
                                className="btn btn-contrast btn-sm btn-icon-left"
                              >
                                <FontAwesomeIcon icon={faPlus} />
                                {t(`${i18nForm}.heatPump.compare`)}
                              </Button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>{t(`${i18nForm}.heatPump.noResult`)}</p>
                  )}
                </div>
                {isInitialized && !noResult && (
                  <DatatablePagination
                    currentPage={page}
                    onChangePage={(page: number) => {
                      setPage(page);
                    }}
                    rowCount={heatPumps?.totalCount ?? 0}
                    rowsPerPage={60}
                    useArrow
                  />
                )}
              </Col>
            </Row>
          </form>
          <ProjectStepProposition
            id={stepId}
            propositions={propositions.secondaries[stepId]}
            withListStyle
          />
        </div>
      )}
    </>
  );
};

export default HeatPumpSelectionPage;
