import { Project } from '../../../model/Project';
import { NewCommentForm, PreviousComments } from './';

interface Props {
  pageId: string;
  project: Project;
}

const ProjectComments: React.FC<Props> = ({ pageId, project }) => {
  const pageComments = project.comments ? project.comments[pageId] || [] : [];

  return (
    <>
      <NewCommentForm pageId={pageId} project={project} />

      <PreviousComments pageComments={pageComments} />
    </>
  );
};

export default ProjectComments;
