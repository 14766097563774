import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '../../common/Form';
import { Step } from '../../../model/Step';
import { Project } from '../../../model/Project';
import { usePartialProjectUpdateMutation } from '../../../hooks/usePartialProjectUpdateMutation';
import { getWorkflowWithoutSubSteps } from '../../../utils/workflowUtils';
import { SubStepsList } from '../../../const/Steps';

interface Props {
  completedSteps: string[];
  project: Project;
  step: Step;
  checkboxClassName?: string;
  hideChild?: boolean;
}

interface FormValue {
  completed: boolean;
}

const MarkCompleteCheckbox: React.FC<Props> = ({
  completedSteps,
  project,
  step,
  checkboxClassName,
  hideChild,
}) => {
  const { t } = useTranslation();
  const stepIsCompleted = completedSteps.includes(step.id);
  const IsSubStep = SubStepsList.some((ss) => step.id === ss.id);
  const [stepId, setStepId] = useState<string | undefined>();
  const { control, setValue } = useForm<FormValue>({
    defaultValues: {
      completed: stepIsCompleted,
    },
  });

  const partialUpdateProjectMutation = usePartialProjectUpdateMutation(project);

  const stepsList = useMemo(
    () => getWorkflowWithoutSubSteps(project),
    [project]
  );

  useEffect(() => {
    setValue('completed', stepIsCompleted);
    if (step.id !== stepId) {
      setStepId(step.id);
    }
  }, [step, stepId, setValue, stepIsCompleted]);

  const setCompleted = (value) => {
    if (step.id === stepId) {
      if (stepIsCompleted !== value.completed) {
        let completedStepsUpdate = [...completedSteps];

        if (value.completed) {
          completedStepsUpdate = [...completedStepsUpdate, step.id];
        } else {
          completedStepsUpdate = completedStepsUpdate.filter(
            (completedStep) => completedStep !== step.id
          );
        }

        partialUpdateProjectMutation.mutate([
          { op: 'add', path: '/updatedAt', value: new Date() },
          { op: 'add', path: '/completedSteps', value: completedStepsUpdate },
          {
            op: 'add',
            path: '/isCompleted',
            value: completedStepsUpdate.length === stepsList.length,
          },
        ]);
      }
    }
  };

  if (IsSubStep) {
    return null;
  }

  return (
    <Checkbox
      className={checkboxClassName || ''}
      control={control}
      id="completed"
      onChange={setCompleted}
      hideChild={hideChild}
    >
      {t('stepPage.markAsComplete')}
    </Checkbox>
  );
};

export default MarkCompleteCheckbox;
