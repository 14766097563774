import { FurnaceType } from '../HeatPumpWithBackup/FurnaceType';

export class FurnaceCost {
  furnaceType?: FurnaceType;
  cost?: number;

  constructor(entity?: FurnaceCost) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.furnaceType = FurnaceType.None;
      this.cost = 1;
    }
  }
}
