import { Col, Row, Well } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Project, ProjectType } from '../../model/Project';
import {
  getNewHomeChecklistConsiderations,
  getEquipmentRemplacementAndSystemExtensionChecklistConsiderations,
} from '../../utils/checklistConsiderationUtils';

interface Props {
  project: Project;
}

const ChecklistConsiderations: React.FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const isNewHome =
    project.projectType === ProjectType.FULL_SYSTEM_REPLACEMENT ||
    project.projectType === ProjectType.NEW_SYSTEM_INSTALLATION;

  const getConditonals = () => {
    switch (project.projectType) {
      case ProjectType.FULL_SYSTEM_REPLACEMENT:
      case ProjectType.NEW_SYSTEM_INSTALLATION:
        return getNewHomeChecklistConsiderations(project);

      case ProjectType.EQUIPMENT_REPLACEMENT_ONLY:
        return getEquipmentRemplacementAndSystemExtensionChecklistConsiderations(
          project
        );

      case ProjectType.SYSTEM_EXTENSION:
        return getEquipmentRemplacementAndSystemExtensionChecklistConsiderations(
          project
        );

      default:
        return [];
    }
  };
  const globalRemarks = isNewHome
    ? [
        t('overview.proposition.checklist.newHome.globalRemarks.1'),
        t('overview.proposition.checklist.newHome.globalRemarks.2'),
        t('overview.proposition.checklist.newHome.globalRemarks.3'),
      ]
    : project.projectType === ProjectType.SYSTEM_EXTENSION
    ? [
        t(
          'overview.proposition.checklist.equipmentRemplacementAndSystemExtension.globalRemarks.1'
        ),
        t(
          'overview.proposition.checklist.equipmentRemplacementAndSystemExtension.globalRemarks.2'
        ),
        t(
          'overview.proposition.checklist.equipmentRemplacementAndSystemExtension.globalRemarks.3'
        ),
        t(
          'overview.proposition.checklist.equipmentRemplacementAndSystemExtension.globalRemarks.4'
        ),
      ]
    : [];

  const conditionals = getConditonals();

  return (
    <>
      {!!globalRemarks.length && (
        <Well className="well-dark proposition">
          <Row>
            <Col xs={1}>
              <FontAwesomeIcon icon={faExclamationCircle} />
            </Col>
            <Col xs={10} sm={11}>
              <div className="proposition-content with-list-style">
                <span className="well-title">
                  {t(
                    'overview.proposition.checklist.newHome.globalRemarks.title'
                  )}
                </span>
                <ul>
                  {globalRemarks.map((text, index) => (
                    <li key={`globalRemark-${index}`} className="proposition">
                      {text}
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </Well>
      )}
      {!!conditionals.length && (
        <Well className="well-dark proposition">
          <Row>
            <Col xs={1}>
              <FontAwesomeIcon icon={faExclamationCircle} />
            </Col>
            <Col xs={10} sm={11}>
              <div className="proposition-content with-list-style">
                <span className="well-title">
                  {t('overview.proposition.checklist.title')}
                </span>
                <ul>
                  {conditionals.map((text, index) => (
                    <li key={`conditional-${index}`} className="proposition">
                      {text}
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </Well>
      )}
    </>
  );
};

export default ChecklistConsiderations;
