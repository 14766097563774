import { useMemo, useState } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { Project } from '../../model/Project';
import { getProjectSituationsList } from '../../utils/workflowUtils';
import { Checkbox } from '../common/Form';
import { VideoModal } from './Videos';

interface Props {
  project?: Project;
  control: Control<any>;
  onChange?: (value: { [key: string]: string | number }) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  viewCheckedItemsOnly?: boolean;
}

const ProjectDetailsForm: React.FC<Props> = ({
  project,
  control,
  onChange,
  displayCompact,
  userIsProjectOwner,
  viewCheckedItemsOnly,
}) => {
  const { t } = useTranslation();
  const [showVideo, setShowVideo] = useState(false);

  const situationsList = useMemo(
    () => getProjectSituationsList(project),
    [project]
  );

  const checkboxes = situationsList.map((situationId) => (
    <Checkbox
      key={`situation-${situationId}`}
      id={`situations.${situationId}`}
      control={control}
      disabled={!userIsProjectOwner}
      onChange={onChange}
    >
      {t(`situation.${situationId}`)}
    </Checkbox>
  ));

  const checkboxesLabel = (
    <label className="section-label">
      {t('project.details.situations.label')}
    </label>
  );

  const videoButton = (
    <Button
      aria-label={`${t('common.watchVideo')}: ${t(
        'project.details.situations.video.title'
      )}`}
      className="btn btn-primary video-button"
      onClick={(e) => setShowVideo(true)}
    >
      <FontAwesomeIcon icon={faPlayCircle} />
      <span className="video-button-text">{t('common.watchVideo')}</span>
    </Button>
  );

  return (
    <>
      <VideoModal
        video={{
          href: t('project.details.situations.video.href'),
          title: t('project.details.situations.video.title'),
        }}
        show={showVideo}
        setShow={setShowVideo}
      />
      {displayCompact ? (
        <>
          {checkboxesLabel}
          {project?.situations && viewCheckedItemsOnly
            ? situationsList
                .filter((situation) =>
                  project?.situations[situation]?.valueOf()
                )
                .map((situation) => (
                  <p key={situation}>{t(`situation.${situation}`)}</p>
                ))
            : checkboxes}
          {videoButton}
        </>
      ) : (
        <>
          {checkboxesLabel}
          <p>{t('project.details.situations.description')}</p>
          {checkboxes}
          {videoButton}
        </>
      )}
    </>
  );
};

export default ProjectDetailsForm;
