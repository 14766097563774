import { Dispatch, SetStateAction } from 'react';
import { Col, Row } from 'react-bootstrap';
import { StepOption } from '../../../model/Step';
import Dialog from '../../common/Dialog/Dialog';

interface Props {
  option: StepOption;
  setShow: Dispatch<SetStateAction<boolean>>;
  show?: boolean;
}

const OptionDetailsModal: React.FC<Props> = ({ option, show, setShow }) => {
  if (!show || !option.modal) return null;

  const describedbyId = option.img
    ? `${option.img.fileName}-description-option-modal`
    : '';
  const modalImg = option.modalImg ? option.modalImg : option.img;
  return (
    <Dialog
      onHide={() => setShow(false)}
      modalProps={{
        backdrop: true,
        bsSize: 'lg',
        className: 'option-modal',
        onHide: () => setShow(false),
      }}
      show={show}
      title={
        <>
          <div className="modal-title-secondary">
            {option && option.option && option.option.title}
          </div>
          <div className="modal-title">{option.modal.title}</div>
        </>
      }
    >
      <Row>
        {option.modal.content && (
          <Col xs={12} md={modalImg ? 8 : 12}>
            {option.modal.content}
          </Col>
        )}
        {modalImg && (
          <>
            <Col
              key={`modal-img-${modalImg.fileName}`}
              xs={12}
              md={option.img ? 4 : 12}
              className="text-right-md text-right-lg text-center-sm text-center-xs"
              mdPush={!option.modal.content ? 8 : undefined}
            >
              <img
                src={`/assets/images/${modalImg.fileName}`}
                alt={modalImg.alt}
                aria-describedby={describedbyId}
              />
              <p id={describedbyId} className="sr-only">
                {modalImg.longDescription}
              </p>
            </Col>
            <Col xs={12} md={8} mdPull={!option.modal.content ? 4 : undefined}>
              <p>{modalImg.longDescription}</p>
            </Col>
          </>
        )}
      </Row>
    </Dialog>
  );
};

export default OptionDetailsModal;
