import React, { useMemo } from 'react';
import { Col, Row, Well } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import LoginButton from './LoginButton';

interface Props {
  withSignup?: boolean;
}

const LoginForm: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  return (
    <Well className="login-form">
      <Row>
        <Col xs={12}>
          <LoginButton action="signIn" />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <LoginButton action="signUp" />
        </Col>
      </Row>
      {query.get(t('logoutReason.queryKey')) ===
        t('logoutReason.inactive.queryValue') && (
        <Col xs={12}>
          <Row className="logout-reason-message">
            {t('logoutReason.inactive.text')}
          </Row>
        </Col>
      )}
    </Well>
  );
};

export default LoginForm;
