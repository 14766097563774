import { Step, Steps } from '../model/Step';
import { Project, ProjectSituation, ProjectType } from '../model/Project';
import { StepsList, SubStepsList } from '../const/Steps';

export const getWorkflow = (project?: Project) => {
  let list: Step[] =
    project?.steps.ashpUnitConfiguration.numberOfIndoorUnits ||
    project?.steps.ashpUnitConfiguration.numberOfOutdoorUnits
      ? [StepsList[0], StepsList[1], SubStepsList[0], StepsList[2]]
      : [StepsList[0], StepsList[1], StepsList[2]];

  const isCentrallyDucted =
    project?.steps[Steps.STEP_2]['ashpUnitConfiguration-value'] === 'A';
  const isNotSingleZone =
    project?.steps[Steps.STEP_6]['zoningSupplyDucts-value'] !== 'A';

  switch (project?.projectType) {
    case ProjectType.NEW_SYSTEM_INSTALLATION:
    case ProjectType.FULL_SYSTEM_REPLACEMENT:
      list = [
        ...list,
        ...(isCentrallyDucted
          ? [StepsList[3], StepsList[4], StepsList[6]]
          : []),
        ...(isCentrallyDucted && isNotSingleZone ? [StepsList[7]] : []),
        ...(isCentrallyDucted ? [StepsList[8], StepsList[9]] : []),
        StepsList[10],
        ...getStepAndSubStep(StepsList[12], project),
        ...getStepAndSubStep(StepsList[13], project),
        StepsList[14],
        StepsList[15],
      ];
      break;
    case ProjectType.EQUIPMENT_REPLACEMENT_ONLY:
      list = [
        ...list,
        ...getStepAndSubStep(StepsList[5], project),
        StepsList[10],
        ...getStepAndSubStep(StepsList[12], project),
        ...getStepAndSubStep(StepsList[13], project),
        StepsList[15],
      ];
      break;
    case ProjectType.SYSTEM_EXTENSION:
      list = [
        ...list,
        StepsList[3],
        StepsList[4],
        StepsList[6],
        ...(isCentrallyDucted && isNotSingleZone ? [StepsList[7]] : []),
        StepsList[8],
        StepsList[9],
        ...getStepAndSubStep(StepsList[5], project),
        StepsList[10],
        ...getStepAndSubStep(StepsList[12], project),
        ...getStepAndSubStep(StepsList[13], project),
        StepsList[14],
        StepsList[15],
      ];
      break;

    default:
      break;
  }

  // we need to count the number of subStep in the list to substrack that number to the index otherwise
  // the index is getting bigger than supposed therfore the stepNumber will be higher by the number of step in the list
  let subStepCount = 0;
  list = list.map((item, index) => {
    const isSubStep = !!SubStepsList.find((sl) => sl.id === item.id);
    const updatedStep = {
      ...item,
      number: !isSubStep
        ? index + 1 - subStepCount
        : index + 0.1 - subStepCount, // because its a substep we are doing + 0.1 to add .1 to the stepNumber, the result will be ex: 2.1
    };
    if (isSubStep) {
      subStepCount += 1;
    }
    return updatedStep;
  });

  return list;
};

const getStepAndSubStep = (step: Step, project: Project) => {
  switch (step.id) {
    case 'existingConstraints':
      if (
        project.steps.ashpUnitConfiguration['ashpUnitConfiguration-value'] ===
        'A'
      ) {
        if (
          project.steps.existingConstraints[
            'airflowCapacityMeasurementMethod-value'
          ] === 'A'
        ) {
          return [step, SubStepsList[1]];
        }
        if (
          project.steps.existingConstraints[
            'airflowCapacityMeasurementMethod-value'
          ] === 'B'
        ) {
          return [step, SubStepsList[2]];
        }
      }
      return [step];
    case 'heatPumpSelection':
      if (project.steps.heatPumpSelection.heatPumpCompareList.length) {
        return [step, SubStepsList[3]];
      }
      return [step];
    case 'utilityCosts':
      if (project.steps.heatPumpSelection.heatPumpCompareList.length) {
        return [step, SubStepsList[4]];
      }
      return [step];
  }
  return [step];
};

export const getWorkflowWithoutSubSteps = (project?: Project) => {
  const workflow = getWorkflow(project);
  return workflow.filter(
    (item) => !SubStepsList.find((sl) => sl.id === item.id)
  );
};

export const getProjectSituationsList = (project?: Project) => {
  let situationList: ProjectSituation[] = [];

  switch (project?.projectType) {
    case ProjectType.EQUIPMENT_REPLACEMENT_ONLY:
    case ProjectType.SYSTEM_EXTENSION:
      situationList = [
        ProjectSituation.ENERGY_EFFICIENCY_IMPORTANT,
        ProjectSituation.LOWER_UPFRONT_COSTS,
        ProjectSituation.ENVIRONMENTALLY_FRIENDLINESS,
        ProjectSituation.QUIET_OUTDOOR_OPERATION,
        ProjectSituation.QUIET_INDOOR_OPERATION,
        ProjectSituation.INDOOR_SYSTEM_TOO_NOISY,
        ProjectSituation.INADEQUATE_HEATING_UPPERLEVEL,
        ProjectSituation.INADEQUATE_HEATING_BASEMENT,
        ProjectSituation.ASHP_WORK_WITH_FURNACE,
        ProjectSituation.BASEMENT_LIKELY_FINISHED,
        ProjectSituation.PLANNING_IMPROVEMENT_ENERGY_EFFICENCY,
        ProjectSituation.POOR_INDOOR_AIR_QUALITY,
        ProjectSituation.INDOOR_AIR_TOO_DRY_WINTER,
        ProjectSituation.INDOOR_AIR_TOO_HUMID_SUMMER,
        ProjectSituation.UNWANTED_TEMPERATURE_FLUCTUATIONS,
        ProjectSituation.ROOM_OVER_GARAGE_TOO_WARM_AND_TOO_COLD,
      ];
      break;
    case ProjectType.NEW_SYSTEM_INSTALLATION:
    case ProjectType.FULL_SYSTEM_REPLACEMENT:
      situationList = [
        ProjectSituation.ENERGY_EFFICIENCY_IMPORTANT,
        ProjectSituation.LOWER_UPFRONT_COSTS,
        ProjectSituation.ENVIRONMENTALLY_FRIENDLINESS,
        ProjectSituation.QUIET_OUTDOOR_OPERATION,
        ProjectSituation.HUMIDITY_COMPLAINT,
        ProjectSituation.HOT_OR_COLD_SPOTTING_COMPLAINT,
        ProjectSituation.SECOND_FLOOR_TOO_WARM_BASEMENT_TOO_COLD_IN_SUMMER_COMPLAINT,
        ProjectSituation.OUTDOOR_NOISE_COMPLAINT,
        ProjectSituation.INDOOR_NOISE_COMPLAINT,
        ProjectSituation.AIMING_PARTICULAR_ENERGY_STANDARD,
        ProjectSituation.CHALLENGES_WITH_HVAC_TECH,
        ProjectSituation.CONSIDER_ZONING_HOME,
        ProjectSituation.UTILITY_SERVICE_OPTION_RESTRICTIONS,
        ProjectSituation.CHALLENGES_WITH_AESTHETICS_HVAC_INDOOR,
        ProjectSituation.CHALLENGES_WITH_AESTHETICS_HVAC_OUTDOOR,
        ProjectSituation.CHALLENGES_WITH_AMEMITY_SPACE_CONFLICT_HVAC_OUTDOOR,
        ProjectSituation.BYLAWS_PLACEMENT_OUTDOOR,
        ProjectSituation.SUPPLY_CHAIN_ISSUES,
        ProjectSituation.BASEMENT_FINISHED,
      ];
      break;

    default:
      break;
  }

  return situationList;
};
