import { BillingType } from './BillingType';

export class ElectricityCost {
  billingType?: BillingType;
  cost?: number;
  onPeakCost?: number;
  midPeakCost?: number;
  offPeakCost?: number;

  constructor(entity?: ElectricityCost) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.billingType = BillingType.Fixed;
      this.cost = 1;
      this.onPeakCost = 1;
      this.midPeakCost = 1;
      this.offPeakCost = 1;
    }
  }
}
