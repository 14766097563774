import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SubStepsList } from '../../../const/Steps';
import { usePartialProjectUpdateMutation } from '../../../hooks/usePartialProjectUpdateMutation';
import { Project } from '../../../model/Project';
import { Step } from '../../../model/Step';
import { TextArea } from '../../common/Form';

interface Props {
  project: Project;
  step: Step;
  additionalInformation?: { [key: string]: string };
  viewOnly?: boolean;
}
interface FormValue {
  [key: string]: string;
}

const AdditionalInfoInput: React.FC<Props> = ({
  project,
  step,
  additionalInformation = {},
  viewOnly,
}) => {
  const { t } = useTranslation();
  const IsSubStep = SubStepsList.some((ss) => step.id === ss.id);
  const {
    control,
    formState: { errors },
    setValue,
  } = useForm<FormValue>({
    defaultValues: additionalInformation,
    mode: 'onBlur',
  });

  const partialUpdateProjectMutation = usePartialProjectUpdateMutation(project);

  useEffect(() => {
    setValue(step.id, additionalInformation[step.id] || '');
  }, [step, setValue, additionalInformation]);

  const saveAdditionalInformation = (value) => {
    partialUpdateProjectMutation.mutate([
      { op: 'add', path: '/updatedAt', value: new Date() },
      {
        op: 'add',
        // eslint-disable-next-line prettier/prettier
        path: `/additionalInformation/${Object.keys(value)[0].replace(
          '.',
          '/'
        )}`,
        value: value[Object.keys(value)[0]],
      },
    ]);
  };

  if (IsSubStep) {
    return null;
  }

  return (
    <>
      {viewOnly ? (
        <div className="additional-info-view">
          <label>{t('stepPage.additionalInformation.label')}</label>
          <p>{additionalInformation[step.id]}</p>
        </div>
      ) : (
        <TextArea
          control={control}
          id={step.id}
          label={t('stepPage.additionalInformation.label')}
          onBlur={saveAdditionalInformation}
          error={errors?.[step.id]}
          validationRules={{
            maxLength: {
              value: 1000,
              message: t('errors.common.maxLength', { maxLength: 1000 }),
            },
          }}
        />
      )}
    </>
  );
};

export default AdditionalInfoInput;
