import { Project, ProjectSituation } from '../model/Project';
import i18n from '../i18n';
import { BackupType } from '../model/HeatPumpWithBackup/BackupType';

export const getNewHomeChecklistConsiderations = (project: Project) => {
  const conditionals: string[] = [];
  const hpThatAreNotVariableSpeed =
    project.steps.heatPumpSelection.heatPumpCompareList.filter(
      (hp) => hp.variableCapacity
    );

  const hpWithHspfLowerThan10 =
    project.steps.heatPumpSelection.heatPumpCompareList.filter((hp) =>
      hp.hspf ? hp.hspf < 10 : false
    );
  if (
    [ProjectSituation.ENERGY_EFFICIENCY_IMPORTANT].some(
      (condition) => project.situations && project.situations[condition]
    ) &&
    hpWithHspfLowerThan10.length
  ) {
    conditionals.push(
      i18n.t('overview.proposition.checklist.newHome.conditional.2', {
        heatPumps: hpWithHspfLowerThan10.map((hp) => hp.name).join(', '),
      })
    );
  }

  if (
    [ProjectSituation.ENERGY_EFFICIENCY_IMPORTANT].some(
      (condition) => project.situations && project.situations[condition]
    ) &&
    project.steps.sizingCapacityRequirements[
      'sizingCapacityRequirements-value'
    ] !== 'D'
  ) {
    conditionals.push(
      i18n.t('overview.proposition.checklist.newHome.conditional.3', {
        heatPumps: project.steps.heatPumpSelection.heatPumpCompareList
          .map((hp) => hp.name)
          .join(', '),
      })
    );
  }

  if (
    [ProjectSituation.INDOOR_NOISE_COMPLAINT].some(
      (condition) => project.situations && project.situations[condition]
    ) &&
    hpThatAreNotVariableSpeed.length
  ) {
    conditionals.push(
      i18n.t('overview.proposition.checklist.newHome.conditional.4', {
        heatPumps: hpThatAreNotVariableSpeed.map((hp) => hp.name).join(', '),
      })
    );
  }

  const hpWithEconomicCutOff =
    project.steps.backupControlStrategy.backupControlStrategies.filter(
      (hp) => hp['backupControlStrategy-value'] === 'B'
    );
  if (
    [ProjectSituation.ENVIRONMENTALLY_FRIENDLINESS].some(
      (condition) => project.situations && project.situations[condition]
    ) &&
    (hpWithEconomicCutOff.length ||
      (project.steps.sizingCapacityRequirements[
        'sizingCapacityRequirements-value'
      ] !== 'C' &&
        project.steps.sizingCapacityRequirements[
          'sizingCapacityRequirements-value'
        ] !== 'D'))
  ) {
    conditionals.push(
      i18n.t('overview.proposition.checklist.newHome.conditional.5', {
        heatPumps: (hpWithEconomicCutOff.length
          ? hpWithEconomicCutOff
          : project.steps.heatPumpSelection.heatPumpCompareList
        )
          .map((hp) => hp.name)
          .join(', '),
      })
    );
  }

  if (
    [ProjectSituation.HUMIDITY_COMPLAINT].some(
      (condition) => project.situations && project.situations[condition]
    )
  ) {
    conditionals.push(
      i18n.t('overview.proposition.checklist.newHome.conditional.6')
    );
  }

  if (
    [
      ProjectSituation.HOT_OR_COLD_SPOTTING_COMPLAINT,
      ProjectSituation.SECOND_FLOOR_TOO_WARM_BASEMENT_TOO_COLD_IN_SUMMER_COMPLAINT,
    ].some(
      (condition) => project.situations && project.situations[condition]
    ) &&
    project.steps.zoningSupplyDucts['zoningSupplyDucts-value'] !== 'A'
  ) {
    conditionals.push(
      i18n.t('overview.proposition.checklist.newHome.conditional.7')
    );
  }

  if (hpThatAreNotVariableSpeed.length) {
    conditionals.push(
      i18n.t('overview.proposition.checklist.newHome.conditional.8', {
        heatPumps: hpThatAreNotVariableSpeed.map((hp) => hp.name).join(', '),
      })
    );
  }

  if (
    [ProjectSituation.CONSIDER_ZONING_HOME].some(
      (condition) => project.situations && project.situations[condition]
    ) &&
    project.steps.zoningSupplyDucts['zoningSupplyDucts-value'] === 'A'
  ) {
    conditionals.push(
      i18n.t('overview.proposition.checklist.newHome.conditional.9')
    );
  }

  conditionals.push(
    i18n.t('overview.proposition.checklist.newHome.conditional.10')
  );

  return conditionals;
};

export const getEquipmentRemplacementAndSystemExtensionChecklistConsiderations =
  (project: Project) => {
    const conditionals: string[] = [];
    const hpThatAreNotVariableSpeed =
      project.steps.heatPumpSelection.heatPumpCompareList.filter(
        (hp) => hp.variableCapacity
      );

    const hpWithHspfLowerThan10 =
      project.steps.heatPumpSelection.heatPumpCompareList.filter((hp) =>
        hp.hspf ? hp.hspf < 10 : false
      );
    if (
      [ProjectSituation.ENERGY_EFFICIENCY_IMPORTANT].some(
        (condition) => project.situations && project.situations[condition]
      ) &&
      hpWithHspfLowerThan10.length
    ) {
      conditionals.push(
        i18n.t(
          'overview.proposition.checklist.equipmentRemplacementAndSystemExtension.conditional.2',
          {
            heatPumps: hpWithHspfLowerThan10.map((hp) => hp.name).join(', '),
          }
        )
      );
    }

    conditionals.push(
      i18n.t(
        'overview.proposition.checklist.equipmentRemplacementAndSystemExtension.conditional.3'
      )
    );

    if (
      [
        ProjectSituation.QUIET_INDOOR_OPERATION,
        ProjectSituation.INDOOR_SYSTEM_TOO_NOISY,
      ].some(
        (condition) => project.situations && project.situations[condition]
      ) &&
      hpThatAreNotVariableSpeed.length
    ) {
      conditionals.push(
        i18n.t(
          'overview.proposition.checklist.equipmentRemplacementAndSystemExtension.conditional.4',
          {
            heatPumps: hpThatAreNotVariableSpeed
              .map((hp) => hp.name)
              .join(', '),
          }
        )
      );
    }

    const hpWithLessThan200AOfElectricSize =
      project.steps.heatPumpSelection.heatPumpCompareList.filter(
        (hp) => hp.electricSize ?? 0 > 200
      );
    if (hpWithLessThan200AOfElectricSize.length) {
      conditionals.push(
        i18n.t(
          'overview.proposition.checklist.equipmentRemplacementAndSystemExtension.conditional.5',
          {
            heatPumps: hpWithLessThan200AOfElectricSize
              .map((hp) => hp.name)
              .join(', '),
          }
        )
      );
    }

    const hpWithEconomicCutOff =
      project.steps.backupControlStrategy.backupControlStrategies.filter(
        (hp) => hp['backupControlStrategy-value'] === 'B'
      );
    if (
      [ProjectSituation.ENVIRONMENTALLY_FRIENDLINESS].some(
        (condition) => project.situations && project.situations[condition]
      ) &&
      (hpWithEconomicCutOff.length ||
        (project.steps.sizingCapacityRequirements[
          'sizingCapacityRequirements-value'
        ] !== 'C' &&
          project.steps.sizingCapacityRequirements[
            'sizingCapacityRequirements-value'
          ] !== 'D'))
    ) {
      conditionals.push(
        i18n.t(
          'overview.proposition.checklist.equipmentRemplacementAndSystemExtension.conditional.6',
          {
            heatPumps: (hpWithEconomicCutOff.length
              ? hpWithEconomicCutOff
              : project.steps.heatPumpSelection.heatPumpCompareList
            )
              .map((hp) => hp.name)
              .join(', '),
          }
        )
      );
    }

    const hpWithFurnace =
      project.steps.heatPumpSelection.heatPumpCompareList.filter(
        (hp) => hp.backupType === BackupType.Furnace
      );
    if (
      [ProjectSituation.HUMIDITY_COMPLAINT].some(
        (condition) => project.situations && project.situations[condition]
      ) &&
      hpWithFurnace.length
    ) {
      conditionals.push(
        i18n.t(
          'overview.proposition.checklist.equipmentRemplacementAndSystemExtension.conditional.7',
          {
            heatPumps: hpWithFurnace.map((hp) => hp.name).join(', '),
          }
        )
      );
    }

    if (
      [ProjectSituation.INDOOR_AIR_TOO_DRY_WINTER].some(
        (condition) => project.situations && project.situations[condition]
      )
    ) {
      conditionals.push(
        i18n.t(
          'overview.proposition.checklist.equipmentRemplacementAndSystemExtension.conditional.8'
        )
      );
    }

    if (
      [ProjectSituation.POOR_INDOOR_AIR_QUALITY].some(
        (condition) => project.situations && project.situations[condition]
      )
    ) {
      conditionals.push(
        i18n.t(
          'overview.proposition.checklist.equipmentRemplacementAndSystemExtension.conditional.9'
        )
      );
    }

    if (
      [
        ProjectSituation.INADEQUATE_HEATING_UPPERLEVEL,
        ProjectSituation.INADEQUATE_HEATING_BASEMENT,
        ProjectSituation.BASEMENT_LIKELY_FINISHED,
        ProjectSituation.UNWANTED_TEMPERATURE_FLUCTUATIONS,
        ProjectSituation.ROOM_OVER_GARAGE_TOO_WARM_AND_TOO_COLD,
      ].some((condition) => project.situations && project.situations[condition])
    ) {
      conditionals.push(
        i18n.t(
          'overview.proposition.checklist.equipmentRemplacementAndSystemExtension.conditional.10'
        )
      );
    }

    if (
      [
        ProjectSituation.QUIET_INDOOR_OPERATION,
        ProjectSituation.INDOOR_SYSTEM_TOO_NOISY,
      ].some((condition) => project.situations && project.situations[condition])
    ) {
      conditionals.push(
        i18n.t(
          'overview.proposition.checklist.equipmentRemplacementAndSystemExtension.conditional.11'
        )
      );
    }

    if (
      [ProjectSituation.PLANNING_IMPROVEMENT_ENERGY_EFFICENCY].some(
        (condition) => project.situations && project.situations[condition]
      ) &&
      project.steps.sizingCapacityRequirements[
        'sizingCapacityRequirements-value'
      ] === 'D'
    ) {
      conditionals.push(
        i18n.t(
          'overview.proposition.checklist.equipmentRemplacementAndSystemExtension.conditional.12'
        )
      );
    }

    return conditionals;
  };
