/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Row, Col, PanelGroup, Panel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Step2Data, Step2_1Data, StepOption, Steps } from '../../../model/Step';
import { RadioGroup, Select } from '../../common/Form';
import { StepOptionRadioItem, StepPageAccordianItemHeader } from '../StepPage';
import { updateFieldArrayDependingOnNumber } from '../../../utils/fieldArrayUtils';

interface Props {
  step2Data?: Step2Data;
  stepData?: Step2_1Data;
  updateProject?: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
}

const DuctlessIndoorOutdoorUnitsConfigurationPage: React.FC<Props> = ({
  step2Data,
  stepData,
  updateProject,
  displayCompact,
  userIsProjectOwner,
}) => {
  const { t } = useTranslation();
  const stepId = Steps.STEP_2_1;
  const [indoorsActiveKey, setIndoorsActiveKey] = useState(0);

  const submitField = (key: keyof Step2_1Data) => {
    updateProject &&
      updateProject({ [`steps.${[stepId]}/${key}`]: getValues()[key] });
  };

  const { control, getValues, setValue, watch } = useForm<Step2_1Data>({
    defaultValues: {
      indoorUnits: stepData?.indoorUnits ? stepData?.indoorUnits : [],
    },
    mode: 'onChange',
  });

  const { fields: indoorUnits } = useFieldArray({
    control,
    name: 'indoorUnits',
  });

  const indoorUnitsWatch = watch('indoorUnits');

  //to set initial values and to add/remove units according the their numberOfUnits
  const updatedIndoorUnits = useMemo(
    () =>
      updateFieldArrayDependingOnNumber(
        step2Data?.numberOfIndoorUnits ?? 0,
        indoorUnitsWatch,
        {
          'ductlessIndoorOutdoorUnitsConfiguration-value':
            step2Data?.['ashpUnitConfiguration-value'] === 'D' ? 'D' : 'A',
        }
      ),
    [step2Data]
  );

  useEffect(() => {
    setValue(
      'indoorUnits',
      stepData?.indoorUnits?.length
        ? step2Data?.numberOfIndoorUnits === stepData?.indoorUnits.length
          ? stepData?.indoorUnits
          : updatedIndoorUnits
        : updatedIndoorUnits
    );
  }, [stepData, updatedIndoorUnits]);

  //update indoorUnits when the numberOfIndoorUnits changed
  useEffect(() => {
    submitField('indoorUnits');
  }, [updatedIndoorUnits]);

  const i18nForm = `step-${stepId}:form`;

  const options: {
    label: string;
    value: string | number;
    disabled?: boolean;
    labelData: StepOption;
  }[] = [
    {
      label: `${t(`${i18nForm}.a.option.title`)} - ${t(
        `${i18nForm}.a.option.text`
      )}`,
      value: 'A',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:2_1A.alt'),
          fileName: t('figures:2_1A.fileName'),
          longDescription: t('figures:2_1A.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.a.modal.list1.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.a.modal.list1.item1`)}</li>
                <li>{t(`${i18nForm}.a.modal.list1.item2`)}</li>
                <li>{t(`${i18nForm}.a.modal.list1.item3`)}</li>
                <li>{t(`${i18nForm}.a.modal.list1.item4`)}</li>
                <li>{t(`${i18nForm}.a.modal.list1.item5`)}</li>
              </ul>
            </>
          ),
          title: t(`${i18nForm}.a.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.a.option.text`),
          title: t(`${i18nForm}.a.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.b.option.title`)} - ${t(
        `${i18nForm}.b.option.text`
      )}`,
      value: 'B',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:2_1B.alt'),
          fileName: t('figures:2_1B.fileName'),
          longDescription: t('figures:2_1B.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.b.modal.list1.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.b.modal.list1.item1`)}</li>
                <li>{t(`${i18nForm}.b.modal.list1.item2`)}</li>
                <li>{t(`${i18nForm}.b.modal.list1.item3`)}</li>
                <li>{t(`${i18nForm}.b.modal.list1.item4`)}</li>
              </ul>
            </>
          ),
          title: t(`${i18nForm}.b.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.b.option.text`),
          title: t(`${i18nForm}.b.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.c.option.title`)} - ${t(
        `${i18nForm}.c.option.text`
      )}`,
      value: 'C',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:2_1C.alt'),
          fileName: t('figures:2_1C.fileName'),
          longDescription: t('figures:2_1C.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.c.modal.list1.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.c.modal.list1.item1`)}</li>
                <li>{t(`${i18nForm}.c.modal.list1.item2`)}</li>
                <li>{t(`${i18nForm}.c.modal.list1.item3`)}</li>
                <li>{t(`${i18nForm}.c.modal.list1.item4`)}</li>
                <li>{t(`${i18nForm}.c.modal.list1.item5`)}</li>
              </ul>
              <p>{t(`${i18nForm}.c.modal.list2.title`)}</p>
            </>
          ),
          title: t(`${i18nForm}.c.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.c.option.text`),
          title: t(`${i18nForm}.c.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.d.option.title`)} - ${t(
        `${i18nForm}.d.option.text`
      )}`,
      value: 'D',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:2_1D.alt'),
          fileName: t('figures:2_1D.fileName'),
          longDescription: t('figures:2_1D.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.d.modal.list1.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.d.modal.list1.item1`)}</li>
                <li>{t(`${i18nForm}.d.modal.list1.item2`)}</li>
              </ul>
              <p>{t(`${i18nForm}.d.modal.list2.title`)}</p>
              <p>{t(`${i18nForm}.d.modal.list3.title`)}</p>
            </>
          ),
          title: t(`${i18nForm}.d.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.d.option.text`),
          title: t(`${i18nForm}.d.option.title`),
        },
      },
    },
  ];

  return (
    <>
      {displayCompact ? (
        <Row>
          <Col xs={8} md={8}>
            {userIsProjectOwner ? (
              <>
                {indoorUnits.map((field, index) => {
                  return (
                    <div key={field.id}>
                      <Select
                        label={t(`${i18nForm}.indoorUnitTitle`, {
                          number: index + 1,
                        })}
                        id={`indoorUnits.${index}.ductlessIndoorOutdoorUnitsConfiguration-value`}
                        control={control}
                        options={options}
                        formGroupClasses={'step-option-radio'}
                        onChange={() => submitField('indoorUnits')}
                        inputProps={{ disabled: !userIsProjectOwner }}
                      />
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="selected-option-text">
                {indoorUnits?.map((iu, index) => {
                  const item = options.find(
                    (o) =>
                      o.value ===
                      iu['ductlessIndoorOutdoorUnitsConfiguration-value']
                  );
                  return (
                    <div key={iu.id}>
                      <label>
                        {t(`${i18nForm}.indoorUnitTitle`, {
                          number: index + 1,
                        })}
                      </label>
                      <p>{`${item?.labelData.option?.title} - ${item?.labelData.option?.text}`}</p>
                    </div>
                  );
                })}
              </div>
            )}
          </Col>
        </Row>
      ) : (
        <form noValidate>
          <p>{t(`${i18nForm}.description`)}</p>
          <PanelGroup
            id={`indoor-accordian-group`}
            accordion
            className="step-page-accordion"
            activeKey={indoorsActiveKey}
            onSelect={(key) => {
              setIndoorsActiveKey(key);
            }}
          >
            {indoorUnits.map((field, index) => {
              return (
                <Panel key={index} eventKey={index}>
                  <StepPageAccordianItemHeader
                    title={t(`${i18nForm}.indoorUnitTitle`, {
                      number: index + 1,
                    })}
                    activeKey={indoorsActiveKey}
                    keyNumber={index}
                  />
                  <Panel.Collapse>
                    <Panel.Body>
                      <div id={field.id}>
                        <RadioGroup
                          id={`indoorUnits.${index}.ductlessIndoorOutdoorUnitsConfiguration-value`}
                          control={control}
                          items={options}
                          radioClassName="step-option-radio"
                          onChange={() => submitField('indoorUnits')}
                          renderLabel={(item) => (
                            <StepOptionRadioItem item={item} />
                          )}
                        />
                      </div>
                    </Panel.Body>
                  </Panel.Collapse>
                </Panel>
              );
            })}
          </PanelGroup>
        </form>
      )}
    </>
  );
};

export default DuctlessIndoorOutdoorUnitsConfigurationPage;
