import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Step, Steps } from '../../../model/Step';
import { Project } from '../../../model/Project';
import { ServerFeedback } from '../../common/Form';
import { ProjectStepper, AdditionalInfoInput, MarkCompleteCheckbox } from './';

interface Props {
  additionalInformation?: { [key: string]: string };
  project: Project;
  step: Step;
  stepsList: Step[];
  completedSteps: string[];
  userIsProjectOwner?: boolean;
  serverError: string | undefined;
}

const ProjectStepFooter: React.FC<Props> = ({
  additionalInformation = {},
  project,
  step,
  stepsList,
  completedSteps,
  userIsProjectOwner,
  serverError,
}) => {
  const { t } = useTranslation();
  const checkboxComponent = userIsProjectOwner ? (
    <MarkCompleteCheckbox
      checkboxClassName={'mark-complete-checkbox-bottom'}
      completedSteps={completedSteps}
      project={project}
      step={step}
    />
  ) : (
    <div>
      {completedSteps.includes(step.id) && (
        <>
          <FontAwesomeIcon className="complete-check-icon" icon={faCheck} />
          <strong>{t('stepPage.complete')}</strong>
        </>
      )}
    </div>
  );

  return (
    <div className="project-step-footer">
      {!!serverError && (
        <ServerFeedback type="error">{t(serverError)}</ServerFeedback>
      )}
      {step.id !== Steps.STEP_1 && (
        <AdditionalInfoInput
          project={project}
          step={step}
          additionalInformation={additionalInformation}
          viewOnly={!userIsProjectOwner}
        />
      )}

      {checkboxComponent}

      <ProjectStepper stepNumber={step.number} stepsList={stepsList} />
    </div>
  );
};

export default ProjectStepFooter;
