import i18next from 'i18next';
import { Step, Steps } from '../../../model/Step';
import { StepsList } from '../../../const/Steps';
import {
  ProjectDetailsPage,
  AshpUnitConfigurationPage,
  SizingCapacityRequirementsPage,
  SupplyOutletApproachPage,
  ExistingConstraintsPage,
  ZoningSupplyDuctsPage,
  DuctSealingPage,
  ReturnAirDuctDesignPage,
  HeatingCoolingLoadEstimatesPage,
  DehumidificationStrategyPage,
  DuctShapePage,
  HeatPumpSelectionPage,
  UtilityCostsPage,
  ZoningEquipmentPage,
  GHGCostEnergyUseComparisonPage,
  DuctlessIndoorOutdoorUnitsConfigurationPage,
  AirflowTestingPage,
  CrossSectionalAreaMeasurementPage,
  DuctSizingPage,
  BackupHeatSourcePage,
  BackupControlStrategyPage,
} from '../Steps';
import { AdditionalInfoInput } from '../StepPage';
import { Project } from '../../../model/Project';
import { ChecklistItemStep } from '../../../model/Checklist';

export const getChecklistItems = (
  project: Project,
  stepsList: Step[],
  updateProject: (value: { [key: string]: string | number | boolean }) => void,
  userIsProjectOwner?: boolean
) => {
  const steps = project.steps || {};

  const getAdditionalInfoInput = (step) => (
    <AdditionalInfoInput
      project={project}
      step={step}
      additionalInformation={project.additionalInformation}
      viewOnly={!userIsProjectOwner}
    />
  );

  const filterSteps = (checklistItemSteps: ChecklistItemStep[]) => {
    return checklistItemSteps.filter((item) =>
      stepsList.find((sl) => sl.id === item.step.id)
    );
  };

  const getStepNumber = (step: Step) => {
    return stepsList.find((s) => s.id === step.id)?.number ?? 0;
  };

  const checklistItems = [
    {
      groupTitle: i18next.t('checklist.accordianTitle1'),
      steps: [
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: 1,
          })} - ${i18next.t('project.details.title')}`,
          step: StepsList[0],
          stepNumber: 1,
          component: (
            <ProjectDetailsPage
              key={0}
              project={project}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: 2,
          })} - ${i18next.t('step-ashpUnitConfiguration:title')}`,
          step: StepsList[1],
          stepNumber: 2,
          component: (
            <AshpUnitConfigurationPage
              key={1}
              step2_1Data={steps[Steps.STEP_2_1]}
              stepData={steps[Steps.STEP_2]}
              project={project}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoInput(StepsList[1])}
            >
              {steps.ashpUnitConfiguration.numberOfIndoorUnits ||
              steps.ashpUnitConfiguration.numberOfOutdoorUnits ? (
                <DuctlessIndoorOutdoorUnitsConfigurationPage
                  key={1}
                  step2Data={steps[Steps.STEP_2]}
                  stepData={steps[Steps.STEP_2_1]}
                  updateProject={updateProject}
                  displayCompact={true}
                  userIsProjectOwner={userIsProjectOwner}
                />
              ) : (
                <></>
              )}
            </AshpUnitConfigurationPage>
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: 3,
          })} - ${i18next.t('step-sizingCapacityRequirements:title')}`,
          step: StepsList[2],
          stepNumber: 3,
          component: (
            <SizingCapacityRequirementsPage
              key={2}
              project={project}
              stepData={steps[Steps.STEP_3]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoInput(StepsList[2])}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: getStepNumber(StepsList[3]),
          })} - ${i18next.t('step-supplyOutletApproach:title')}`,
          step: StepsList[3],
          stepNumber: getStepNumber(StepsList[3]),
          component: (
            <SupplyOutletApproachPage
              key={3}
              project={project}
              stepData={steps[Steps.STEP_4]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoInput(StepsList[3])}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: getStepNumber(StepsList[4]),
          })} - ${i18next.t('step-ductSizing:title')}`,
          step: StepsList[4],
          stepNumber: getStepNumber(StepsList[4]),
          component: (
            <DuctSizingPage
              key={4}
              project={project}
              stepData={steps[Steps.HPNewHome_Step5Data]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoInput(StepsList[4])}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: getStepNumber(StepsList[5]),
          })} - ${i18next.t('step-existingConstraints:title')}`,
          step: StepsList[5],
          stepNumber: getStepNumber(StepsList[5]),
          component: (
            <ExistingConstraintsPage
              key={4}
              step2Data={steps[Steps.STEP_2]}
              stepData={steps[Steps.STEP_5]}
              updateProject={updateProject}
              project={project}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoInput(StepsList[5])}
            >
              <>
                {steps[Steps.STEP_2]['ashpUnitConfiguration-value'] === 'A' &&
                steps[Steps.STEP_5][
                  'airflowCapacityMeasurementMethod-value'
                ] === 'A' ? (
                  <AirflowTestingPage
                    stepData={steps[Steps.STEP_5_1]}
                    updateProject={updateProject}
                    displayCompact={true}
                    userIsProjectOwner={userIsProjectOwner}
                  />
                ) : undefined}
                {steps[Steps.STEP_5][
                  'airflowCapacityMeasurementMethod-value'
                ] === 'B' ? (
                  <CrossSectionalAreaMeasurementPage
                    stepData={steps[Steps.STEP_5_1]}
                    updateProject={updateProject}
                    displayCompact={true}
                    userIsProjectOwner={userIsProjectOwner}
                  />
                ) : undefined}
              </>
            </ExistingConstraintsPage>
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: getStepNumber(StepsList[6]),
          })} - ${i18next.t('step-zoningSupplyDucts:title')}`,
          step: StepsList[6],
          stepNumber: getStepNumber(StepsList[6]),
          component: (
            <ZoningSupplyDuctsPage
              key={5}
              project={project}
              stepData={steps[Steps.STEP_6]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoInput(StepsList[6])}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: getStepNumber(StepsList[7]),
          })} - ${i18next.t('step-zoningEquipment:title')}`,
          step: StepsList[7],
          stepNumber: getStepNumber(StepsList[7]),
          component: (
            <ZoningEquipmentPage
              key={6}
              project={project}
              stepData={steps[Steps.STEP_7]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoInput(StepsList[7])}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: getStepNumber(StepsList[8]),
          })} - ${i18next.t('step-returnAirDuctDesign:title')}`,
          step: StepsList[8],
          stepNumber: getStepNumber(StepsList[8]),
          component: (
            <ReturnAirDuctDesignPage
              key={7}
              project={project}
              stepData={steps[Steps.STEP_8]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoInput(StepsList[8])}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: getStepNumber(StepsList[9]),
          })} - ${i18next.t('step-ductSealing:title')}`,
          step: StepsList[9],
          stepNumber: getStepNumber(StepsList[9]),
          component: (
            <DuctSealingPage
              key={8}
              project={project}
              stepData={steps[Steps.STEP_9]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoInput(StepsList[9])}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: getStepNumber(StepsList[10]),
          })} - ${i18next.t('step-heatingCoolingLoadEstimates:title')}`,
          step: StepsList[10],
          stepNumber: getStepNumber(StepsList[10]),
          component: (
            <HeatingCoolingLoadEstimatesPage
              key={9}
              project={project}
              stepData={steps[Steps.STEP_10]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoInput(StepsList[10])}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: getStepNumber(StepsList[11]),
          })} - ${i18next.t('step-ductShape:title')}`,
          step: StepsList[11],
          stepNumber: getStepNumber(StepsList[11]),
          component: (
            <DuctShapePage
              key={10}
              stepData={steps[Steps.STEP_11]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoInput(StepsList[11])}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: getStepNumber(StepsList[12]),
          })} - ${i18next.t('step-heatPumpSelection:title')}`,
          step: StepsList[12],
          stepNumber: getStepNumber(StepsList[12]),
          component: (
            <HeatPumpSelectionPage
              key={11}
              project={project}
              stepData={steps[Steps.STEP_12]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoInput(StepsList[12])}
            >
              {steps[Steps.STEP_12].heatPumpCompareList.length ? (
                <BackupHeatSourcePage
                  stepData={steps[Steps.STEP_12]}
                  updateProject={updateProject}
                  displayCompact={true}
                  userIsProjectOwner={userIsProjectOwner}
                />
              ) : undefined}
            </HeatPumpSelectionPage>
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: getStepNumber(StepsList[13]),
          })} - ${i18next.t('step-utilityCosts:title')}`,
          step: StepsList[13],
          stepNumber: getStepNumber(StepsList[13]),
          component: (
            <UtilityCostsPage
              key={12}
              step12Data={steps[Steps.STEP_12]}
              stepData={steps[Steps.STEP_13]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoInput(StepsList[13])}
            >
              {steps[Steps.STEP_12].heatPumpCompareList.length ? (
                <BackupControlStrategyPage
                  project={project}
                  step12Data={steps[Steps.STEP_12]}
                  stepData={steps[Steps.STEP_13_1]}
                  updateProject={updateProject}
                  displayCompact={true}
                  userIsProjectOwner={userIsProjectOwner}
                />
              ) : undefined}
            </UtilityCostsPage>
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: getStepNumber(StepsList[14]),
          })} - ${i18next.t('step-dehumidificationStrategy:title')}`,
          step: StepsList[14],
          stepNumber: getStepNumber(StepsList[14]),
          component: (
            <DehumidificationStrategyPage
              key={12}
              project={project}
              stepData={steps[Steps.STEP_14]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoInput(StepsList[14])}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: getStepNumber(StepsList[15]),
          })} - ${i18next.t('step-ghgCostEnergyUseComparison:title')}`,
          step: StepsList[15],
          stepNumber: getStepNumber(StepsList[15]),
          component: (
            <GHGCostEnergyUseComparisonPage
              key={12}
              project={project}
              stepData={steps[Steps.STEP_15]}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoInput(StepsList[15])}
            />
          ),
        },
      ],
    },
  ];

  const filteredCheckList = checklistItems
    .map((checkListItem) => ({
      ...checkListItem,
      steps: filterSteps(checkListItem.steps),
    }))
    .filter((checkListItem) => checkListItem.steps.length)
    .map((i) => ({
      ...i,
      steps: i.steps.sort((a, b) => a.stepNumber - b.stepNumber),
    }));
  return filteredCheckList;
};
