import { useEffect } from 'react';
import { Col, PageHeader, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import { AppRoute } from '../../const';
import { VideoContent } from '../Project/Videos';
import LoginForm from './LoginForm';

const LoginPage = () => {
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();
  const { replace } = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      replace(`/${t(AppRoute.Dashboard)}`);
    }
  }, [isAuthenticated, replace, t]);

  return (
    <>
      <PageHeader>{t('appTitle')}</PageHeader>
      <UnauthenticatedTemplate>
        <Row>
          <Col xs={12} sm={5} md={6}>
            <p>{t('login.appDescription')}</p>
          </Col>
          <Col xs={12} sm={6} md={5} smOffset={1}>
            <LoginForm />
            <VideoContent
              video={{
                href: t('login.video.href'),
                title: t('login.video.title'),
              }}
            />
          </Col>
        </Row>
      </UnauthenticatedTemplate>
    </>
  );
};

export default LoginPage;
