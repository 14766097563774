import { Step, Steps } from '../model/Step';
import { AppRoute } from '.';

export const StepsList: Step[] = [
  {
    id: Steps.STEP_1,
    number: 1,
    path: AppRoute.Step1,
  },
  {
    id: Steps.STEP_2,
    number: 2,
    path: AppRoute.Step2,
  },
  {
    id: Steps.STEP_3,
    number: 3,
    path: AppRoute.Step3,
  },
  {
    id: Steps.STEP_4,
    number: 4,
    path: AppRoute.Step4,
  },
  {
    id: Steps.HPNewHome_Step5Data,
    number: 5,
    path: AppRoute.HPNewHome_Step5,
  },
  {
    id: Steps.STEP_5,
    number: 5,
    path: AppRoute.Step5,
  },
  {
    id: Steps.STEP_6,
    number: 6,
    path: AppRoute.Step6,
  },
  {
    id: Steps.STEP_7,
    number: 7,
    path: AppRoute.Step7,
  },
  {
    id: Steps.STEP_8,
    number: 8,
    path: AppRoute.Step8,
  },
  {
    id: Steps.STEP_9,
    number: 9,
    path: AppRoute.Step9,
  },
  {
    id: Steps.STEP_10,
    number: 10,
    path: AppRoute.Step10,
  },
  {
    id: Steps.STEP_11,
    number: 11,
    path: AppRoute.Step11,
  },
  {
    id: Steps.STEP_12,
    number: 12,
    path: AppRoute.Step12,
  },
  {
    id: Steps.STEP_13,
    number: 13,
    path: AppRoute.Step13,
  },
  {
    id: Steps.STEP_14,
    number: 14,
    path: AppRoute.Step14,
  },
  {
    id: Steps.STEP_15,
    number: 15,
    path: AppRoute.Step15,
  },
];

export const SubStepsList: Step[] = [
  {
    id: Steps.STEP_2_1,
    number: 2.1,
    path: AppRoute.Step2_1,
  },
  {
    id: Steps.STEP_5_1_1,
    number: 5.1,
    path: AppRoute.Step5_1_1,
  },
  {
    id: Steps.STEP_5_1_2,
    number: 5.1,
    path: AppRoute.Step5_1_2,
  },
  {
    id: Steps.STEP_12_1,
    number: 12.1,
    path: AppRoute.Step12_1,
  },
  {
    id: Steps.STEP_13_1,
    number: 13.1,
    path: AppRoute.Step13_1,
  },
];
