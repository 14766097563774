import { Address } from './Address';
import { ProjectComments } from './Comments';
import { HouseType } from './House';
import { StepData } from './Step';
import { UserRoles } from './User';

export interface Checklist {
  homeBuilder: string;
  hvacDesigner: string;
  houseId: string;
  appliedTo: string;
}

export interface Collaborator {
  name: string;
  email: string;
  role: UserRoles | undefined;
}

export interface Project {
  id: string;
  additionalInformation: { [key: string]: string };
  checklist: Checklist;
  collaborators: Collaborator[];
  comments: ProjectComments;
  completedSteps: string[];
  createdAt: Date;
  houseType?: HouseType;
  isCompleted: boolean;
  isStarted: boolean;
  name: string;
  numberOfLevels?: number;
  owner: string;
  ownerRole: UserRoles;
  situations: ProjectSituationSelections;
  projectType: ProjectType;
  address: Address;
  installationType: InstallationType;
  steps: StepData;
  updatedAt: Date;
}

export enum ProjectSituation {
  //existing home
  ENERGY_EFFICIENCY_IMPORTANT = 'energyEfficiencyImportant', //existing home and new home
  LOWER_UPFRONT_COSTS = 'lowerUpfrontCosts', //existing home and new home
  ENVIRONMENTALLY_FRIENDLINESS = 'environmentallyFriendliness', //existing home and new home
  QUIET_OUTDOOR_OPERATION = 'quietOutdoorOperation', //existing home and new home
  QUIET_INDOOR_OPERATION = 'quietIndoorOperation', //existing home and new home
  INDOOR_SYSTEM_TOO_NOISY = 'indoorSystemTooNoisy',
  INADEQUATE_HEATING_UPPERLEVEL = 'inadequateHeatingUpperLevel',
  INADEQUATE_HEATING_BASEMENT = 'inadequateHeatingBasement',
  ASHP_WORK_WITH_FURNACE = 'ashpWorkWithFurnace',
  BASEMENT_LIKELY_FINISHED = 'basementLikelyFinished',
  PLANNING_IMPROVEMENT_ENERGY_EFFICENCY = 'planningImprovementEnergyEfficency',
  POOR_INDOOR_AIR_QUALITY = 'poorIndoorAirQuality',
  INDOOR_AIR_TOO_DRY_WINTER = 'indoorAirTooDryWinter',
  INDOOR_AIR_TOO_HUMID_SUMMER = 'indoorAirTooHumidSummer',
  UNWANTED_TEMPERATURE_FLUCTUATIONS = 'unwantedTemperatureFluctuations',
  ROOM_OVER_GARAGE_TOO_WARM_AND_TOO_COLD = 'roomOverGarageTooWarmAndTooCold',
  //new home
  HUMIDITY_COMPLAINT = 'humidityComplaint',
  HOT_OR_COLD_SPOTTING_COMPLAINT = 'hotOrColdSpottingComplaint',
  SECOND_FLOOR_TOO_WARM_BASEMENT_TOO_COLD_IN_SUMMER_COMPLAINT = 'secondFloorTooWarmBasementTooColdInSummerComplaint',
  INDOOR_NOISE_COMPLAINT = 'indoorNoiseComplaint',
  OUTDOOR_NOISE_COMPLAINT = 'outdoorNoiseComplaint',
  AIMING_PARTICULAR_ENERGY_STANDARD = 'aimingParticularEnergyStandard',
  CHALLENGES_WITH_HVAC_TECH = 'challengesWithHvacTech',
  CONSIDER_ZONING_HOME = 'considerZoningHome',
  UTILITY_SERVICE_OPTION_RESTRICTIONS = 'utilityServiceOptionRestrictions',
  CHALLENGES_WITH_AESTHETICS_HVAC_INDOOR = 'challengesWithAestheticsHvacIndoor',
  CHALLENGES_WITH_AESTHETICS_HVAC_OUTDOOR = 'challengesWithAestheticsHvacOutdoor',
  CHALLENGES_WITH_AMEMITY_SPACE_CONFLICT_HVAC_OUTDOOR = 'challengesWithAmenitySpaceConflictHvacOutdoor',
  BYLAWS_PLACEMENT_OUTDOOR = 'bylawsPlacementOutdoor',
  SUPPLY_CHAIN_ISSUES = 'supplyChainIssues',
  BASEMENT_FINISHED = 'basementFinished',
}

export enum ProjectType {
  NEW_SYSTEM_INSTALLATION = 'newSystemInstallation',
  EQUIPMENT_REPLACEMENT_ONLY = 'equipmentReplacementOnly',
  SYSTEM_EXTENSION = 'systemExtension',
  FULL_SYSTEM_REPLACEMENT = 'fullSystemReplacement',
}

export enum InstallationType {
  NEW_HOME = 'newHome',
  EXISTING_HOME = 'existingHome',
}

export enum ProjectStatus {
  CREATED = 'created',
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
}

export type ProjectPropositions = {
  always: { [key: string]: { [key: string]: string[] } };
  options: { [key: string]: string[] };
  secondaries: { [key: string]: string[] };
};

export enum Propositions {
  ENERGY_EFFICIENCY_IMPORTANT = 'energyEfficiencyImportant',
  ENERGY_EFFICIENCY_IMPORTANT_NEW_HOME_STEP_3 = 'energyEfficiencyImportantNewHomeStep3',
  ENERGY_EFFICIENCY_IMPORTANT_STEP_12 = 'energyEfficiencyImportantStep12',
  ENERGY_EFFICIENCY_IMPORTANT_NEW_HOME_STEP_12 = 'energyEfficiencyImportantNewHomeStep12',
  ENERGY_EFFICIENCY_IMPORTANT_STEP_13_1 = 'energyEfficiencyImportantStep13_1',
  ENERGY_EFFICIENCY_IMPORTANT_NEW_HOME_STEP_13_1 = 'energyEfficiencyImportantNewHomeStep13_1',
  DETERMINE_MAXIMUM_AIR_FLOW_CAPACITY = 'determineMaximumAirFlowCapacity',
  DETERMINE_MAXIMUM_AIR_FLOW_CAPACITY_SYSTEM_EXTENSION = 'determineMaximumAirFlowCapacitySystemExtension',
  AIR_FLOW_TESTING_MORE_ACCURATE = 'airFlowTestingMoreAccurate',
  AIR_FLOW_TESTING_MORE_ACCURATE_SYSTEM_EXTENSION = 'airFlowTestingMoreAccurateSystemExtension',
  LOWER_UPFRONT_COSTS = 'lowerUpfrontCosts',
  LOWER_UPFRONT_COSTS_STEP_2 = 'lowerUpfrontCostsStep2',
  LOWER_UPFRONT_COSTS_STEP_3 = 'lowerUpfrontCostsStep3',
  LOWER_UPFRONT_COSTS_STEP_4 = 'lowerUpfrontCostsStep4',
  LOWER_UPFRONT_COSTS_STEP_8 = 'lowerUpfrontCostsStep8',
  LOWER_UPFRONT_COSTS_SYSTEM_EXTENSION_STEP_8 = 'lowerUpfrontCostsSystemExtensionStep8',
  LOWER_UPFRONT_COSTS_STEP_13_1 = 'lowerUpfrontCostsStep13_1',
  ENVIRONMENTALLY_FRIENDLINESS = 'environmentallyFriendliness',
  ENVIRONMENTALLY_FRIENDLINESS_STEP_12 = 'environmentallyFriendlinessStep12',
  ENVIRONMENTALLY_FRIENDLINESS_NEW_HOME_STEP_12 = 'environmentallyFriendlinessNewHomeStep12',
  ENVIRONMENTALLY_FRIENDLINESS_STEP_13_1 = 'environmentallyFriendlinessStep13_1',
  ENVIRONMENTALLY_FRIENDLINESS_NEW_HOME_STEP_13_1 = 'environmentallyFriendlinessNewHomeStep13_1',
  QUIET_OUTDOOR_OPERATION = 'quietOutdoorOperation',
  PLANNING_IMPROVEMENTS_ENERGY_EFFICIENCY = 'planningImprovementsEnergyEfficiency',
  PLANNING_IMPROVEMENTS_ENERGY_EFFICIENCY_STEP_10 = 'planningImprovementsEnergyEfficiencyStep10',
  TSTAT_PLACEMENT = 'tStatPlacement',
  BASEMENT_LIKELY_FINISHED = 'basementLikelyFinished',
  UNWANTED_TEMPERATURE_FLUCTUATIONS = 'unwantedTemperatureFluctuations',
  INDOOR_AIR_TOO_HUMID_SUMMER = 'indoorAirTooHumidSummer',
  INDOOR_AIR_TOO_HUMID_SUMMER_STEP_14 = 'indoorAirTooHumidSummerStep14',
  QUIET_INDOOR_OPERATION_DUCTED_STEP_12 = 'quietIndoorOperationDuctedStep12',
  QUIET_INDOOR_OPERATION_DUCTLESS_STEP_12 = 'quietIndoorOperationDuctlessStep12',
  ASHP_WORK_WITH_FURNACE = 'ashpWorkWithFurnace',
  CHALLENGES_WITH_AESTHETICS_HVAC_INDOOR = 'challengesWithAestheticsHvacIndoor',
  CHALLENGES_WITH_AESTHETICS_HVAC_INDOOR_STEP_4 = 'challengesWithAestheticsHvacIndoorStep4',
  CHALLENGES_WITH_AESTHETICS_HVAC_INDOOR_NEW_HOME_STEP_5 = 'challengesWithAestheticsHvacIndoorNewHomeStep5',
  CHALLENGES_WITH_AESTHETICS_HVAC_INDOOR_STEP_8 = 'challengesWithAestheticsHvacIndoorStep8',
  CHALLENGES_WITH_HVAC_TECH = 'challengesWithHvacTech',
  CHALLENGES_WITH_HVAC_TECH_STEP_4 = 'challengesWithHvacTechStep4',
  CHALLENGES_WITH_HVAC_TECH_STEP_6 = 'challengesWithHvacTechStep6',
  CHALLENGES_WITH_HVAC_TECH_STEP_7 = 'challengesWithHvacTechStep7',
  CHALLENGES_WITH_HVAC_TECH_STEP_13_1 = 'challengesWithHvacTechStep13_1',
  CHALLENGES_WITH_HVAC_TECH_STEP_14 = 'challengesWithHvacTechStep14',
  AIMING_PARTICULAR_ENERGY_STANDARD = 'aimingParticularEnergyStandard',
  AIMING_PARTICULAR_ENERGY_STANDARD_STEP_12 = 'aimingParticularEnergyStandardStep12',
  HOT_OR_COLD_SPOTTING_COMPLAINT = 'hotOrColdSpottingComplaint',
  HOT_OR_COLD_SPOTTING_COMPLAINT_STEP_6 = 'hotOrColdSpottingComplaintStep6',
  HOT_OR_COLD_SPOTTING_COMPLAINT_STEP_8 = 'hotOrColdSpottingComplaintStep8',
  CONSIDER_ZONING_HOME = 'considerZoningHome',
  CONSIDER_ZONING_HOME_STEP_7 = 'considerZoningHomeStep7',
  REDUCE_THE_NEED_FOR_BULKHEADS = 'reduceTheNeedForBulkheads',
  DUCT_SEALING_HELPS_REDUCE_HOT_COLD_SPOTTING = 'ductSealingHelpsReduceHotColdSpotting',
  HUMIDITY_COMPLAINT = 'humidityComplaint',
  INADEQUATE_HEATING_UPPERLEVEL = 'inadequateHeatingUpperLevel',
  INADEQUATE_HEATING_UPPERLEVEL_STEP_8 = 'inadequateHeatingUpperLevelStep8',
  HYBRID_OR_CENTRAL_SUPPLY = 'hybridOrCentralSupply',
  ZONED_SYSTEMS_CAREFULLY_DESIGNED = 'zonedSystemsCarefullyDesigned',
  DHL = 'dhl',
  SAFETY_FACTOR = 'safetyFactor',
  TARGET_HEATING_CAPACITY = 'targetHeatingCapacity',
  AIRFLOW_CFM = 'airflowCFM',
  PANEL_CAPACITY = 'panelCapacity',
  ELECTRIC_SERVICE = 'electricService',
  OTHER_OR_ONE_ZONE_PER_FLOOR = 'otherOrOneZonePerFloor',
  MEDIUM_OR_HIGH_VELOCITY = 'mediumOrHighVelocity',
  HUMIDITY_COMPLAINT_STEP_12 = 'humidityComplaintStep12',
  INDOOR_NOISE_COMPLAINT = 'indoorNoiseComplaint',
  INDOOR_NOISE_COMPLAINT_MEDIUM_HIGH_VELOCITY = 'indoorNoiseComplaintMediumHighVelocity',
  INDOOR_NOISE_COMPLAINT_DUCTLESS = 'indoorNoiseComplaintDuctless',
  UTILITY_SERVICE_OPTION_RESTRICTIONS = 'utilityServiceOptionRestrictions',
  RIGHT_SIZING_HEAT_PUMP = 'rightSizingHeatPump',
  CHALLENGES_WITH_AESTHETICS_HVAC_OUTDOOR = 'challengesWithAestheticsHvacOutdoor',
  CHALLENGES_WITH_AMEMITY_SPACE_CONFLICT_HVAC_OUTDOOR = 'challengesWithAmenitySpaceConflictHvacOutdoor',
  CONTINUOUS_OPERATION_OF_THE_FAN = 'continuousOperationOfTheFan',
  CONTINUOUS_OPERATION_OF_THE_FAN_NEW_HOME = 'continuousOperationOfTheFanNewHome',
  ADHERE_TO_LOCAL_NOISE_BYLAWS = 'adhereToLocalNoiseBylaws',
  MOST_EFFICENT_HP = 'mostEfficentHP',
  HP_LOWEST_OPERATING_COST = 'hpLowestOperatingCost',
  HP_LOWEST_GREENHOUSE_GAZ = 'hpLowestGreenhouseGaz',
  HEAT_PUMPS_REQUIRE_GREATER_AIRFLOWS = 'heatPumpsRequireGreaterAirflows',
}

export interface ProjectSituationSelections {
  [ProjectSituation.ENERGY_EFFICIENCY_IMPORTANT]: boolean;
  [ProjectSituation.LOWER_UPFRONT_COSTS]: boolean;
  [ProjectSituation.ENVIRONMENTALLY_FRIENDLINESS]: boolean;
  [ProjectSituation.QUIET_OUTDOOR_OPERATION]: boolean;
  [ProjectSituation.QUIET_INDOOR_OPERATION]: boolean;
  [ProjectSituation.INDOOR_SYSTEM_TOO_NOISY]: boolean;
  [ProjectSituation.INADEQUATE_HEATING_UPPERLEVEL]: boolean;
  [ProjectSituation.INADEQUATE_HEATING_BASEMENT]: boolean;
  [ProjectSituation.ASHP_WORK_WITH_FURNACE]: boolean;
  [ProjectSituation.BASEMENT_LIKELY_FINISHED]: boolean;
  [ProjectSituation.PLANNING_IMPROVEMENT_ENERGY_EFFICENCY]: boolean;
  [ProjectSituation.POOR_INDOOR_AIR_QUALITY]: boolean;
  [ProjectSituation.INDOOR_AIR_TOO_DRY_WINTER]: boolean;
  [ProjectSituation.INDOOR_AIR_TOO_HUMID_SUMMER]: boolean;
  [ProjectSituation.UNWANTED_TEMPERATURE_FLUCTUATIONS]: boolean;
  [ProjectSituation.ROOM_OVER_GARAGE_TOO_WARM_AND_TOO_COLD]: boolean;
  [ProjectSituation.HUMIDITY_COMPLAINT]: boolean;
  [ProjectSituation.HOT_OR_COLD_SPOTTING_COMPLAINT]: boolean;
  [ProjectSituation.SECOND_FLOOR_TOO_WARM_BASEMENT_TOO_COLD_IN_SUMMER_COMPLAINT]: boolean;
  [ProjectSituation.INDOOR_NOISE_COMPLAINT]: boolean;
  [ProjectSituation.OUTDOOR_NOISE_COMPLAINT]: boolean;
  [ProjectSituation.AIMING_PARTICULAR_ENERGY_STANDARD]: boolean;
  [ProjectSituation.CHALLENGES_WITH_HVAC_TECH]: boolean;
  [ProjectSituation.CONSIDER_ZONING_HOME]: boolean;
  [ProjectSituation.UTILITY_SERVICE_OPTION_RESTRICTIONS]: boolean;
  [ProjectSituation.CHALLENGES_WITH_AESTHETICS_HVAC_INDOOR]: boolean;
  [ProjectSituation.CHALLENGES_WITH_AESTHETICS_HVAC_OUTDOOR]: boolean;
  [ProjectSituation.CHALLENGES_WITH_AMEMITY_SPACE_CONFLICT_HVAC_OUTDOOR]: boolean;
  [ProjectSituation.BYLAWS_PLACEMENT_OUTDOOR]: boolean;
  [ProjectSituation.SUPPLY_CHAIN_ISSUES]: boolean;
  [ProjectSituation.BASEMENT_FINISHED]: boolean;
}
