import { FormattedInput } from '../model/Formatter';

export const numberFormatter =
  (isFr: boolean, maxDecimals: number) =>
  (value: string | number): FormattedInput => {
    if (value === '' || value == null) {
      return {
        parsed: '',
        formatted: '',
      };
    }

    // Since 16 significant digits with JavaScript numbers, limit input size, but keep at least one
    const maxDigits = Math.max(16 - maxDecimals, 1);
    // Replace everything that is not digits or decimal separators (allow both dot and comma in French)
    const cleaned = isFr
      ? value
          .toString()
          .replace(/[^0-9,.]/g, '')
          .replace(',', '.')
      : value.toString().replace(/[^0-9.]/g, '');
    // Get only the first decimal point and its following numbers
    const sanitized = cleaned.match(/\d*\.?\d*/)?.[0] ?? '';
    // Split the string to get the numbers before the decimal (characteristic) and
    // after the decimal (mantissa)
    const [characteristic, mantissa] = sanitized.split('.');
    // Limit the characteristic to the maximum number of digits
    const limitedCharacteristic = characteristic.substring(0, maxDigits);
    // Parse the number before the decimal
    const characteristicParsed = Number(limitedCharacteristic);
    // Format it using the appropriate locale
    const characteristicFormatted = characteristicParsed.toLocaleString(
      isFr ? 'fr-CA' : 'en-CA'
    );
    // Limit the number of decimal places
    const limitedMantissa = mantissa?.substring(0, maxDecimals);
    // Assemble a raw number that can be parsed
    const rawNumber = `${characteristic ?? 0}.${limitedMantissa ?? ''}`;
    const parsedNumber = Number(rawNumber);
    // Assemble the formatted number
    const formatted = `${characteristicFormatted}${
      mantissa || (mantissa === '' && maxDecimals) ? (isFr ? ',' : '.') : ''
    }${limitedMantissa ?? ''}`;
    // Return a string if the inputed number had a decimal point or a zero after the decimal
    // at the end, therefore allowing the user to continue typing their number
    const parsed =
      limitedMantissa === '' || limitedMantissa?.slice(-1) === '0'
        ? rawNumber
        : parsedNumber;
    // Return the final result
    return {
      parsed: parsed,
      formatted: formatted,
    };
  };
