import { ProgressBar, Well } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { SM_MAX_SIZE } from '../../const/Responsive';
import { Project } from '../../model/Project';
import { getProjectStatus } from '../../utils/projectUtils';
import { Step } from '../../model/Step';

interface Props {
  project: Project;
  stepsList: Step[];
}

const ProjectStatus: React.FC<Props> = ({ project, stepsList }) => {
  const { t } = useTranslation();
  return (
    <Well className="project-status-well well-with-header">
      <div className="well-header hidden-xs hidden-sm" id="title">
        {t('project.status.title')}
      </div>
      <div className="well-content">
        <MediaQuery maxWidth={SM_MAX_SIZE}>
          <span className="project-status-well-mobile-title">
            {t('project.status.title')}: {''}
          </span>
        </MediaQuery>
        {getProjectStatus(project, t)}
        <ProgressBar
          now={(project.completedSteps.length / stepsList.length) * 100}
        />
      </div>
    </Well>
  );
};

export default ProjectStatus;
