/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Step5_1Data, Steps } from '../../../../model/Step';
import { Input } from '../../../common/Form';
import { AirflowTesting } from '../../../../model/DuckConstraints/AirflowTesting';
import { CrossSectionalAreaMeasurement } from '../../../../model/DuckConstraints/CrossSectionalAreaMeasurement';
import { NUMBER_MAX_DECIMALS } from '../../../../const/Formatter';
import { useNumberFormatter } from '../../../../hooks/useNumberFormatter';

interface Props {
  stepData?: Step5_1Data;
  updateProject?: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  additionalInfo?: JSX.Element;
}

const AirflowTestingPage: React.FC<Props> = ({
  stepData,
  updateProject,
  displayCompact,
  userIsProjectOwner,
  additionalInfo,
}) => {
  const { t } = useTranslation();
  const numberFormatter = useNumberFormatter(NUMBER_MAX_DECIMALS);

  const stepId = Steps.STEP_5_1_1;

  const submitField = () => {
    updateProject &&
      updateProject({
        [`steps.${[Steps.STEP_5_1]}`]: { ...stepData, ...getValues() },
      });
  };

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<Step5_1Data>({
    defaultValues: {
      airflowTesting: new AirflowTesting(stepData?.airflowTesting),
      crossSectionalAreaMeasurement: new CrossSectionalAreaMeasurement(
        stepData?.crossSectionalAreaMeasurement
      ),
    },
    mode: 'onChange',
  });

  const i18nForm = `step-${[stepId]}:form`;

  const formValues = getValues();

  useEffect(() => {
    if (!Object.values(stepData?.airflowTesting ?? {}).length) {
      submitField();
    }
  }, []);

  useEffect(() => {
    if (stepData && !formValues) {
      setValue('airflowTesting', new AirflowTesting(stepData?.airflowTesting));
    }
  }, [stepData]);

  const validationRules = {
    min: {
      value: 0,
      message: t('errors.project.minNumber', {
        min: 0,
      }).toString(),
    },
  };

  const totalPressureValidationRules = {
    min: {
      value: 0,
      message: t('errors.project.minNumber', {
        min: 0,
      }).toString(),
    },
    max: {
      value: 1,
      message: t('errors.project.maxNumber', {
        max: 1,
      }).toString(),
    },
  };

  return (
    <>
      {displayCompact ? (
        <Row>
          <Col xs={8} md={8}>
            {userIsProjectOwner ? (
              <>
                <Input
                  id="airflowTesting.airflow"
                  control={control}
                  label={t(`${i18nForm}.airflow`)}
                  inputProps={{ disabled: !userIsProjectOwner }}
                  onBlur={submitField}
                  formatter={numberFormatter}
                  validationRules={validationRules}
                  error={errors?.airflowTesting?.airflow}
                />
                <Input
                  id="airflowTesting.totalExternalStaticPressure"
                  control={control}
                  label={t(`${i18nForm}.totalExternalStaticPressure`)}
                  inputProps={{ disabled: !userIsProjectOwner }}
                  onBlur={submitField}
                  formatter={numberFormatter}
                  validationRules={totalPressureValidationRules}
                  error={errors?.airflowTesting?.totalExternalStaticPressure}
                />
                <p>
                  <Trans>
                    {t(`${i18nForm}.predictedMaximumAirflow`, {
                      value: stepData?.airflowTesting?.predictedMaximumAirflow,
                    })}
                  </Trans>
                </p>
              </>
            ) : (
              <div className="selected-option-text">
                <label>{t(`${i18nForm}.airflow`)}</label>
                <p>
                  {
                    numberFormatter(formValues.airflowTesting?.airflow ?? 0)
                      .formatted
                  }
                </p>
                <label>{t(`${i18nForm}.totalExternalStaticPressure`)}</label>
                <p>
                  {
                    numberFormatter(
                      formValues.airflowTesting?.totalExternalStaticPressure ??
                        0
                    ).formatted
                  }
                </p>
                <p>
                  <Trans>
                    {t(`${i18nForm}.predictedMaximumAirflow`, {
                      value: stepData?.airflowTesting?.predictedMaximumAirflow,
                    })}
                  </Trans>
                </p>
              </div>
            )}
            {additionalInfo}
          </Col>
        </Row>
      ) : (
        <form noValidate>
          <Row>
            <Col xs={12}>
              <p>{t('step-airflowTesting:description')}</p>
            </Col>
            <Col xs={6} sm={5}>
              <Input
                id="airflowTesting.airflow"
                control={control}
                label={t(`${i18nForm}.airflow`)}
                inputProps={{ disabled: !userIsProjectOwner }}
                onBlur={submitField}
                formatter={numberFormatter}
                validationRules={validationRules}
                error={errors?.airflowTesting?.airflow}
              />
            </Col>
            <Col xs={6} sm={5}>
              <Input
                id="airflowTesting.totalExternalStaticPressure"
                control={control}
                label={t(`${i18nForm}.totalExternalStaticPressure`)}
                inputProps={{ disabled: !userIsProjectOwner }}
                onBlur={submitField}
                formatter={numberFormatter}
                validationRules={totalPressureValidationRules}
                error={errors?.airflowTesting?.totalExternalStaticPressure}
              />
            </Col>
          </Row>

          <Row className="step-type-description-container">
            <Col xs={12}>
              <p>
                <Trans>
                  {t(`${i18nForm}.predictedMaximumAirflow`, {
                    value: stepData?.airflowTesting?.predictedMaximumAirflow,
                  })}
                </Trans>
              </p>
            </Col>
            <Col xs={12}>
              <p id="airflow-testing-description">
                {t('step-airflowTesting:imageDescription')}
              </p>
            </Col>
            <Col xs={12} className="step-full-width-image">
              <img
                src={`/assets/images/${t('figures:5A.fileName')}`}
                alt={t('step-airflowTesting:title')}
                aria-describedby={'airflow-testing-description'}
              />
            </Col>
          </Row>
        </form>
      )}
    </>
  );
};

export default AirflowTestingPage;
