/* eslint-disable react-hooks/exhaustive-deps */
import { FieldArrayWithId } from 'react-hook-form';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DataTable, {
  TableColumn,
  TableStyles,
} from 'react-data-table-component';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Step12Data, Steps } from '../../../../model/Step';
import { SM_MAX_SIZE } from '../../../../const/Responsive';
import { DataTableCell } from '../../../Dashboard';

const customStyles: TableStyles = {
  headRow: {
    style: {
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: '#000',
    },
  },
  headCells: {
    style: {
      color: '#000',
      fontFamily: 'Noto Sans, sans-serif',
      fontSize: 20,
      fontWeight: 600,
      justifyContent: 'space-between',

      '& div': {
        whiteSpace: 'pre-wrap',
        textAlign: 'center',
        fontWeight: 600,
        minHeight: 24,
      },
    },
    activeSortStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: 1,
      span: {
        fontSize: 12,
      },
    },
    inactiveSortStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: 1,
      span: {
        fontSize: 12,
      },
    },
  },
  rows: {
    style: {
      backgroundColor: '#FBFBFB',
      borderBottom: 'none',
    },
    stripedStyle: {
      backgroundColor: 'transparent',
    },
  },
  cells: {
    style: {
      borderBottom: '#26374A solid 1px',
      fontSize: 20,

      '& a': {
        color: '#000',
      },
    },
  },
};

const mobileStyle = {
  table: {
    style: {
      marginTop: 20,
    },
  },
  headRow: {
    style: {
      display: 'none',
    },
  },
  rows: {
    style: {
      backgroundColor: '#FBFBFB',
      display: 'block',
      '&:first-of-type': {
        borderTop: '#26374A solid 1px',
      },
      '&:not(:last-of-type)': {
        borderBottom: '#26374A solid 1px',
      },

      borderBottom: '#26374A solid 1px',
    },
    stripedStyle: {
      backgroundColor: 'transparent',
    },
  },
  cells: {
    style: {
      border: 'none',
      display: 'inline-block',
      fontSize: 18,
      lineHeight: '26px',
      marginTop: 15,

      a: {
        color: '#333333',
      },

      '.table-cell-column-label': {
        fontSize: 16,
        fontWeight: 600,
      },
    },
  },
};

interface Props {
  compareList: FieldArrayWithId<Step12Data, 'heatPumpCompareList', 'id'>[];
  remove: (index?: number | number[] | undefined) => void;
  userIsProjectOwner?: boolean;
  isLoading: boolean;
}

const HeatPumpSelectionCompareList: React.FC<Props> = ({
  compareList,
  remove,
  userIsProjectOwner,
  isLoading,
}) => {
  const { t } = useTranslation();
  const stepId = Steps.STEP_12;
  const i18nForm = `step-${stepId}:form`;
  const isSM = useMediaQuery({ query: `(max-width: ${SM_MAX_SIZE}px)` });

  const columns: TableColumn<
    FieldArrayWithId<Step12Data, 'heatPumpCompareList', 'id'>
  >[] = [
    {
      id: 'index',
      name: '#',
      selector: (row, index) => index + 1,
      width: '7%',
      cell: (row, index) => {
        return <DataTableCell columnLabel="#">{index + 1}</DataTableCell>;
      },
    },
    {
      id: 'brand',
      name: t(`${i18nForm}.compareList.brand`),
      selector: (row) => row.name,
      width: isSM ? '60%' : '23%',
      cell: (row) => {
        return (
          <DataTableCell columnLabel={t(`${i18nForm}.compareList.brand`)}>
            {`${row.name ?? ''}`}
            {row.outdoorUnitNumber ? ` (${row.outdoorUnitNumber})` : ''}
          </DataTableCell>
        );
      },
    },
    {
      id: 'ahri',
      name: t(`${i18nForm}.compareList.ahri`),
      selector: (row) => row.ahri_Number,
      width: isSM ? '35%' : '18%',
      cell: (row) => (
        <DataTableCell columnLabel={t(`${i18nForm}.compareList.ahri`)}>
          {row.ahri_Number}
        </DataTableCell>
      ),
    },
    {
      id: 'heatingRatedBtuh',
      name: t(`${i18nForm}.compareList.heatingRatedBtuh`),
      selector: (row) => row.maxHeatingCapacityAt5F,
      width: isSM ? '100%' : undefined,
      cell: (row) => (
        <DataTableCell
          columnLabel={t(`${i18nForm}.compareList.heatingRatedBtuh`)}
        >
          <div className="compare-list-rated-btuh-container">
            <span>
              {t(`${i18nForm}.compareList.maxHeatingCapacityAt47F`, {
                number: row.maxHeatingCapacityAt47F,
              })}
            </span>
            <span>
              {t(`${i18nForm}.compareList.maxHeatingCapacityAt17F`, {
                number: row.maxHeatingCapacityAt17F,
              })}
            </span>
            <span>
              {t(`${i18nForm}.compareList.maxHeatingCapacityAt5F`, {
                number: row.maxHeatingCapacityAt5F,
              })}
            </span>
          </div>
        </DataTableCell>
      ),
    },
    {
      id: 'action',
      name: '',
      right: false,
      button: !isSM,
      width: isSM ? '100%' : '18%',
      omit: !userIsProjectOwner,
      cell: (row, index) => (
        <>
          <Button
            bsSize="small"
            className="btn btn-primary btn-sm copy-btn pull-right btn-icon-left"
            onClick={() => remove(index)}
            disabled={isLoading}
          >
            <FontAwesomeIcon icon={faTrash} />
            {t('common.remove')}
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <form noValidate>
        {!userIsProjectOwner ? (
          <div className="compare-list">
            {!compareList.length ? (
              <>
                <p>{t(`${i18nForm}.compareList.title`)}</p>
                <hr />
                <p> {t(`${i18nForm}.compareList.noResult`)}</p>
              </>
            ) : (
              <DataTable
                keyField="ahri_Number"
                customStyles={
                  isSM ? { ...customStyles, ...mobileStyle } : customStyles
                }
                columns={columns}
                data={compareList ?? []}
                persistTableHead
                striped
                noDataComponent={t(`${i18nForm}.compareList.noResult`)}
              />
            )}
          </div>
        ) : (
          <div className="compare-list">
            {!compareList.length ? (
              <>
                <p>{t(`${i18nForm}.compareList.title`)}</p>
                <hr />
                <p> {t(`${i18nForm}.compareList.noResult`)}</p>
              </>
            ) : (
              <DataTable
                keyField="ahri_Number"
                customStyles={
                  isSM ? { ...customStyles, ...mobileStyle } : customStyles
                }
                columns={columns}
                data={compareList ?? []}
                persistTableHead
                striped
                noDataComponent={t(`${i18nForm}.compareList.noResult`)}
              />
            )}
          </div>
        )}
      </form>
    </>
  );
};

export default HeatPumpSelectionCompareList;
