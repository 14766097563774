export class Address {
  country = '';
  province = '';
  city = '';

  constructor(entity?: Address) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.country = '';
      this.province = '';
      this.city = '';
    }
  }
}
