import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { AppRoute } from '../../../const';
import { Step } from '../../../model/Step';
import { LocalizedNavLink } from '../../i18n';

interface Props {
  stepNumber: number;
  stepsList: Step[];
}

const ProjectStepper: React.FC<Props> = ({ stepNumber, stepsList }) => {
  const { t } = useTranslation();

  const { projectId }: { projectId: string } = useParams();

  const baseProjectUrl = `${t(AppRoute.Project)}/${projectId}`;

  const prevLink = useMemo(() => {
    if (stepNumber !== 1) {
      const currentStepIndex = stepsList.findIndex(
        (stepItem) => stepItem.number === stepNumber
      );
      const prevStep = stepsList[currentStepIndex - 1];
      if (prevStep) {
        return `${baseProjectUrl}/${t(AppRoute.Step)}/${t(prevStep.path)}`;
      }
    }
    return `${baseProjectUrl}/${t(AppRoute.ProjectOverview)}`;
  }, [stepNumber, baseProjectUrl, t, stepsList]);

  const nextLink = useMemo(() => {
    if (stepNumber !== stepsList.length) {
      const currentStepIndex = stepsList.findIndex(
        (stepItem) => stepItem.number === stepNumber
      );
      const nextStep = stepsList[currentStepIndex + 1];
      if (nextStep) {
        return `${baseProjectUrl}/${t(AppRoute.Step)}/${t(nextStep.path)}`;
      }
    }
    return `${baseProjectUrl}/${t(AppRoute.ProjectChecklist)}`;
  }, [stepNumber, stepsList, baseProjectUrl, t]);

  return (
    <div className="project-stepper">
      <LocalizedNavLink
        className="btn btn-default btn-xs btn-icon-left"
        to={prevLink}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
        {t('common.previous')}
      </LocalizedNavLink>

      <LocalizedNavLink
        className="btn btn-default btn-xs btn-icon-right"
        to={nextLink}
      >
        {t('common.next')}
        <FontAwesomeIcon icon={faAngleRight} />
      </LocalizedNavLink>
    </div>
  );
};

export default ProjectStepper;
