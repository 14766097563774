import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageCode, TranslatedStrings } from '../model/Language';

export function useInternationalization() {
  const { i18n } = useTranslation();

  const currentLanguage = useMemo(
    () =>
      i18n.languages[0].startsWith('fr') ? LanguageCode.fr : LanguageCode.en,
    [i18n.languages]
  );

  const getTranslation = useCallback(
    <T extends string>(object: TranslatedStrings<T>, key: T) =>
      currentLanguage === LanguageCode.fr && object[`${key}_Fr`]
        ? object[`${key}_Fr`]
        : object[`${key}_En`],
    [currentLanguage]
  );

  return {
    getTranslation,
  };
}
