/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Row, Col, PanelGroup, Panel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  Step12Data,
  Step13_1Data,
  StepOption,
  Steps,
} from '../../../model/Step';
import { RadioGroup, Select } from '../../common/Form';
import { StepOptionRadioItem, StepPageAccordianItemHeader } from '../StepPage';
import { updateFieldArrayDependingOnNumber } from '../../../utils/fieldArrayUtils';
import { Project, ProjectPropositions } from '../../../model/Project';
import { getWorkflow } from '../../../utils/workflowUtils';
import { BackupType } from '../../../model/HeatPumpWithBackup/BackupType';
import { getProjectPropositions } from '../../../const/Projects';
import ProjectStepProposition from '../ProjectStepProposition';

interface Props {
  project: Project;
  step12Data?: Step12Data;
  stepData?: Step13_1Data;
  updateProject?: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
}

const BackupControlStrategyPage: React.FC<Props> = ({
  project,
  step12Data,
  stepData,
  updateProject,
  displayCompact,
  userIsProjectOwner,
}) => {
  const { t } = useTranslation();
  const stepId = Steps.STEP_13_1;
  const i18nForm = `step-${stepId}:form`;
  const [panelActiveKey, setPanelActiveKey] = useState(0);

  const stepList = useMemo(() => getWorkflow(project), [project]);
  const stepNumber = useMemo(
    () => stepList.find((step) => step.id === stepId)?.number,
    [stepList]
  );

  const propositions: ProjectPropositions = useMemo(() => {
    return getProjectPropositions(project);
  }, [project]);

  const submitField = (key: keyof Step13_1Data) => {
    updateProject &&
      updateProject({ [`steps.${[stepId]}/${key}`]: getValues()[key] });
  };

  const { control, getValues, setValue, watch } = useForm<Step13_1Data>({
    defaultValues: {
      backupControlStrategies: stepData?.backupControlStrategies ?? [],
    },
    mode: 'onChange',
  });

  const { fields } = useFieldArray({
    control,
    name: 'backupControlStrategies',
  });

  const backupControlStrategiesWatch = watch('backupControlStrategies');

  //to set initial values and to add/remove heatPumps according the number of items in the compareList
  const { updatedStrategies } = useMemo(() => {
    const updatedStrategies = updateFieldArrayDependingOnNumber(
      step12Data?.heatPumpCompareList.length ?? 0,
      backupControlStrategiesWatch,
      {
        'backupControlStrategy-value': 'A',
      }
    );
    return { updatedStrategies };
  }, [step12Data]);

  useEffect(() => {
    setValue(
      'backupControlStrategies',
      stepData?.backupControlStrategies?.length
        ? step12Data?.heatPumpCompareList.length ===
          stepData?.backupControlStrategies.length
          ? stepData?.backupControlStrategies
          : updatedStrategies
        : updatedStrategies
    );
  }, [stepData, updatedStrategies]);

  //update backupControlStrategies when the compareList changed
  useEffect(() => {
    submitField('backupControlStrategies');
  }, [updatedStrategies]);

  const optionsFurnace: {
    label: string;
    value: string | number;
    disabled?: boolean;
    labelData: StepOption;
  }[] = [
    {
      label: `${t(`${i18nForm}.a.option.title`, {
        stepNumber: stepNumber,
      })} - ${t(`${i18nForm}.a.option.text`)}`,
      value: 'A',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:13_1A.alt'),
          fileName: t('figures:13_1A.fileName'),
          longDescription: t('figures:13_1A.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.a.modal.text1`)}</p>
              <p>{t(`${i18nForm}.a.modal.text2`)}</p>
              <p>{t(`${i18nForm}.a.modal.text3`)}</p>
            </>
          ),
          title: t(`${i18nForm}.a.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.a.option.text`),
          title: t(`${i18nForm}.a.option.title`, {
            stepNumber: stepNumber,
          }),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.b.option.title`, {
        stepNumber: stepNumber,
      })} - ${t(`${i18nForm}.b.option.text`)}`,
      value: 'B',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:13_1B.alt'),
          fileName: t('figures:13_1B.fileName'),
          longDescription: t('figures:13_1B.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.b.modal.text1`)}</p>
              <p>{t(`${i18nForm}.b.modal.text2`)}</p>
              <p>{t(`${i18nForm}.b.modal.text3`)}</p>
            </>
          ),
          title: t(`${i18nForm}.b.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.b.option.text`),
          title: t(`${i18nForm}.b.option.title`, {
            stepNumber: stepNumber,
          }),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.c.option.title`, {
        stepNumber: stepNumber,
        optionLetter: 'C',
      })} - ${t(`${i18nForm}.c.option.text`)}`,
      value: 'C',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:13_1B.alt'),
          fileName: t('figures:13_1B.fileName'),
          longDescription: t('figures:13_1B.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.c.modal.text1`)}</p>
              <p>{t(`${i18nForm}.c.modal.text2`)}</p>
              <p>{t(`${i18nForm}.c.modal.text3`)}</p>
            </>
          ),
          title: t(`${i18nForm}.c.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.c.option.text`),
          title: t(`${i18nForm}.c.option.title`, {
            stepNumber: stepNumber,
          }),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.d.option.title`, {
        stepNumber: stepNumber,
        optionLetter: 'D',
      })} - ${t(`${i18nForm}.d.option.text`)}`,
      value: 'D',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:13_1D.alt'),
          fileName: t('figures:13_1D.fileName'),
          longDescription: t('figures:13_1D.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.d.modal.text1`)}</p>
              <p>{t(`${i18nForm}.d.modal.text2`)}</p>
              <p>{t(`${i18nForm}.d.modal.text3`)}</p>
            </>
          ),
          title: t(`${i18nForm}.d.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.d.option.text`),
          title: t(`${i18nForm}.d.option.title`, {
            stepNumber: stepNumber,
            optionLetter: 'D',
          }),
        },
      },
    },
  ];

  const optionsElectric: {
    label: string;
    value: string | number;
    disabled?: boolean;
    labelData: StepOption;
  }[] = [
    {
      label: `${t(`${i18nForm}.d.option.title`, {
        stepNumber: stepNumber,
        optionLetter: 'A',
      })} - ${t(`${i18nForm}.d.option.text`)}`,
      value: 'D',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:13_1D.alt'),
          fileName: t('figures:13_1D.fileName'),
          longDescription: t('figures:13_1D.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.d.modal.text1`)}</p>
              <p>{t(`${i18nForm}.d.modal.text2`)}</p>
              <p>{t(`${i18nForm}.d.modal.text3`)}</p>
            </>
          ),
          title: t(`${i18nForm}.d.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.d.option.text`),
          title: t(`${i18nForm}.d.option.title`, {
            stepNumber: stepNumber,
            optionLetter: 'A',
          }),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.e.option.title`, {
        stepNumber: stepNumber,
        optionLetter: 'B',
      })} - ${t(`${i18nForm}.e.option.text`)}`,
      value: 'E',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:13_1E.alt'),
          fileName: t('figures:13_1E.fileName'),
          longDescription: t('figures:13_1E.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.e.modal.text1`)}</p>
              <p>{t(`${i18nForm}.e.modal.text2`)}</p>
              <p>{t(`${i18nForm}.e.modal.text3`)}</p>
              <p>{t(`${i18nForm}.e.modal.text4`)}</p>
            </>
          ),
          title: t(`${i18nForm}.e.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.e.option.text`),
          title: t(`${i18nForm}.e.option.title`, {
            stepNumber: stepNumber,
            optionLetter: 'B',
          }),
        },
      },
    },
  ];

  return (
    <>
      {displayCompact ? (
        <Row>
          <Col xs={12}>
            {userIsProjectOwner ? (
              <>
                {fields.map((field, index) => {
                  return (
                    <div key={field.id}>
                      <Select
                        label={
                          `#${index + 1} ${field.name ?? ''} ` +
                          (field.outdoorUnitNumber
                            ? `(${field.outdoorUnitNumber ?? ''})`
                            : '')
                        }
                        id={`backupControlStrategies.${index}.backupControlStrategy-value`}
                        control={control}
                        options={
                          field.backupType === BackupType.Electric
                            ? optionsElectric
                            : optionsFurnace
                        }
                        formGroupClasses={'step-option-radio'}
                        onChange={() => submitField('backupControlStrategies')}
                        inputProps={{ disabled: !userIsProjectOwner }}
                      />
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="selected-option-text">
                {fields?.map((field, index) => {
                  const item = (
                    field.backupType === BackupType.Electric
                      ? optionsElectric
                      : optionsFurnace
                  ).find(
                    (o) => o.value === field['backupControlStrategy-value']
                  );
                  return (
                    <div key={field.id}>
                      <label>
                        {`#${index + 1} ${field.name ?? ''} ` +
                          (field.outdoorUnitNumber
                            ? `(${field.outdoorUnitNumber ?? ''})`
                            : '')}
                      </label>
                      <p>{`${item?.labelData.option?.title} - ${item?.labelData.option?.text}`}</p>
                    </div>
                  );
                })}
              </div>
            )}
          </Col>
        </Row>
      ) : (
        <form noValidate>
          <PanelGroup
            id={`outdoor-accordian-group`}
            accordion
            className="step-page-accordion"
            activeKey={panelActiveKey}
            onSelect={(key) => {
              setPanelActiveKey(key);
            }}
          >
            {fields.map((field, index) => {
              return (
                <Panel key={index} eventKey={index}>
                  <StepPageAccordianItemHeader
                    title={
                      `#${index + 1} ${field.name ?? ''} ` +
                      (field.outdoorUnitNumber
                        ? `(${field.outdoorUnitNumber ?? ''})`
                        : '')
                    }
                    activeKey={panelActiveKey}
                    keyNumber={index}
                  />
                  <Panel.Collapse>
                    <Panel.Body>
                      <div id={field.id}>
                        <RadioGroup
                          id={`backupControlStrategies.${index}.backupControlStrategy-value`}
                          control={control}
                          items={
                            field.backupType === BackupType.Electric
                              ? optionsElectric
                              : optionsFurnace
                          }
                          radioClassName="step-option-radio"
                          onChange={() =>
                            submitField('backupControlStrategies')
                          }
                          renderLabel={(item) => (
                            <StepOptionRadioItem item={item} />
                          )}
                        />
                      </div>
                    </Panel.Body>
                  </Panel.Collapse>
                </Panel>
              );
            })}
          </PanelGroup>
          <ProjectStepProposition
            id={stepId}
            propositions={propositions.options[stepId]}
          />
        </form>
      )}
    </>
  );
};

export default BackupControlStrategyPage;
