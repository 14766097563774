export class HeatPumpsFilter {
  brand?: string;
  ahriModelUnit?: string;
  capacity47Min?: number;
  capacity5Min?: number;

  constructor(entity?: HeatPumpsFilter) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.brand = '';
      this.ahriModelUnit = '';
      this.capacity47Min = 0;
      this.capacity5Min = 0;
    }
  }
}
