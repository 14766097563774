import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { ProjectDetailsForm, ProjectIdentificationForm } from '..';
import { defaultSituations } from '../../../const/Projects';
import { Project } from '../../../model/Project';
import { Step1Data } from '../../../model/Step';
import { Address } from '../../../model/Address';

interface Props {
  project: Project;
  updateProject: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
}

const ProjectDetailsPage: React.FC<Props> = ({
  project,
  updateProject,
  displayCompact,
  userIsProjectOwner,
}) => {
  const { t } = useTranslation();

  const [compactHouseTypeImage, setCompactHouseTypeImage] = useState(null);

  const submitField = (value: { [key: string]: string | number }) => {
    updateProject(value);
  };

  const { control, formState, setValue, watch } = useForm<Step1Data>({
    defaultValues: {
      address: new Address(project?.address),
      installationType: project?.installationType || undefined,
      projectType: project?.projectType || undefined,
      houseType: project?.houseType || undefined,
      numberOfLevels: project?.numberOfLevels || '',
      situations: project.situations || defaultSituations,
    },
    mode: 'onChange',
  });

  const projectIdentificationForm = (
    <ProjectIdentificationForm
      project={project}
      control={control}
      setValue={setValue}
      watch={watch}
      inCreationMode={false}
      formState={formState}
      onBlur={(value) => submitField(value)}
      displayCompact={displayCompact}
      setCompactHouseTypeImage={setCompactHouseTypeImage}
      userIsProjectOwner={userIsProjectOwner}
    />
  );

  const projectDetailsForm = (
    <ProjectDetailsForm
      project={project}
      control={control}
      onChange={(value) => submitField(value)}
      displayCompact={true}
      userIsProjectOwner={userIsProjectOwner}
      viewCheckedItemsOnly={!userIsProjectOwner}
    />
  );

  return (
    <>
      <form noValidate>
        {displayCompact ? (
          <>
            <Row>
              <Col xs={8} md={8}>
                {projectIdentificationForm}
              </Col>
              <Col xs={4} md={4}>
                {compactHouseTypeImage}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                {projectDetailsForm}
              </Col>
            </Row>
          </>
        ) : (
          <>
            <h2>{t('project.identification.title')}</h2>
            {projectIdentificationForm}
            <h2>{t('project.details.title')}</h2>
            {projectDetailsForm}
          </>
        )}
      </form>
    </>
  );
};

export default ProjectDetailsPage;
