/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Step10Data, StepOption, Steps } from '../../../model/Step';
import { RadioGroup, Input, Select } from '../../common/Form';
import { StepOptionRadioItem } from '../StepPage';
import ProjectStepProposition from '../ProjectStepProposition';
import {
  Project,
  ProjectPropositions,
  ProjectType,
} from '../../../model/Project';
import { getProjectPropositions } from '../../../const/Projects';
import { getWorkflow } from '../../../utils/workflowUtils';
import { BTUH_MAX_DECIMALS } from '../../../const/Formatter';
import { useNumberFormatter } from '../../../hooks/useNumberFormatter';

interface Props {
  project: Project;
  stepData?: Step10Data;
  updateProject?: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  hideOptionsSelection?: boolean;
  additionalInfo?: JSX.Element;
}

const HeatingCoolingLoadEstimatesPage: React.FC<Props> = ({
  project,
  stepData,
  updateProject,
  displayCompact,
  userIsProjectOwner,
  hideOptionsSelection,
  additionalInfo,
}) => {
  const { t } = useTranslation();
  const numberFormatter = useNumberFormatter(BTUH_MAX_DECIMALS);
  const stepId = Steps.STEP_10;
  const fieldValue = `${stepId}-value`;
  const isSystemExtension =
    project.projectType === ProjectType.SYSTEM_EXTENSION;
  const stepList = useMemo(() => getWorkflow(project), [project]);
  const stepNumber = useMemo(
    () => stepList.find((step) => step.id === stepId)?.number,
    [stepList]
  );

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<Step10Data>({
    defaultValues: {
      [fieldValue]: stepData?.[fieldValue] || 'A',
      designHeatGain: stepData?.designHeatGain ?? 1,
      designHeatLoss: stepData?.designHeatLoss ?? 1,
    },
    mode: 'onChange',
  });

  const submitField = (value: { [key: string]: string | number }) => {
    updateProject &&
      updateProject({
        [`steps.${[stepId]}`]: {
          ...(isNewHome
            ? { ...stepData, 'heatingCoolingLoadEstimates-value': 'A' }
            : stepData),
          ...value,
        },
      });
  };

  const i18nForm = `step-${stepId}:form`;
  const i18nDescription = `step-${stepId}:description`;
  const isNewHome =
    project.projectType === ProjectType.FULL_SYSTEM_REPLACEMENT ||
    project.projectType === ProjectType.NEW_SYSTEM_INSTALLATION;

  const options: {
    label: string;
    value: string | number;
    disabled?: boolean;
    labelData: StepOption;
  }[] = [
    {
      label: `${t(`${i18nForm}.a.option.title`, {
        stepNumber: stepNumber,
      })} - ${t(`${i18nForm}.a.option.text`)}`,
      value: 'A',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:10A.alt'),
          fileName: t('figures:10A.fileName'),
          longDescription: t('figures:10A.longDesc'),
        },
        modal: {
          content: <p>{t(`${i18nForm}.a.modal.list1.title`)}</p>,
          title: t(`${i18nForm}.a.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.a.option.text`),
          title: t(`${i18nForm}.a.option.title`, { stepNumber: stepNumber }),
        },
        video: {
          href: t(`${i18nForm}.a.video.href`),
          title: t(`${i18nForm}.a.video.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.b.option.title`, {
        stepNumber: stepNumber,
      })} - ${t(`${i18nForm}.b.option.text`)}`,
      value: 'B',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:10B.alt'),
          fileName: t('figures:10B.fileName'),
          longDescription: t('figures:10B.longDesc'),
        },
        modal: {
          content: <p>{t(`${i18nForm}.b.modal.list1.title`)}</p>,
          title: t(`${i18nForm}.b.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.b.option.text`),
          title: t(`${i18nForm}.b.option.title`, { stepNumber: stepNumber }),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.c.option.title`, {
        stepNumber: stepNumber,
      })} - ${t(`${i18nForm}.c.option.text`)}`,
      value: 'C',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:10C.alt'),
          fileName: t('figures:10C.fileName'),
          longDescription: t('figures:10C.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.c.modal.list1.title`)}</p>
              <p>{t(`${i18nForm}.c.modal.list2.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.c.modal.list2.item1`)}</li>
                <li>{t(`${i18nForm}.c.modal.list2.item2`)}</li>
              </ul>
            </>
          ),
          title: t(`${i18nForm}.c.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.c.option.text`),
          title: t(`${i18nForm}.c.option.title`, { stepNumber: stepNumber }),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.d.option.title`, {
        stepNumber: stepNumber,
      })} - ${t(`${i18nForm}.d.option.text`)}`,
      value: 'D',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:10D.alt'),
          fileName: t('figures:10D.fileName'),
          longDescription: t('figures:10D.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>
                <Trans>{t(`${i18nForm}.d.modal.list1.title`)}</Trans>
              </p>
              <p>{t(`${i18nForm}.d.modal.list2.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.d.modal.list2.item1`)}</li>
                <li>{t(`${i18nForm}.d.modal.list2.item2`)}</li>
                <li>{t(`${i18nForm}.d.modal.list2.item3`)}</li>
              </ul>
              <p>{t(`${i18nForm}.d.modal.list3.title`)}</p>
            </>
          ),
          title: t(`${i18nForm}.d.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.d.option.text`),
          title: t(`${i18nForm}.d.option.title`, { stepNumber: stepNumber }),
        },
      },
    },
  ];

  const formValues = getValues();
  const [selectedOption, setSelectedOption] = useState(
    options.find((o) => o.value === formValues[fieldValue])
  );

  const propositions: ProjectPropositions = useMemo(() => {
    return getProjectPropositions(project);
  }, [project?.situations]);

  useEffect(() => {
    setSelectedOption(
      options.find(
        (o) => o.value === formValues?.['heatingCoolingLoadEstimates-value']
      )
    );

    if (stepData && !formValues) {
      setValue('designHeatGain', stepData?.designHeatGain || 1);
      setValue('designHeatLoss', stepData?.designHeatLoss || 1);
      setValue(
        'heatingCoolingLoadEstimates-value',
        stepData?.['heatingCoolingLoadEstimates-value'] ?? 'A'
      );
    }
  }, [stepData, setValue]);

  useEffect(() => {
    if (!Object.values(stepData ?? {}).length) {
      submitField(formValues as any);
    }
  }, []);

  const validationRules = {
    min: {
      value: 1,
      message: t('errors.project.minNumber', {
        min: 1,
      }).toString(),
    },
    validate: (value) => {
      const num = Number(value);
      if (!Number.isInteger(num)) {
        return t('errors.common.notInteger').toString();
      }
      return true;
    },
  };

  return (
    <>
      <form noValidate>
        {displayCompact ? (
          <Row>
            <Col xs={8} md={8}>
              {userIsProjectOwner ? (
                <>
                  {!isNewHome && (
                    <Select
                      label={t('checklist.selectedOption')}
                      id={fieldValue}
                      control={control}
                      options={options}
                      formGroupClasses={'step-option-radio'}
                      onChange={submitField}
                    />
                  )}
                  <Input
                    id="designHeatLoss"
                    control={control}
                    label={t(`${i18nForm}.designHeatLoss`)}
                    inputProps={{ disabled: !userIsProjectOwner }}
                    onBlur={submitField}
                    validationRules={validationRules}
                    error={errors?.designHeatLoss}
                    suffix={t(`${i18nForm}.btu`)}
                    formatter={numberFormatter}
                  />
                  <Input
                    id="designHeatGain"
                    control={control}
                    label={t(`${i18nForm}.designHeatGain`)}
                    inputProps={{ disabled: !userIsProjectOwner }}
                    onBlur={submitField}
                    validationRules={validationRules}
                    error={errors?.designHeatGain}
                    suffix={t(`${i18nForm}.btu`)}
                    formatter={numberFormatter}
                  />
                </>
              ) : (
                <>
                  <div className="selected-option-text">
                    {!isNewHome && !hideOptionsSelection && (
                      <>
                        <label>{selectedOption?.labelData.option?.title}</label>
                        <p>{selectedOption?.labelData.option?.text}</p>
                      </>
                    )}
                    <label>{t(`${i18nForm}.designHeatLoss`)}</label>
                    <p>
                      {
                        numberFormatter(formValues.designHeatLoss ?? 0)
                          .formatted
                      }
                    </p>
                    <label>{t(`${i18nForm}.designHeatGain`)}</label>
                    <p>
                      {
                        numberFormatter(formValues.designHeatGain ?? 0)
                          .formatted
                      }
                    </p>
                  </div>
                </>
              )}
              {additionalInfo}
            </Col>
            {!isNewHome && !hideOptionsSelection && (
              <Col xs={4} md={4}>
                {selectedOption && selectedOption.labelData.img && (
                  <img
                    src={`/assets/images/${selectedOption.labelData.img?.fileName}`}
                    alt={selectedOption.labelData.img?.alt}
                    className="compact-image"
                  />
                )}
              </Col>
            )}
          </Row>
        ) : (
          <>
            {(isNewHome || isSystemExtension) && (
              <>
                <p>{t(`${i18nDescription}.list1.title`)}</p>
                <ul>
                  <li>{t(`${i18nDescription}.list1.item1`)}</li>
                  <li>{t(`${i18nDescription}.list1.item2`)}</li>
                  <li>{t(`${i18nDescription}.list1.item3`)}</li>
                  <li>
                    {isSystemExtension
                      ? t(`${i18nDescription}.list1.item4SystemExtension`)
                      : t(`${i18nDescription}.list1.item4NewHome`)}
                  </li>
                </ul>

                <p>
                  {isSystemExtension
                    ? t(`${i18nDescription}.text1SystemExtension`)
                    : t(`${i18nDescription}.text1NewHome`)}
                </p>
                {isNewHome && (
                  <p>
                    <Trans
                      defaults={t(`${i18nDescription}.text2`)}
                      components={{ sup: <sup /> }}
                    />
                  </p>
                )}
              </>
            )}
            {!isNewHome && (
              <RadioGroup
                id={fieldValue}
                control={control}
                items={options}
                radioClassName="step-option-radio"
                onChange={submitField}
                renderLabel={(item) => <StepOptionRadioItem item={item} />}
              />
            )}
            <Row>
              <Col xs={6} sm={5}>
                <Input
                  id="designHeatLoss"
                  control={control}
                  label={t(`${i18nForm}.designHeatLoss`)}
                  inputProps={{ disabled: !userIsProjectOwner }}
                  onBlur={submitField}
                  validationRules={validationRules}
                  error={errors?.designHeatLoss}
                  suffix={t(`${i18nForm}.btu`)}
                  formatter={numberFormatter}
                />
              </Col>
              <Col xs={6} sm={5}>
                <Input
                  id="designHeatGain"
                  control={control}
                  label={t(`${i18nForm}.designHeatGain`)}
                  inputProps={{ disabled: !userIsProjectOwner }}
                  onBlur={submitField}
                  validationRules={validationRules}
                  error={errors?.designHeatGain}
                  suffix={t(`${i18nForm}.btu`)}
                  formatter={numberFormatter}
                />
              </Col>
            </Row>
            {!isNewHome && (
              <ProjectStepProposition
                id={stepId}
                propositions={propositions.options[stepId]}
              />
            )}
          </>
        )}
      </form>
    </>
  );
};

export default HeatingCoolingLoadEstimatesPage;
