import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { getLang } from './utils/splashUtils';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    defaultNS: 'common',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    lng: getLang() || 'en',
    ns: [
      'bestPractices',
      'common',
      'figures',
      'license',
      'routes',
      'step-ghgCostEnergyUseComparison',
      'step-ashpUnitConfiguration',
      'step-ductlessIndoorOutdoorUnitsConfiguration',
      'step-utilityCosts',
      'step-backupControlStrategy',
      'step-dehumidificationStrategy',
      'step-existingConstraints',
      'step-airflowTesting',
      'step-crossSectionalAreaMeasurement',
      'step-ductSealing',
      'step-ductShape',
      'step-ductSizing',
      'step-projectDetails',
      'step-sizingCapacityRequirements',
      'step-heatPumpSelection',
      'step-backupHeatSource',
      'step-heatingCoolingLoadEstimates',
      'step-returnAirDuctDesign',
      'step-zoningSupplyDucts',
      'step-supplyOutletApproach',
      'step-zoningEquipment',
    ],
    preload: ['en', 'fr'],
  });

export default i18n;
