declare global {
  interface Window {
    env: any;
  }
}

type ProcessEnv = {
  REACT_APP_API_URL: string;
  REACT_APP_AD_B2C_CLIENT_ID: string;
  REACT_APP_AD_B2C_API_CLIENT_ID: string;
  REACT_APP_AD_B2C_ORGANIZATION_NAME: string;
  REACT_APP_API_DISPLAY_INTERMEDIATE_RESULTS: string;
  REACT_APP_API_MAX_HP_COMPARE_COUNT: string;
};

export const env: ProcessEnv = { ...process.env, ...window.env };
