/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Step3Data, StepOption, Steps } from '../../../model/Step';
import { RadioGroup, Select } from '../../common/Form';
import { StepOptionRadioItem } from '../StepPage';
import { Project, ProjectPropositions } from '../../../model/Project';
import ProjectStepProposition from '../ProjectStepProposition';
import { getProjectPropositions } from '../../../const/Projects';

interface Props {
  stepData?: Step3Data;
  project: Project;
  updateProject: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  additionalInfo?: JSX.Element;
}

const SizingCapacityRequirementsPage: React.FC<Props> = ({
  stepData,
  project,
  updateProject,
  displayCompact,
  userIsProjectOwner,
  additionalInfo,
}) => {
  const { t } = useTranslation();

  const stepId = Steps.STEP_3;
  const fieldValue = `${stepId}-value`;

  const submitField = (value: { [key: string]: string | number }) => {
    updateProject({ [`steps.${[stepId]}`]: value });
  };

  const propositions: ProjectPropositions = useMemo(() => {
    return getProjectPropositions(project);
  }, [project]);

  const { control, getValues, setValue } = useForm<Step3Data>({
    defaultValues: {
      [fieldValue]: stepData?.[fieldValue] || 'A',
    },
    mode: 'onChange',
  });

  const i18nForm = `step-${[stepId]}:form`;

  const options: {
    label: string;
    value: string | number;
    disabled?: boolean;
    labelData: StepOption;
  }[] = [
    {
      label: `${t(`${i18nForm}.a.option.title`)} - ${t(
        `${i18nForm}.a.option.text`
      )}`,
      value: 'A',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:3A.alt'),
          fileName: t('figures:3A.fileName'),
          longDescription: t('figures:3A.longDesc'),
        },
        modal: {
          content: <p>{t(`${i18nForm}.a.modal.text`)}</p>,
          title: t(`${i18nForm}.a.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.a.option.text`),
          title: t(`${i18nForm}.a.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.b.option.title`)} - ${t(
        `${i18nForm}.b.option.text`
      )}`,
      value: 'B',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:3B.alt'),
          fileName: t('figures:3B.fileName'),
          longDescription: t('figures:3B.longDesc'),
        },
        modal: {
          content: <p>{t(`${i18nForm}.b.modal.text`)}</p>,
          title: t(`${i18nForm}.b.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.b.option.text`),
          title: t(`${i18nForm}.b.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.c.option.title`)} - ${t(
        `${i18nForm}.c.option.text`
      )}`,
      value: 'C',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:3C.alt'),
          fileName: t('figures:3C.fileName'),
          longDescription: t('figures:3C.longDesc'),
        },
        modal: {
          content: <p>{t(`${i18nForm}.c.modal.text`)}</p>,
          title: t(`${i18nForm}.c.modal.title`),
        },

        option: {
          text: t(`${i18nForm}.c.option.text`),
          title: t(`${i18nForm}.c.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.d.option.title`)} - ${t(
        `${i18nForm}.d.option.text`
      )}`,
      value: 'D',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:3D.alt'),
          fileName: t('figures:3D.fileName'),
          longDescription: t('figures:3D.longDesc'),
        },
        modal: {
          content: <p>{t(`${i18nForm}.d.modal.text`)}</p>,
          title: t(`${i18nForm}.d.modal.title`),
        },

        option: {
          text: t(`${i18nForm}.d.option.text`),
          title: t(`${i18nForm}.d.option.title`),
        },
      },
    },
  ];

  const formValues = getValues();
  const [selectedOption, setSelectedOption] = useState(
    options.find((o) => o.value === formValues[fieldValue])
  );

  useEffect(() => {
    if (!(stepData && stepData[fieldValue])) {
      submitField(formValues as any);
    }
  }, [formValues[fieldValue]]);

  useEffect(() => {
    setSelectedOption(options.find((o) => o.value === stepData?.[fieldValue]));
    setValue('sizingCapacityRequirements-value', stepData?.[fieldValue] || 'A');
  }, [stepData]);

  return (
    <form noValidate>
      {displayCompact ? (
        <Row>
          <Col xs={8} md={8}>
            {userIsProjectOwner ? (
              <Select
                label={t('checklist.selectedOption')}
                id={fieldValue}
                control={control}
                options={options}
                formGroupClasses={'step-option-radio'}
                onChange={submitField}
              />
            ) : (
              <div className="selected-option-text">
                <label>{selectedOption?.labelData.option?.title}</label>
                <p>{selectedOption?.labelData.option?.text}</p>
              </div>
            )}
            {additionalInfo}
          </Col>
          <Col xs={4} md={4}>
            {selectedOption && (
              <img
                src={`/assets/images/${selectedOption.labelData.img?.fileName}`}
                alt={selectedOption.labelData.img?.alt}
                className="compact-image"
              />
            )}
          </Col>
        </Row>
      ) : (
        <>
          <p className="page-description">
            {t('step-sizingCapacityRequirements:description')}
          </p>
          <RadioGroup
            id={fieldValue}
            control={control}
            items={options}
            radioClassName="step-option-radio"
            onChange={submitField}
            renderLabel={(item) => <StepOptionRadioItem item={item} />}
          />
          <ProjectStepProposition
            id={stepId}
            propositions={propositions.options[stepId]}
          />
        </>
      )}
    </form>
  );
};

export default SizingCapacityRequirementsPage;
