import axios from 'axios';
import { env } from '../env';
import { HeatPump } from '../model/HeatPump';
import { HeatPumpsFilter } from '../model/HeatPumpsFilter';
import { PaginationEntity } from '../model/PaginationEntity';

const baseURL = `${env.REACT_APP_API_URL}/api/Neep`;

export const getBrands = async () => {
  return await axios.get<string[]>(`/brands`, { baseURL });
};

export const getHeatPumps = async (filter: HeatPumpsFilter, page: number) => {
  return await axios.get<PaginationEntity<HeatPump>>(`/heatpumps`, {
    baseURL,
    params: { ...filter, pageNumber: page },
  });
};
