import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { SM_MAX_SIZE } from '../../../const/Responsive';
import { Step } from '../../../model/Step';
import { Project } from '../../../model/Project';
import { ServerFeedback } from '../../common/Form';
import { ProjectStepper, MarkCompleteCheckbox, CopyLinkButton } from '.';

interface Props {
  completedSteps: string[];
  project: Project;
  step: Step;
  stepsList: Step[];
  userIsProjectOwner?: boolean;
  serverError: string | undefined;
}

const ProjectStepHeader: React.FC<Props> = ({
  completedSteps,
  project,
  step,
  stepsList,
  userIsProjectOwner,
  serverError,
}) => {
  const { t } = useTranslation();
  const isSM = useMediaQuery({ query: `(max-width: ${SM_MAX_SIZE}px)` });
  const stepIsComplete = completedSteps.includes(step.id);

  // Only display checkbox control if user is owner of project
  const checkboxComponent = userIsProjectOwner ? (
    <MarkCompleteCheckbox
      checkboxClassName={'mark-complete-checkbox-top'}
      completedSteps={completedSteps}
      project={project}
      step={step}
    />
  ) : (
    stepIsComplete && (
      <div>
        <FontAwesomeIcon className="complete-check-icon" icon={faCheck} />
        <strong>{t('stepPage.complete')}</strong>
      </div>
    )
  );

  return (
    <>
      <h2>{t(`step-${step.id}:title`)}</h2>
      <Row className="check-and-copy-container">
        <Col
          xs={7}
          sm={8}
          xsHidden={!stepIsComplete && !userIsProjectOwner}
          smHidden={!stepIsComplete && !userIsProjectOwner}
          md={9}
        >
          {checkboxComponent}
        </Col>
        <Col
          xs={5}
          sm={4}
          md={3}
          className={
            isSM && !stepIsComplete && !userIsProjectOwner ? 'align-left' : ''
          }
        >
          <CopyLinkButton />
        </Col>
      </Row>

      <ProjectStepper stepNumber={step.number} stepsList={stepsList} />
      {!!serverError && (
        <ServerFeedback type="error">{t(serverError)}</ServerFeedback>
      )}
    </>
  );
};

export default ProjectStepHeader;
