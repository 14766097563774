import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { Project } from '../../../model/Project';
import { ChecklistPrintFormat } from './';

interface Props {
  project: Project;
}

const PrintChecklistButton: React.FC<Props> = ({ project }) => {
  const componentToPrintRef = useRef(null);
  const { t } = useTranslation();
  const pageStyle = `
    .print-gov-logo {
      width: 200pt !important;
      margin-bottom: 10pt;
      margin-top: 10pt;
    }
    .app-name-small {
      font-size: 15pt;
      margin-bottom: 15pt !important;
    }
    .website-link {
      font-size: 14pt !important;
    }
    .completed-step {
      margin-right: 10pt;
      max-height: 16pt;
    }
    .additional-info-text {
      white-space: pre-wrap;
    }
    p {
      font-size: 16pt;
    }
    li {
      font-size: 16pt;
    }
    h1 {
      margin-bottom: 30pt !important;
    }
    h3 {
      font-size: 20pt !important;
      margin-bottom: 25pt !important;
      margin-top: 25pt !important;
    }
    h4 {
      font-size: 18pt !important;
      margin-bottom: 15pt !important;
    }
    hr {
      display: block;
      height: 2pt;
      background: transparent;
      width: 100%;
      border: none;
      border-top: solid 1pt #26374A !important;
  }
  label {
    font-size: 14pt !important;
    font-weight: 600 !important;
  }
  @media print {
    div {
      break-inside: avoid;
    }
  }
`;
  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
    pageStyle: pageStyle,
  });
  return (
    <>
      <div style={{ display: 'none' }}>
        <ChecklistPrintFormat
          reference={componentToPrintRef}
          project={project}
        />
      </div>
      <Button
        className="print-button"
        type="button"
        bsStyle="primary"
        onClick={handlePrint}
      >
        <FontAwesomeIcon className="print-icon" icon={faPrint} />
        {t('checklist.print')}
      </Button>
    </>
  );
};

export default PrintChecklistButton;
