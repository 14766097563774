export class Trunk {
  type?: string;
  depth?: number;
  width?: number;
  diameter?: number;

  constructor(entity?: Trunk) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.type = 'rectangular';
      this.depth = 1;
      this.width = 1;
      this.diameter = 1;
    }
  }
}
