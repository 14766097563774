import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, PageHeader } from 'react-bootstrap';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Project, ProjectSituationSelections } from '../../model/Project';
import { ServerFeedback } from '../common/Form';
import { AppRoute } from '../../const';
import { LocalizedNavLink } from '../i18n';
import { ProjectDetailsForm } from '../Project';
import { defaultSituations } from '../../const/Projects';
import { Steps } from '../../model/Step';
import { partialUpdateProject } from '../../api/projects';
import { JsonPatchOperation } from '../../model/JsonPatchOperation';
import { UserRoles } from '../../model/User';
import { CreateProjectProgress, ReminderModal } from '.';

type FormValues = {
  situations: ProjectSituationSelections;
};

interface Props {
  project: Project;
}

const ProjectDetails: React.FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const history = useHistory();
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [serverError, setServerError] = useState<string | undefined>();
  const [openReminderModal, setOpenReminderModal] = useState<boolean>(false);

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      situations: project.situations || defaultSituations,
    },
    mode: 'onChange',
  });

  const updateMutation = useMutation((changes: JsonPatchOperation[]) =>
    partialUpdateProject(project.id, changes)
  );

  const onSubmit = (values: FormValues) => {
    const completedSteps = project.completedSteps;
    if (completedSteps.indexOf(Steps.STEP_1) === -1) {
      completedSteps.unshift(Steps.STEP_1);
    }

    updateMutation.mutate(
      [
        { op: 'add', path: '/updatedAt', value: new Date() },
        { op: 'add', path: '/situations', value: values.situations },
        { op: 'add', path: '/isStarted', value: true },
        { op: 'add', path: '/completedSteps', value: completedSteps },
      ],
      {
        onError: (err) => {
          console.log(err);
          setServerError(t('errors.common.unknown'));
        },
        onSuccess: async () => {
          await queryClient.invalidateQueries(['projects']);
          history.push(`/${t(AppRoute.Project)}/${project.id}`);
        },
      }
    );
  };

  const onSaveClick = () => {
    if (
      project.ownerRole === UserRoles.HOME_BUILDER ||
      project.ownerRole === UserRoles.HOME_OWNER
    ) {
      setOpenReminderModal(true);
    } else {
      submitButtonRef.current?.click();
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {t('pageTitle.createProject.title')} -{' '}
          {t('pageTitle.createProject.details')} - {t('pageTitle.appName')}
        </title>
      </Helmet>
      <PageHeader>
        {t('createProject.title')}
        {project && ` - ${project.name}`}
      </PageHeader>
      <CreateProjectProgress project={project} currentStep={3} />
      <h2>{t('createProject.details.title')}</h2>

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <ProjectDetailsForm
          control={control}
          userIsProjectOwner={true}
          project={project}
        />

        <div className="form-actions">
          {!!serverError && (
            <ServerFeedback type="error">{t(serverError)}</ServerFeedback>
          )}
          <Button
            disabled={updateMutation.isLoading}
            aria-disabled={updateMutation.isLoading}
            bsStyle="primary"
            onClick={onSaveClick}
          >
            {t('project.saveStartButton.label')}
          </Button>
          <button
            type="submit"
            ref={submitButtonRef}
            style={{ display: 'none' }}
          />
          <LocalizedNavLink
            className="btn btn-default btn-sm"
            to={AppRoute.Dashboard}
          >
            {t('common.cancel')}
          </LocalizedNavLink>
        </div>
      </form>
      <ReminderModal
        project={project}
        onClose={() => setOpenReminderModal(false)}
        onConfirm={() => submitButtonRef.current?.click()}
        opened={openReminderModal}
      />
    </>
  );
};

export default ProjectDetails;
