import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AppRoute } from '../../const';
import { Step } from '../../model/Step';

interface Props {
  completedSteps: string[];
  projectId: string;
  stepsList: Step[];
}

const ProjectOverviewOptions: React.FC<Props> = ({
  completedSteps,
  projectId,
  stepsList,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {stepsList.map((step) => {
        return (
          <div key={`overview-${step.id}`} className="overview-item">
            <Grid fluid>
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col xs={1}>
                      {completedSteps?.includes(step.id) && (
                        <FontAwesomeIcon icon={faCheck} />
                      )}
                    </Col>
                    <Col xs={10} sm={8}>
                      <span className="overview-item-title">
                        {t('stepPage.title', { number: step.number })}
                      </span>
                      <h3>{t(`step-${step.id}:title`)}</h3>
                    </Col>
                    <Col
                      xs={11}
                      xsPush={1}
                      sm={3}
                      smPush={0}
                      className="text-right text-right-not-xs"
                    >
                      <a
                        aria-label={t('overview.options.viewButtonAria', {
                          stepNumber: step.number,
                          viewLabel: t(`step-${step.id}:title`),
                        })}
                        href={`/${t(AppRoute.Project)}/${projectId}/${t(
                          AppRoute.Step
                        )}/${t(step.path)}`}
                        className="btn btn-default btn-sm view-button"
                      >
                        {t('overview.options.viewButton', {
                          stepNumber: step.number,
                        })}
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Grid>
          </div>
        );
      })}
    </>
  );
};

export default ProjectOverviewOptions;
