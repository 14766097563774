import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Col, PageHeader, Row } from 'react-bootstrap';
import { useMemo } from 'react';
import MediaQuery from 'react-responsive';
import { Project } from '../../model/Project';
import { MD_MIN_SIZE, SM_MAX_SIZE } from '../../const/Responsive';
import { getWorkflowWithoutSubSteps } from '../../utils/workflowUtils';
import { ProjectComments } from './Comments';
import { ProjectMenu, ProjectOverviewOptions, ProjectStatus } from '.';

interface Props {
  project: Project;
}

const ProjectOverview: React.FC<Props> = ({ project }) => {
  const { t } = useTranslation();

  const stepsList = useMemo(
    () => getWorkflowWithoutSubSteps(project),
    [project]
  );

  return (
    <>
      <Helmet>
        <title>{t('pageTitle.overview', { projectName: project.name })}</title>
      </Helmet>
      <PageHeader>
        {t('overview.title', { projectName: project.name })}
      </PageHeader>

      <ProjectMenu project={project} />

      <Row>
        <MediaQuery maxWidth={SM_MAX_SIZE}>
          <Col xs={12}>
            <ProjectStatus project={project} stepsList={stepsList} />
          </Col>
        </MediaQuery>

        <Col xs={12} md={8}>
          <h2>{t('overview.options.title')}</h2>

          <div>
            <ProjectOverviewOptions
              completedSteps={project.completedSteps}
              projectId={project.id}
              stepsList={stepsList}
            />
          </div>
        </Col>
        <Col xs={12} md={4}>
          <MediaQuery minWidth={MD_MIN_SIZE}>
            <ProjectStatus project={project} stepsList={stepsList} />
          </MediaQuery>
          <ProjectComments project={project} pageId="overview" />
        </Col>
      </Row>
    </>
  );
};

export default ProjectOverview;
