import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Col, Row, Well } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
  always?: boolean;
  id: string;
  propositions?: string[];
  translationVariables?: { [key: string]: number | string | undefined };
  withListStyle?: boolean;
  title?: string;
}

const ProjectStepProposition: React.FC<Props> = ({
  always,
  id,
  propositions,
  translationVariables,
  withListStyle,
  title,
}) => {
  const { t } = useTranslation();

  if (!propositions?.length) return null;

  return (
    <Well className="well-dark proposition">
      <Row>
        <Col xs={1}>
          <FontAwesomeIcon icon={faExclamationCircle} />
        </Col>
        <Col xs={10} sm={11}>
          <div
            className={clsx('proposition-content', {
              'with-list-style': withListStyle,
            })}
          >
            <span className="well-title">
              {title
                ? title
                : always
                ? t('overview.proposition.title.always')
                : t('overview.proposition.title.consider')}
            </span>
            <ul>
              {propositions.map((proposition) => (
                <li
                  key={`${id}-proposition-${proposition}`}
                  className="proposition"
                >
                  {t(`propositions.${proposition}`, translationVariables)}
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </Well>
  );
};

export default ProjectStepProposition;
