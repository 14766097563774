import { HeatPump } from '../HeatPump';
import { BackupType } from './BackupType';
import { FurnaceCondition } from './FurnaceCondition';
import { FurnaceType } from './FurnaceType';

export class HeatPumpWithBackup extends HeatPump {
  backupType?: BackupType;
  furnaceType?: FurnaceType;
  electricSize?: number;
  furnaceAFUE?: number;
  furnaceCapacity?: number;
  furnaceCondition?: FurnaceCondition;

  constructor(entity?: HeatPumpWithBackup) {
    super(entity);
    this.backupType = BackupType.Electric;
    this.electricSize = 1;
    this.furnaceType =
      entity?.backupType === BackupType.Electric
        ? FurnaceType.None
        : FurnaceType.Gas;
    this.furnaceAFUE = this.furnaceType === FurnaceType.Gas ? 96 : 0;
    this.furnaceCapacity = 1;
    this.furnaceCondition = FurnaceCondition.NewFurnace;
    if (entity) {
      Object.assign(this, { ...entity });
    }
  }
}

export const DEFAULT_GAS_AFUE = 96;
