import { Panel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  title: string;
  activeKey?: number;
  keyNumber: number;
}

const StepPageAccordianItemHeader: React.FC<Props> = ({
  title,
  activeKey,
  keyNumber,
}) => {
  const { t } = useTranslation();
  const isActive = keyNumber === activeKey;

  return (
    <Panel.Heading
      className={`step-page-accordian-header ${
        isActive ? 'dark-accordian-header' : ''
      }`}
    >
      <Panel.Title>
        <div className="title-wrapper">
          <div className="header">
            <Panel.Toggle>
              <h4>{title}</h4>
            </Panel.Toggle>
          </div>
          <Panel.Toggle
            aria-label={
              isActive ? t('checklist.contract') : t('checklist.expand')
            }
          >
            <FontAwesomeIcon
              color={isActive ? '#ffffff' : '#26374A'}
              icon={isActive ? faChevronUp : faChevronDown}
              size="sm"
              className={'dark-toggle-icon'}
            />
          </Panel.Toggle>
        </div>
      </Panel.Title>
    </Panel.Heading>
  );
};

export default StepPageAccordianItemHeader;
