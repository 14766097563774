export class HeatPump {
  name?: string;
  ahri_Number?: string;
  outdoorUnitNumber?: string;
  indoorUnitNumber?: string;
  productTypeId?: number;
  productType?: string;
  ductingConfiguration?: string;
  cutOff_Temperature?: number;
  hspf?: number;
  maxHeatingCapacityAt47F?: number;
  maxHeatingCapacityAt17F?: number;
  maxHeatingCapacityAt5F?: number;
  copAt47F?: number;
  copAt17F?: number;
  copAt5F?: number;
  maxCurrentProtection?: string;
  coolingCapacityAt95F?: number;
  coolingEER?: number;
  variableCapacity?: boolean;

  constructor(entity?: HeatPump) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.name = '';
      this.ahri_Number = '';
      this.outdoorUnitNumber = '';
      this.indoorUnitNumber = '';
      this.productTypeId = 0;
      this.productType = '';
      this.ductingConfiguration = '';
      this.cutOff_Temperature = 0;
      this.hspf = 0;
      this.maxHeatingCapacityAt47F = 0;
      this.maxHeatingCapacityAt17F = 0;
      this.maxHeatingCapacityAt5F = 0;
      this.copAt47F = 0;
      this.copAt17F = 0;
      this.copAt5F = 0;
      this.maxCurrentProtection = '';
      this.coolingCapacityAt95F = 0;
      this.coolingEER = 0;
      this.variableCapacity = false;
    }
  }
}
