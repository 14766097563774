import { useState } from 'react';
import { Button, PageHeader } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AppRoute } from '../../const';
import { HouseType } from '../../model/House';
import { InstallationType, Project, ProjectType } from '../../model/Project';
import { ServerFeedback } from '../common/Form';
import { LocalizedNavLink } from '../i18n';
import { ProjectIdentificationForm } from '../Project';
import { partialUpdateProject } from '../../api/projects';
import { JsonPatchOperation } from '../../model/JsonPatchOperation';
import { Address } from '../../model/Address';
import { CreateProjectProgress } from '.';

type FormValues = {
  address: Address;
  installationType: InstallationType;
  projectType: ProjectType;
  houseType: HouseType;
  numberOfLevels: number | '';
};

interface Props {
  project: Project;
}

const ProjectIdentification: React.FC<Props> = ({ project }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [serverError, setServerError] = useState<string | undefined>();
  const createProjectRoute = `/${t(AppRoute.CreateProject)}`;

  const { control, handleSubmit, formState, setValue, watch } =
    useForm<FormValues>({
      defaultValues: {
        address: new Address(project?.address),
        installationType:
          project?.installationType || InstallationType.NEW_HOME,
        projectType: project?.projectType || undefined,
        houseType: project?.houseType || undefined,
        numberOfLevels: project?.numberOfLevels || '',
      },
      mode: 'onChange',
    });

  const updateMutation = useMutation((changes: JsonPatchOperation[]) =>
    partialUpdateProject(project.id, changes)
  );

  const onSubmit = (values: FormValues) => {
    updateMutation.mutate(
      [
        { op: 'add', path: '/updatedAt', value: new Date() },
        { op: 'add', path: '/address', value: values.address },
        {
          op: 'add',
          path: '/installationType',
          value: values.installationType,
        },
        { op: 'add', path: '/projectType', value: values.projectType },
        { op: 'add', path: '/houseType', value: values.houseType },
        { op: 'add', path: '/numberOfLevels', value: values.numberOfLevels },
      ],
      {
        onError: (err) => {
          console.log(err);
          setServerError(t('errors.common.unknown'));
        },
        onSuccess: async () => {
          await queryClient.invalidateQueries(['projects']);
          history.push(
            `${createProjectRoute}/${t(AppRoute.CreateProjectDetails)}?id=${
              project.id
            }`
          );
        },
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {t('pageTitle.createProject.title')} -{' '}
          {t('pageTitle.createProject.identification')} -{' '}
          {t('pageTitle.appName')}
        </title>
      </Helmet>
      <PageHeader>
        {t('createProject.title')}
        {project && ` - ${project.name}`}
      </PageHeader>

      <CreateProjectProgress project={project} currentStep={2} />
      <h2>{t('createProject.identification.title')}</h2>

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <ProjectIdentificationForm
          control={control}
          formState={formState}
          userIsProjectOwner={true}
          setValue={setValue}
          project={project}
          watch={watch}
          inCreationMode
        />

        <div className="form-actions">
          {!!serverError && (
            <ServerFeedback type="error">{t(serverError)}</ServerFeedback>
          )}
          <Button
            type="submit"
            disabled={updateMutation.isLoading}
            aria-disabled={updateMutation.isLoading}
            bsStyle="primary"
          >
            {t('common.saveContinue')}
          </Button>
          <LocalizedNavLink
            className="btn btn-default btn-sm"
            to={AppRoute.Dashboard}
          >
            {t('common.cancel')}
          </LocalizedNavLink>
        </div>
      </form>
    </>
  );
};

export default ProjectIdentification;
