/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Row, Col, Button } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Step5_1Data, Steps } from '../../../../model/Step';
import { Input, Select } from '../../../common/Form';
import { CrossSectionalAreaMeasurement } from '../../../../model/DuckConstraints/CrossSectionalAreaMeasurement';
import { Trunk } from '../../../../model/DuckConstraints/Trunk';
import { NUMBER_MAX_DECIMALS } from '../../../../const/Formatter';
import { useNumberFormatter } from '../../../../hooks/useNumberFormatter';

interface Props {
  stepData?: Step5_1Data;
  updateProject?: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  additionalInfo?: JSX.Element;
}

const CrossSectionalAreaMeasurementPage: React.FC<Props> = ({
  stepData,
  updateProject,
  displayCompact,
  userIsProjectOwner,
  additionalInfo,
}) => {
  const { t } = useTranslation();
  const numberFormatter = useNumberFormatter(NUMBER_MAX_DECIMALS);

  const stepId = Steps.STEP_5_1_2;

  const submitField = () => {
    updateProject &&
      updateProject({
        [`steps.${[Steps.STEP_5_1]}`]: { ...stepData, ...getValues() },
      });
  };

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<Step5_1Data>({
    defaultValues: {
      ductVelocity: (stepData && stepData.ductVelocity) || 700,
      crossSectionalAreaMeasurement: new CrossSectionalAreaMeasurement(
        stepData?.crossSectionalAreaMeasurement
      ),
    },
    mode: 'onChange',
  });

  const i18nForm = `step-${[stepId]}:form`;

  const formValues = getValues();

  const trunkTypeOptions: {
    disabled?: boolean;
    label: string;
    value: string | number;
  }[] = [
    {
      label: `${t(`${i18nForm}.trunkType.rectangular`)}`,
      value: 'rectangular',
      disabled: !userIsProjectOwner,
    },
    {
      label: `${t(`${i18nForm}.trunkType.round`)}`,
      value: 'round',
      disabled: !userIsProjectOwner,
    },
  ];

  useEffect(() => {
    if (!Object.values(stepData?.crossSectionalAreaMeasurement ?? {}).length) {
      submitField();
    }
  }, []);

  useEffect(() => {
    if (stepData && !formValues) {
      setValue('ductVelocity', stepData?.ductVelocity || 700);

      setValue(
        'crossSectionalAreaMeasurement',
        new CrossSectionalAreaMeasurement(
          stepData?.crossSectionalAreaMeasurement
        )
      );
    }
  }, [stepData]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'crossSectionalAreaMeasurement.trunks',
  });

  const validationRules = {
    min: {
      value: 1,
      message: t('errors.project.minNumber', {
        min: 1,
      }).toString(),
    },
  };

  const trunks = (
    <>
      <Button
        onClick={() => {
          append(new Trunk());
          submitField();
        }}
        className="btn btn-default btn-sm btn-icon-left add-trunk"
      >
        <FontAwesomeIcon icon={faPlus} />
        {t(`${i18nForm}.addTrunk`)}
      </Button>
      <div className="trunk-wrapper">
        {fields.map((field, index) => (
          <div key={field.id}>
            <Row className={'trunk-row'}>
              <Col xs={6} sm={3}>
                <Select
                  id={`crossSectionalAreaMeasurement.trunks.${index}.type`}
                  control={control}
                  label={t(`${i18nForm}.trunk`, {
                    number: index + 1,
                  })}
                  inputProps={{ disabled: !userIsProjectOwner }}
                  onBlur={() => {
                    submitField();
                    //to fix a bug that when you change the trunk type the value of depth is tranfered to diameter and vice versa
                    setValue(
                      'crossSectionalAreaMeasurement.trunks',
                      getValues().crossSectionalAreaMeasurement?.trunks as never
                    );
                  }}
                  options={trunkTypeOptions}
                />
              </Col>
              {getValues().crossSectionalAreaMeasurement?.trunks[index].type ===
              'rectangular' ? (
                <>
                  <Col xs={6} sm={3}>
                    <Input
                      id={`crossSectionalAreaMeasurement.trunks.${index}.depth`}
                      control={control}
                      label={t(`${i18nForm}.depth`)}
                      inputProps={{ disabled: !userIsProjectOwner }}
                      onBlur={submitField}
                      validationRules={validationRules}
                      error={
                        errors?.crossSectionalAreaMeasurement?.trunks?.[index]
                          ?.depth
                      }
                      formatter={numberFormatter}
                    />
                  </Col>
                  <Col xs={6} sm={3}>
                    <Input
                      id={`crossSectionalAreaMeasurement.trunks.${index}.width`}
                      control={control}
                      label={t(`${i18nForm}.width`)}
                      inputProps={{ disabled: !userIsProjectOwner }}
                      onBlur={submitField}
                      validationRules={validationRules}
                      error={
                        errors?.crossSectionalAreaMeasurement?.trunks?.[index]
                          ?.width
                      }
                      formatter={numberFormatter}
                    />
                  </Col>
                  <Col
                    xs={6}
                    sm={2}
                    smOffset={1}
                    className="btn-remove-container"
                  >
                    <Button
                      onClick={() => {
                        remove(index);
                        submitField();
                      }}
                      className="btn btn-default btn-sm btn-icon-left"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                      {t('common.remove')}
                    </Button>
                  </Col>
                </>
              ) : (
                <>
                  <Col xs={6} sm={3}>
                    <Input
                      id={`crossSectionalAreaMeasurement.trunks.${index}.diameter`}
                      control={control}
                      label={t(`${i18nForm}.diameter`)}
                      inputProps={{ disabled: !userIsProjectOwner }}
                      onBlur={submitField}
                      validationRules={validationRules}
                      error={
                        errors?.crossSectionalAreaMeasurement?.trunks?.[index]
                          ?.diameter
                      }
                      formatter={numberFormatter}
                    />
                  </Col>
                  <Col
                    xs={6}
                    sm={2}
                    smOffset={4}
                    className="btn-remove-container"
                  >
                    <Button
                      onClick={() => {
                        remove(index);
                        submitField();
                      }}
                      className="btn btn-default btn-sm btn-icon-left"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                      {t('common.remove')}
                    </Button>
                  </Col>
                </>
              )}
            </Row>
            <hr></hr>
          </div>
        ))}
      </div>
    </>
  );

  return (
    <div className="cross-sectional-area-measurement-page">
      {displayCompact ? (
        <Row>
          <Col xs={12}>
            {userIsProjectOwner ? (
              <>
                {trunks}
                <Input
                  id="ductVelocity"
                  control={control}
                  label={t(`${i18nForm}.ductVelocity`)}
                  inputProps={{ disabled: !userIsProjectOwner }}
                  onBlur={submitField}
                  formatter={numberFormatter}
                  suffix={t(`${i18nForm}.fpm`)}
                  validationRules={{
                    min: {
                      value: 0,
                      message: t('errors.project.minNumber', {
                        min: 0,
                      }).toString(),
                    },
                    max: {
                      value: 900,
                      message: t('errors.project.maxNumber', {
                        max: 900,
                      }).toString(),
                    },
                  }}
                  error={errors?.ductVelocity}
                />
                <p>
                  <Trans>
                    {t(
                      'step-crossSectionalAreaMeasurement:form.estimatedMaximumFlowCapacity',
                      {
                        value:
                          stepData?.crossSectionalAreaMeasurement
                            ?.estimatedMaximumFlowCapacity,
                      }
                    )}
                  </Trans>
                </p>
              </>
            ) : (
              <div className="selected-option-text">
                {fields.map((field, index) => (
                  <div key={field.id}>
                    <div>
                      <label>
                        {t(`${i18nForm}.trunk`, { number: index + 1 })}
                      </label>
                      <p>
                        {t(
                          `${i18nForm}.trunkType.${
                            numberFormatter(
                              formValues.crossSectionalAreaMeasurement?.trunks[
                                index
                              ].type ?? 0
                            ).formatted
                          }`
                        )}
                      </p>
                    </div>
                    {getValues().crossSectionalAreaMeasurement?.trunks[index]
                      .type === 'rectangular' ? (
                      <>
                        <div>
                          <label>{t(`${i18nForm}.depth`)}</label>
                          <p>
                            {
                              numberFormatter(
                                formValues.crossSectionalAreaMeasurement
                                  ?.trunks[index].depth ?? 0
                              ).formatted
                            }
                          </p>
                        </div>
                        <div>
                          <label>{t(`${i18nForm}.width`)}</label>
                          <p>
                            {
                              numberFormatter(
                                formValues.crossSectionalAreaMeasurement
                                  ?.trunks[index].width ?? 0
                              ).formatted
                            }
                          </p>
                        </div>
                      </>
                    ) : (
                      <div>
                        <label>{t(`${i18nForm}.diameter`)}</label>
                        <p>
                          {
                            numberFormatter(
                              formValues.crossSectionalAreaMeasurement?.trunks[
                                index
                              ].diameter ?? 0
                            ).formatted
                          }
                        </p>
                      </div>
                    )}
                  </div>
                ))}
                <label>{t(`${i18nForm}.ductVelocity`)}</label>
                <p>{`${
                  numberFormatter(formValues.ductVelocity ?? 0).formatted
                } ${t(`${i18nForm}.fpm`)}`}</p>
                <p>
                  <Trans>
                    {t(
                      'step-crossSectionalAreaMeasurement:form.estimatedMaximumFlowCapacity',
                      {
                        value:
                          stepData?.crossSectionalAreaMeasurement
                            ?.estimatedMaximumFlowCapacity,
                      }
                    )}
                  </Trans>
                </p>
              </div>
            )}
            {additionalInfo}
          </Col>
        </Row>
      ) : (
        <form noValidate>
          <p>
            {t(
              'step-crossSectionalAreaMeasurement:form.ductVelocityDescription'
            )}
          </p>
          {trunks}
          <Input
            id="ductVelocity"
            control={control}
            label={t(`${i18nForm}.ductVelocity`)}
            inputProps={{ disabled: !userIsProjectOwner }}
            onBlur={submitField}
            formatter={numberFormatter}
            suffix={t(`${i18nForm}.fpm`)}
            validationRules={{
              min: {
                value: 0,
                message: t('errors.project.minNumber', {
                  min: 0,
                }).toString(),
              },
              max: {
                value: 900,
                message: t('errors.project.maxNumber', {
                  max: 900,
                }).toString(),
              },
            }}
            error={errors?.ductVelocity}
          />
          <p>
            <Trans>
              {t(
                'step-crossSectionalAreaMeasurement:form.estimatedMaximumFlowCapacity',
                {
                  value:
                    stepData?.crossSectionalAreaMeasurement
                      ?.estimatedMaximumFlowCapacity,
                }
              )}
            </Trans>
          </p>
          <Row className="step-type-description-container">
            <Col xs={12} sm={12}>
              <p id="airflow-testing-description">
                {t('step-crossSectionalAreaMeasurement:imageDescription')}
              </p>
            </Col>
            <Col xs={12} sm={12} className="step-full-width-image">
              <img
                src={`/assets/images/${t('figures:5B.fileName')}`}
                alt={t('figures:5B.alt')}
                aria-describedby={'airflow-testing-description'}
              />
            </Col>
          </Row>
        </form>
      )}
    </div>
  );
};

export default CrossSectionalAreaMeasurementPage;
