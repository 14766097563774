import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Button, Checkbox } from 'react-bootstrap';
import { InstallationType, Project } from '../../model/Project';
import { Dialog } from '../common/Dialog';
import { VideoContent } from '../Project/Videos';

interface Props {
  project: Project;
  onClose: () => void;
  onConfirm: () => void;
  opened: boolean;
}

const ReminderModal: React.FC<Props> = ({
  project,
  onClose,
  onConfirm,
  opened,
}) => {
  const { t } = useTranslation();
  const [confirmCheck, setConfirmCheck] = useState<boolean>(false);

  const isBC = project.address?.province === 'BC';

  const existingHomeContent = (
    <>
      <p>{t('createProject.details.modal.existingHome.description')}</p>
      <ul>
        <li>
          {t('createProject.details.modal.existingHome.item1.item')}
          <ul>
            <li>
              {t(
                `createProject.details.modal.existingHome.item1.${
                  isBC ? 'bc' : 'allOtherProvinces'
                }.subItem1`
              )}
              {isBC ? (
                <a
                  target="blank"
                  href={t(
                    `createProject.details.modal.existingHome.item1.bc.link1`
                  )}
                >
                  {t(`createProject.details.modal.existingHome.item1.bc.link1`)}
                </a>
              ) : (
                <a
                  target="blank"
                  href={t(
                    `createProject.details.modal.existingHome.item1.allOtherProvinces.link1`
                  )}
                >
                  {t(
                    `createProject.details.modal.existingHome.item1.allOtherProvinces.link1`
                  )}
                </a>
              )}
            </li>
            {isBC && (
              <li>
                {t(
                  'createProject.details.modal.existingHome.item1.bc.subItem2'
                )}
                <a
                  target="blank"
                  href={t(
                    'createProject.details.modal.existingHome.item1.bc.link2'
                  )}
                >
                  {t('createProject.details.modal.existingHome.item1.bc.link2')}
                </a>
              </li>
            )}
          </ul>
        </li>
        <li>{t('createProject.details.modal.existingHome.item2.item')}</li>
        <li>{t('createProject.details.modal.existingHome.item3.item')}</li>
        <li>
          {t('createProject.details.modal.existingHome.item4.item')}
          <ul>
            <li>
              {t('createProject.details.modal.existingHome.item4.subItem1')}
            </li>
            <li>
              {t('createProject.details.modal.existingHome.item4.subItem2')}
            </li>
            <li>
              {t('createProject.details.modal.existingHome.item4.subItem3')}
            </li>
            <li>
              {t('createProject.details.modal.existingHome.item4.subItem4')}
            </li>
            <li>
              {t('createProject.details.modal.existingHome.item4.subItem5')}
            </li>
            <li>
              {t('createProject.details.modal.existingHome.item4.subItem6')}
            </li>
            <li>
              {t('createProject.details.modal.existingHome.item4.subItem7')}
            </li>
          </ul>
        </li>
      </ul>
    </>
  );

  const newBuildContent = (
    <>
      <p>{t('createProject.details.modal.newBuild.description')}</p>
      <ul>
        <li>{t('createProject.details.modal.newBuild.item1.item')}</li>
        <li>{t('createProject.details.modal.newBuild.item2.item')}</li>
        <li>{t('createProject.details.modal.newBuild.item3.item')}</li>
        <li>{t('createProject.details.modal.newBuild.item4.item')}</li>
        <li>{t('createProject.details.modal.newBuild.item5.item')}</li>
        <li>{t('createProject.details.modal.newBuild.item6.item')}</li>
        <li>
          {t('createProject.details.modal.newBuild.item7.item')}
          <ul>
            <li>
              {t(
                `createProject.details.modal.newBuild.item7.${
                  isBC ? 'bc' : 'allOtherProvinces'
                }.subItem1`
              )}
              {isBC ? (
                <a
                  target="blank"
                  href={t(
                    `createProject.details.modal.newBuild.item7.bc.link1`
                  )}
                >
                  {t(`createProject.details.modal.newBuild.item7.bc.link1`)}
                </a>
              ) : (
                <a
                  target="blank"
                  href={t(
                    `createProject.details.modal.newBuild.item7.allOtherProvinces.link1`
                  )}
                >
                  {t(
                    `createProject.details.modal.newBuild.item7.allOtherProvinces.link1`
                  )}
                </a>
              )}
            </li>
          </ul>
        </li>
        <li>
          {t('createProject.details.modal.newBuild.item8.item')}
          <ul>
            <li>{t('createProject.details.modal.newBuild.item8.subItem1')}</li>
            <li>{t('createProject.details.modal.newBuild.item8.subItem2')}</li>
            <li>{t('createProject.details.modal.newBuild.item8.subItem3')}</li>
            <li>{t('createProject.details.modal.newBuild.item8.subItem4')}</li>
          </ul>
        </li>
        <li>{t('createProject.details.modal.newBuild.item9.item')}</li>
      </ul>
    </>
  );

  return (
    <Dialog
      noActions
      onHide={onClose}
      show={!!opened}
      title={t('createProject.details.modal.title')}
      modalProps={{
        onHide: onClose,
        className: 'create-project-reminder-modal',
      }}
    >
      {project.installationType === InstallationType.EXISTING_HOME
        ? existingHomeContent
        : newBuildContent}
      <VideoContent
        video={{
          href: t('createProject.details.modal.video.href'),
          title: t('createProject.details.modal.video.title'),
        }}
      />
      <div className="actions">
        <Checkbox
          type="checkbox"
          aria-label={t('createProject.details.modal.checkboxLabel')}
          checked={confirmCheck}
          onChange={(event: any) => {
            setConfirmCheck(event.target.checked);
          }}
        >
          <span>{t('createProject.details.modal.checkboxLabel')}</span>
        </Checkbox>
        <Button
          bsSize="small"
          bsStyle="primary"
          disabled={!confirmCheck}
          onClick={onConfirm}
        >
          {t('createProject.details.modal.continueButton')}
        </Button>
      </div>
    </Dialog>
  );
};

export default ReminderModal;
