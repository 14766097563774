import axios from 'axios';
import { PageComment } from '../model/Comments';

import { Project, ProjectStatus } from '../model/Project';
import { GHGCostEnergyUse } from '../model/GHGCostEnergyUse';

export const saveNewProject = async (project: Project) => {
  return axios.post('/Projects', project);
};

export const getProjectsOfUser = async (status?: ProjectStatus) => {
  if (status) {
    return await axios.get<Project[]>(`/Projects?status=${status}`);
  } else {
    return await axios.get<Project[]>('/Projects');
  }
};

export const getProject = async (projectId: string) => {
  return await axios.get<Project>(`/Projects/${projectId}`);
};

export const getCalculations = async (projectId: string) => {
  return await axios.get<GHGCostEnergyUse[]>(
    `/Projects/${projectId}/calculations`
  );
};

export const getDesignTemp = async (
  countryId: string,
  provinceId: string,
  cityId: string
) => {
  return await axios.get<number>(
    `/Countries/${countryId}/provinces/${provinceId}/cities/${cityId}/designTemp`
  );
};

export const deleteProject = async (projectId: string) => {
  return axios.delete(`/Projects/${projectId}`);
};

export const partialUpdateProject = async (
  projectId: string,
  operations: any
) => {
  return axios.patch(`/Projects/${projectId}`, operations);
};

export const saveComment = async (
  projectId: string,
  pageId: string,
  comment: PageComment
) => {
  return axios.post(`/Projects/${projectId}/comments/${pageId}`, comment);
};

export const logShare = async (
  projectId: string,
  projectName: string,
  collaborators: string[]
) => {
  return axios.post(`/Projects/${projectId}/logShare`, {
    projectName,
    collaborators,
  });
};
